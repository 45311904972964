import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../types';
import { planDaySelectors, createPlanDayToApi } from '../../slices/planDaySlice';
import { setDay } from '../../slices/activeDaySlice';

import IconPlus from '../icons/iconPlusComponent';
import { useAppDispatch } from '../../export/hook';
import I18n from '../../i18n/i18n';

const PlanDayAddButton: FC = () => {
  const planDays = useSelector((state: AppState) => planDaySelectors.selectIds(state));
  const dispatch = useAppDispatch();

  if (!planDays || planDays.length === 0) {
    return <div data-testid="no_data" />;
  }

  return (
    <button
      className={`jc-plan-day-add-btn ${planDays.length >= 5 ? '--state_disabled' : ''}`}
      type="button"
      aria-label="AddDay"
      data-testid="addDay"
      disabled={planDays.length >= 5}
      onClick={() => {
        dispatch(setDay(planDays.length + 1));
        dispatch(createPlanDayToApi());
      }}
    >
      <IconPlus additionalClassNames={['jc-plan-day-add-btn__icon']} />
      <span className="jc-plan-day-add-btn__text">{I18n.t('japan_concierge.active_plans.show.add_trip_day')}</span>
    </button>
  );
};

export default PlanDayAddButton;
