import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../types';
import { optimizeToApi, planSpotInDaySelector } from '../../slices/planSpotSlice';

import IconSort from '../icons/iconSortComponent';
import { useAppDispatch } from '../../active_plan/hook';

const OptimizeRouteButton: FC = () => {
  const planSpotIds = useSelector((state: AppState) => planSpotInDaySelector(state));
  const dispatch = useAppDispatch();

  return (
    <button
      type="button"
      className={`optimize-route-button`}
      disabled={planSpotIds.length === 0}
      onClick={() => {
        dispatch(optimizeToApi());
      }}
    >
      <IconSort additionalClassNames={['optimize-route-button__icon']} />
    </button>
  );
};

export default OptimizeRouteButton;
