import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import I18n from '../../i18n/i18n';
import { AppState } from '../../types';
import { optimizeToApi, planSpotInDaySelector } from '../../slices/planSpotSlice';

import IconSort from '../icons/iconSortComponent';
import { useAppDispatch } from '../../active_plan/hook';

interface Props {
  location: string;
}

const OptimizeRouteButton: FC<Props> = (props: Props) => {
  const { location } = props;

  const planSpotIds = useSelector((state: AppState) => planSpotInDaySelector(state));
  const dispatch = useAppDispatch();

  return (
    <button
      type="button"
      className={`${location}-utility-btn --color_optimize`}
      disabled={planSpotIds.length === 0}
      onClick={() => {
        dispatch(optimizeToApi());
      }}
    >
      <IconSort additionalClassNames={[]} />
      <span>{I18n.t('japan_concierge.active_plans.show.optimize_route')}</span>
    </button>
  );
};

export default OptimizeRouteButton;
