import React, { FC } from 'react';

import { createPlanToApi } from '../../slices/planSlice';
import { reset as planSpotReset } from '../../slices/planSpotSlice';
import { reset as transitReset } from '../../slices/transitSlice';
import { setDay } from '../../slices/activeDaySlice';
import { setIsInitializeMapCenter } from '../../slices/mapSlice';
import { useAppDispatch } from '../../export/hook';
import IconPlus from '../icons/iconPlusComponent';
import { setIndex } from '../../slices/planMenuSlice';
import I18n from '../../i18n/i18n';

const CreatePlanButton: FC = () => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(setIndex(0));
    dispatch(planSpotReset());
    dispatch(transitReset());
    dispatch(setDay(1));
    dispatch(setIsInitializeMapCenter(true));
    dispatch(createPlanToApi());
  };

  return (
    <button
      data-jc-role="plan-list-plan-new-btn"
      aria-label="plan-list-plan-new-btn"
      className="foot-utility-btn --color_add_plan"
      type="button"
      onClick={handleClick}
    >
      <IconPlus additionalClassNames={['btn__icon']} />
      {I18n.t('japan_concierge.active_plans.show.create_a_new_plan')}
    </button>
  );
};

export default CreatePlanButton;
