import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import FavoriteButton from './favoriteSpotButtonComponent';
import HelpButton from './helpButtonComponent';
import LoginButton from './loginButtonComponent';
import LogoutButton from './logoutButtonComponent';
import MyPlanButton from './myPlanButtonComponent';
import PlanEditButton from './planEditButtonComponent';
import { AppState } from '../../types';

const TopMenuApp: FC = () => {
  const { helpButton, socialMediaAuth, snsAuth } = useSelector((state: AppState) => state.config);

  return (
    <div className="jc-top-menu">
      <div>
        <PlanEditButton />
      </div>
      <div>
        <FavoriteButton />
      </div>
      <div>
        <MyPlanButton />
      </div>
      {snsAuth && (
        <div>
          {!socialMediaAuth.name && <LoginButton />}
          {socialMediaAuth.name && <LogoutButton />}
        </div>
      )}
      {helpButton && (
        <div>
          <HelpButton />
        </div>
      )}
    </div>
  );
};

export default TopMenuApp;
