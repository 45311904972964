import React from 'react';

import { AppState, MarkerType } from '../../types';
import { createPlanSpotToApi } from '../../slices/planSpotSlice';
import IconPlus from '../icons/iconPlusComponent';
import { useAppDispatch } from '../../active_plan/hook';
import { setContentId, toggle } from '../../slices/modalSlice';
import { getSpotToApi } from '../../slices/modalSpotSlice';
import { useSelector } from 'react-redux';

type MapPointProps = {
  mapSpotMarker: MarkerType;
};

const MapSpotMarker = (props: MapPointProps) => {
  const { mapSpotMarker } = props;
  const dispatch = useAppDispatch();
  // imageに関しては、jc-apiから返却されたspotとローカルspotのインスタンスの違いにより画像のURL取得可否がある為、サーバ側にてimgタグとして返却される
  // ため、dangerouslySetInnerHTMLにてimgを表示できるように設定。
  const selectedIndex = useSelector((state: AppState) => state.mapMenuCategory.selectedIndex);
  const categoryColorAmount = 9 // カテゴリーの色の数
  const colorIndex = (selectedIndex - 1) % categoryColorAmount + 1;

  return (
    <div
      className={`jc-map-marker jc-map-marker--type_others jc-map-marker--category-${colorIndex}`}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        if (mapSpotMarker && mapSpotMarker.spot) {
          dispatch(setContentId('spot'));
          dispatch(toggle());
          dispatch(getSpotToApi(mapSpotMarker.spot.id));
          const mapSpot = document.getElementById(`${mapSpotMarker.spot.id}`);
          mapSpot?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
          });
        }
        e.stopPropagation();
      }}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: mapSpotMarker.image }} className="jc-map-marker__img" />
      <div>
        <button
          className="jc-btn-style-reset jc-map-marker__action-button jc-map-marker__action-button--add"
          type="button"
          aria-label="mapSpotAddPlanButton"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            if (mapSpotMarker && mapSpotMarker.spot) {
              dispatch(createPlanSpotToApi({ spotId: mapSpotMarker.spot.id, position: 'auto' }));
            }
            // 親divのonclick処理を実行しないようにバブリング伝播を阻止
            e.stopPropagation();
          }}
        >
          <IconPlus additionalClassNames={['jc-map-marker__action-button-icon']} />
        </button>
      </div>

      {/* スポット名 */}
      {mapSpotMarker && <div className="jc-map-marker__spot-name">{mapSpotMarker.name}</div>}
    </div>
  );
};

export default React.memo(MapSpotMarker);
