import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import activeDayReducer from '../slices/activeDaySlice';
import backdropSliceReducer from '../slices/backdropSlice';
import configSliceReducer from '../slices/configSlice';
import customSpotReducer from '../slices/customeSpotSlice';
import modalSliceReducer from '../slices/modalSlice';
import modalCalendarReducer from '../slices/modalCalendarSlice';
import modalStaySliceReducer from '../slices/modalStaySlice';
import modalStartSliceReducer from '../slices/modalStartSlice';
import modalTransitReducer from '../slices/modalTransitSlice';
import modalFavoriteSpotReducer from '../slices/modalSpotSlice';
import modalPlanReducer from '../slices/modalPlanSlice';
import mapReducer from '../slices/mapSlice';
import mapMenuReducer from '../slices/mapMenuSlice';
import mapMenuHeaderReducer from '../slices/mapMenuHeaderSlice';
import mapMenuMonthReducer from '../slices/mapMenuMonthSlice';
import mapMenuSeasonReducer from '../slices/mapMenuSeasonSlice';
import mapMenuItemReducer from '../slices/mapMenuCategorySlice';
import mapSpotReducer from '../slices/mapSpotSlice';
import mapSlideTileReducer from '../slices/spotSlideTileSlice';
import planSliceReducer from '../slices/planSlice';
import planDayReducer from '../slices/planDaySlice';
import planMenuReducer from '../slices/planMenuSlice';
import planSpotReducer from '../slices/planSpotSlice';
import popupReducer from '../slices/popupSlice';
import snsReducer from '../slices/snsSlice';
import transitReducer from '../slices/transitSlice';
import { AppState } from '../types';

const rootReducer = combineReducers<AppState>({
  activeDay: activeDayReducer,
  backdrop: backdropSliceReducer,
  config: configSliceReducer,
  customSpot: customSpotReducer,
  map: mapReducer,
  mapMenu: mapMenuReducer,
  mapMenuHeader: mapMenuHeaderReducer,
  mapMenuMonth: mapMenuMonthReducer,
  mapMenuSeason: mapMenuSeasonReducer,
  mapMenuCategory: mapMenuItemReducer,
  mapSpot: mapSpotReducer,
  modal: modalSliceReducer,
  modalCalendar: modalCalendarReducer,
  modalSpot: modalFavoriteSpotReducer,
  modalStay: modalStaySliceReducer,
  modalStart: modalStartSliceReducer,
  modalTransit: modalTransitReducer,
  modalPlan: modalPlanReducer,
  plan: planSliceReducer,
  planDay: planDayReducer,
  planMenu: planMenuReducer,
  planSpot: planSpotReducer,
  popup: popupReducer,
  sns: snsReducer,
  spotSlideTile: mapSlideTileReducer,
  transit: transitReducer,
});

// reducerにDate型を指定すると[A non-serializable value was detected in the state]のエラーが出力される
// reduxの場合は発生しないが、redux-toolkitの場合のみ発生する模様。なので、serializableCheckを一旦falseに設定
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type AppDispatch = typeof store.dispatch;
export default store;
