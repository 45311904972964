import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { PopupState } from '../types';

const initialState: PopupState = {
  isShown: false,
  contentText: '',
};

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    reset: (state: PopupState) => {
      Object.assign(state, initialState);
    },
    showPopup: (state: PopupState) => {
      state.isShown = true;
    },
    hidePopup: (state: PopupState) => {
      state.isShown = false;
    },
    setPopupContentText: (state: PopupState, action: PayloadAction<string>) => {
      state.contentText = action.payload;
    },
  },
});

export const { reset, showPopup, hidePopup, setPopupContentText } = popupSlice.actions;

export default popupSlice.reducer;
