// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { Provider } from 'react-redux';

import App from './app';

import { ConfigProps, MapConfigProps } from '../types';
import store from './store';
import I18n from '../i18n/i18n';
import Modal from '../components/modal/modalComponent';
import Backdrop from '../components/backdrop/backdropComponent';
import Popup from '../components/popup/popupComponent';
import { setPlanId } from '../slices/planSlice';
import { setConfig } from '../slices/configSlice';
import { setIndex } from '../slices/planMenuSlice';

interface Props {
  activePlanId: number;
  config: ConfigProps;
  locale: string;
  defaultLocale: string;
  mapConfig: MapConfigProps;
  initialActiveTab: number;
}

export default class ActivePlan extends Component<Props> {
  constructor(props: Props) {
    super(props);
    I18n.locale = props.locale;
    I18n.fallbacks = true;
    I18n.defaultLocale = props.defaultLocale;
    // Class ComponentからReact Hooksは呼び出せない為以下のように呼び出してます
    store.dispatch(setPlanId(props.activePlanId));
    store.dispatch(setConfig(props.config));
    if (props.initialActiveTab === 1 || props.initialActiveTab === 2) {
      store.dispatch(setIndex(props.initialActiveTab));
    }
  }

  render(): JSX.Element {
    const { config, mapConfig } = this.props;

    if (google.maps.ControlPosition.RIGHT_CENTER !== undefined) {
      return (
        <Provider store={store}>
          <Modal />
          <Backdrop />
          <Popup />
          <App config={config} mapConfig={mapConfig} />
        </Provider>
      );
    }

    return <></>;
  }
}
