import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { ExportAppState, ExportPlanDaySpot } from '../../types/export_type';

interface Props {
  exportPlanDaySpots: ExportPlanDaySpot[];
  day: number;
}

const SpotList: FC<Props> = ({ exportPlanDaySpots, day }) => {
  const isShow = useSelector((state: ExportAppState) => state.export.isShowMap)[day];

  if (!exportPlanDaySpots || exportPlanDaySpots.length === 0) {
    return <div data-testid="spot_list_no_data" />;
  }

  const truncate = (str: string, len: number) => (str.length <= len ? str : `${str.substring(0, len)}...`);

  return (
    <ul className={isShow ? 'jc-export-course-map__spots' : 'jc-export-course-map__spots jc-u-d-none'}>
      {exportPlanDaySpots.map((exportPlanDaySpot: ExportPlanDaySpot) => (
        <div className="jc-export-course-map-spot" key={exportPlanDaySpot.id}>
          {/* 上記divは削除しないでください。このmap処理の戻り値としては複数のHTMLElementを返却する場合、親となる要素が1つだけ必ず必要です */}
          {/* 表示順 */}
          <div className="jc-export-course-map-spot__position">{exportPlanDaySpot.position + 1}</div>

          {/* スポット名 */}
          {/* FEチームへ 3点リーダーの設定はデザインコーディング時に変更してください。修正後はこのコメントの削除もお願いします */}
          <div className="jc-export-course-map-spot__ttl">{truncate(exportPlanDaySpot.spot.name, 20)}</div>
        </div>
      ))}
    </ul>
  );
};

export default SpotList;
