import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getPlanListToApi, setIndex } from '../../slices/planMenuSlice';
import I18n from '../../i18n/i18n';
import { AppState } from '../../types';
import { useAppDispatch } from '../../export/hook';
import IconList from '../icons/iconListComponent';

const MyPlanButton: FC = () => {
  const dispatch = useAppDispatch();
  const activePlanId = useSelector((state: AppState) => state.plan.id);
  const selectedIndex = useSelector((state: AppState) => state.planMenu.selectedIndex);

  useEffect(() => {
    dispatch(getPlanListToApi());
  }, [activePlanId]);

  const handleClick = () => {
    dispatch(setIndex(2));
  };

  return (
    <button
      data-jc-role="top-menu-plans-btn"
      className={`jc-top-menu__button ${selectedIndex === 2 ? 'jc-top-menu__button-active' : ''}`}
      type="button"
      onClick={handleClick}
      aria-label="MyPlan"
    >
      <IconList additionalClassNames={['jc-top-menu__icon']} />
      <span className="jc-top-menu__text">{I18n.t('japan_concierge.active_plans.top_menu.my_plans')}</span>
    </button>
  );
};

export default MyPlanButton;
