import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deepEqual } from 'fast-equals';

import { GoogleMap, Polyline } from '@react-google-maps/api';
import { planSpotMarkerSelector } from '../../slices/planSpotSlice';
import { setMap, setIsRunFitBounds, setIsInitializeMapCenter } from '../../slices/mapSlice';
import { AppState, MarkerType } from '../../types';
import PlanSpotMarkers from './planSpotMarkersComponent';
import MapSpotMarkers from './mapSpotMarkersComponent';

interface MapProps {
  customSpot: boolean;
  initialZoomLevel: number;
  zoom: number;
  center: { lat: number; lng: number };
}

const App: FC<MapProps> = (mapProps: MapProps) => {
  // useSelector の比較ロジックは状態の「更新」が発生した場合にのみ、再レンダリングが発生する
  // 更新が発生したかどうかの判定には、SameValueではなく[===]が利用されるため、
  // 更新が発生したかどうかチェックする対象は「セレクタの返り値」であり、それは必ずしもstateとは限らない。
  // === で比較する場合、オブジェクト、配列は値ではなく参照先が同じかの判定になるため、毎回 falseとなり再レンダリングが発生する
  // deepEqualを利用して、ネストしているspotオブジェクトの値を比較できるようにしている
  // その為、planSpotのmarker以外の値が更新されたとしてもplanSpotMarkerSelectorは前回と同じ値と判定され再レンダリングの対象外となる
  const planSpotMarkers = useSelector((state: AppState) => planSpotMarkerSelector(state), deepEqual);
  const { zoom, center, initialZoomLevel } = mapProps;
  const isRunFitBounds = useSelector((state: AppState) => state.map.isRunFitBounds);
  const isInitializeMapCenter = useSelector((state: AppState) => state.map.isInitializeMapCenter);
  const day = useSelector((state: AppState) => state.activeDay.day);
  const map = useSelector((state: AppState) => state.map.map);
  const displayPlanSpotMarkers = planSpotMarkers.filter(
    (marker: MarkerType | undefined) => marker !== undefined && marker?.day === day
  );
  const dispatch = useDispatch();

  // 地図の中心位置を設定する
  useEffect(() => {
    if (map) {
      // 初回読み込み時、または新規にプラン作成された際に一旦ズームレベル、アプリ初期値の中心座標を表示する
      // planSpotが既に存在している場合は、fitBoundsが呼ばれる
      if (isInitializeMapCenter) {
        map.setZoom(initialZoomLevel);
        map.setCenter(center || new google.maps.LatLng(0, 0));
        dispatch(setIsInitializeMapCenter(false));
      }

      // planSpotが1件、または全て同じスポットの場合planSpotを地図の中心に表示する
      if (
        displayPlanSpotMarkers.length === 1 ||
        (displayPlanSpotMarkers.length > 0 &&
          displayPlanSpotMarkers.every(
            (v: MarkerType | undefined) => v?.spot?.id === displayPlanSpotMarkers[0]?.spot?.id
          ))
      ) {
        map.setCenter(new google.maps.LatLng(displayPlanSpotMarkers[0]?.lat || 0, displayPlanSpotMarkers[0]?.lng || 0));
      }
    }
  }, [displayPlanSpotMarkers]);

  // fitBoundsの実施を行う
  // 初回読み込み、DAYの切り替え、Planの切り替え時にplanSpotが存在している場合のみ。
  useEffect(() => {
    if (map) {
      // 地図のビューポート(位置座標とズーム値)を変更
      // planSpotに含まれているspotが全て同一の場合は、fitBoundsを行わない
      if (
        isRunFitBounds &&
        displayPlanSpotMarkers.length > 1 &&
        !displayPlanSpotMarkers.every(
          (v: MarkerType | undefined) => v?.spot?.id === displayPlanSpotMarkers[0]?.spot?.id
        )
      ) {
        map.fitBounds(
          displayPlanSpotMarkers.reduce(
            (bounds: google.maps.LatLngBounds, marker: MarkerType | undefined) =>
              bounds.extend(new google.maps.LatLng(marker?.lat || 0, marker?.lng || 0)),
            new google.maps.LatLngBounds()
          )
        );
      }

      // isRunFitBoundsフラグは初回読み込み時activePlan情報を取得する処理が完了後、（getActivePlanToApi）trueになる。
      // trueによりfitboundsが実施される為、以降はさせてない為フラグを初期化する
      if (isRunFitBounds) {
        dispatch(setIsRunFitBounds(false));
      }
    }
  }, [isRunFitBounds]);

  const onLoad = useCallback((mapInstance: google.maps.Map) => {
    mapInstance.setCenter(center);
    dispatch(setMap(mapInstance));
  }, []);

  const onUnmount = useCallback(() => {
    dispatch(setMap(undefined));
  }, []);

  const polylineOptions = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.3,
    strokeWeight: 5,
  };

  const polylinePath: { lat: any; lng: any }[] = displayPlanSpotMarkers
    .sort((a, b) => ((a?.position || 0) > (b?.position || 0) ? 1 : -1))
    .map((marker) => ({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      lat: marker.lat,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      lng: marker.lng,
    }));

  return (
    <>
      {/* 新デザイン対応により以下は不要になったが完全対応まで残しておく */}
      {/*<MapMenu customSpot={customSpot} />*/}
      <GoogleMap
        mapContainerStyle={{ flexGrow: 1, height: '100%' }}
        zoom={zoom || 13}
        options={{
          mapTypeControl: false,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
          },
          streetViewControl: false,
          fullscreenControl: false,
          backgroundColor: '#93c5fd',
          gestureHandling: 'greedy',
          clickableIcons: false,
        }}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <PlanSpotMarkers />
        <Polyline path={polylinePath} options={polylineOptions} />
        <MapSpotMarkers />
      </GoogleMap>
    </>
  );
};

export default React.memo(App);
