import React, { ForwardRefRenderFunction, forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { AppState, Category } from '../../types';

import { setIndex } from '../../slices/mapMenuCategorySlice';
import { getSpotToApi, reset, setSelectedId, setLoading } from '../../slices/mapSpotSlice';
import { useAppDispatch } from '../../active_plan/hook';

interface Props {
  category: Category;
  index: number;
}

const CategoryButton: ForwardRefRenderFunction<HTMLButtonElement, Props> = (props, ref) => {
  const { category, index } = props;
  const dispatch = useAppDispatch();
  const selectedIndex = useSelector((state: AppState) => state.mapMenuCategory.selectedIndex);
  const categories = useSelector((state: AppState) => state.mapMenuCategory.categories);

  if (categories.length === 0) {
    return <div data-testid="no_data" />;
  }

  return (
    <button
      ref={ref}
      type="button"
      className={`${selectedIndex === index + 2 ? 'active' : ''}`}
      onClick={() => {
        dispatch(setIndex(index + 2));
        dispatch(reset());
        if (selectedIndex !== index + 2) {
          dispatch(setLoading(true));
          dispatch(setSelectedId(category.id));
          dispatch(getSpotToApi(category.id));
        }
      }}
    >
      {category.name}
    </button>
  );
};

export default forwardRef(CategoryButton);
