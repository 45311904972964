import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const IconCar: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="car"
    data-testid="car"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M407.79,449.43c-20.41-8.28-15.82-38-16.55-55.71-44,5.14-97.77,14.36-135.58,16.71-38.07-2.32-91.86-11.52-136.12-16.71-.84,17.66,3.94,47.49-16.55,55.71-18.15.7-66.38,8.71-73.78-16.45-5.15-6.93-1.39-62.32-2.77-70.9C22.92,359.47,11.23,349.2,10,346.91c-1.55-2.8-13.6-65.19-8.87-90.63s26.72-27.92,31.62-32.57c-21.59-10.46-44.79-32.48-24.35-57.77C14.93,156,53,163,61.72,161c18.46-57.6,20.2-99.37,110.73-98.73,45.76-1,179.88-3.87,218.25,6.1C438,90,435.93,128.69,449.07,161c9,2.27,46.61-5,53.36,4.92,20,23-2,49-24.82,57.23,51.63,7.41,30.5,105.77,23.22,123.74-1.35,2.36-13.05,12.66-16.65,15.29C484.07,436.11,495.9,458.78,407.79,449.43ZM372,305.59c.28,51.15,78.05,51.07,78.22-.08C449.89,254.36,372.2,254.48,372,305.59Zm-311.35,0c.28,51.15,78.05,51.07,78.22-.08-.29-51.15-78-51-78.22.08ZM404.07,199.64s-4.48-72.72-38.78-93.35-186.15-20.62-220.32,0-38.25,93.31-38.25,93.31Z"
    />
  </svg>
);

export default IconCar;
