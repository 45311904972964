import { createRoot } from 'react-dom/client';
import React from 'react';

import ActivePlanApp from '../japan_concierge/active_plan';
import ExportApp from '../japan_concierge/export';

const mount = (mountNodeId) => {
  // Integrated版の場合
  window.addEventListener('DOMContentLoaded', () => {
    const mountNode = document.getElementById(mountNodeId);
    if (mountNode) {
      const propsJSON = mountNode.getAttribute('data-react-props');
      const props = JSON.parse(propsJSON);
      const root = createRoot(mountNode);
      root.render(
        <>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {mountNodeId === 'active_plan' && <ActivePlanApp {...props} />}
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {mountNodeId === 'export' && <ExportApp {...props} />}
        </>
      );
    }
  });
};

mount('active_plan');
mount('export');
