import React, { FC } from 'react';
import I18n from '../../i18n/i18n';
import { useAppDispatch } from '../../active_plan/hook';
import { useSelector } from 'react-redux';
import { AppState } from '../../types';
import { setIndex } from '../../slices/planMenuSlice';
import IconUser from '../icons/iconUserComponent';

const LoginButton: FC = () => {
  const dispatch = useAppDispatch();
  const selectedIndex = useSelector((state: AppState) => state.planMenu.selectedIndex);

  return (
    <button
      aria-label="login"
      className={`jc-top-menu__button ${selectedIndex === 3 ? 'jc-top-menu__button-active' : ''}`}
      type="button"
      data-testid="login"
      onClick={() => {
        dispatch(setIndex(3));
      }}
    >
      <IconUser additionalClassNames={['jc-top-menu__icon']} />
      <span className="jc-top-menu__text">{I18n.t('japan_concierge.auth_menus.show.sign_in')}</span>
    </button>
  );
};

export default LoginButton;
