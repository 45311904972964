import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../types';
import { updateStartTime, updatePlanDayToApi } from '../../slices/planDaySlice';
import { changeInputHour, changeInputMinute } from '../../slices/modalStartSlice';
import I18n from '../../i18n/i18n';
import { useAppDispatch } from '../../active_plan/hook';

const ModalContentStart: FC = () => {
  const dispatch = useAppDispatch();
  const { id, inputHour, inputMinute } = useSelector((state: AppState) => state.modalStart);
  const [errorMessage, setErrorMessage] = useState('');

  const htmlForHourId = 'start_hour_input';
  const htmlForMinuteId = 'start_minute_input';

  if (id === 0) {
    return <div data-testid="no_data_start" />;
  }

  return (
    <>
      <div className="jc-modal-for-react__header">
        <h4 className="jc-modal-for-react__header-ttl">
          {I18n.t('japan_concierge.active_plans.show.change_departure_time')}
        </h4>
      </div>
      <div className="jc-modal-for-react__body">
        <div className="jc-modal-content-time-list justify-content-center">
          <div className="jc-modal-content-time-list__main-cnt">
            <span className="jc-modal-content-time-list__label">スタート時間</span>
            <span className='jc-modal-content-time-list__edit-time-container'>
              <input
                className="jc-modal-content-time-list__input-txt"
                type="text"
                id={htmlForHourId}
                data-testid={htmlForHourId}
                pattern="[0-9]*"
                value={inputHour}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.validity.valid) {
                    setErrorMessage('');
                    dispatch(changeInputHour(e.target.value));
                  } else {
                    setErrorMessage(I18n.t('japan_concierge.active_plans.show.error_message_half_width_numbers'));
                  }
                }}
              />
              <span className="px-2">:</span>
              <input
                className="jc-modal-content-time-list__input-txt"
                type="text"
                id={htmlForMinuteId}
                data-testid={htmlForMinuteId}
                pattern="[0-9]*"
                value={String(inputMinute).padStart(2, '0')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.validity.valid) {
                    setErrorMessage('');
                    dispatch(changeInputMinute(e.target.value));
                  } else {
                    setErrorMessage(I18n.t('japan_concierge.active_plans.show.error_message_half_width_numbers'));
                  }
                }}
              />
            </span>
          </div>
          <div className="jc-modal-content-time-list__sub-cnt">
            <span className="jc-modal-content-time-list__valid-txt">{errorMessage}</span>
          </div>
        </div>

        <div className="jc-modal-for-react__button-area">
          <button
            className="jc-modal-content-done-btn"
            type="button"
            onClick={() => {
              dispatch(
                updateStartTime({
                  id,
                  startTimeHour: inputHour,
                  startTimeMinute: inputMinute,
                })
              );
              dispatch(updatePlanDayToApi());
            }}
          >
            {I18n.t('japan_concierge.active_plans.show.confirm')}
          </button>
        </div>
      </div>
    </>
  );
};

export default ModalContentStart;
