import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { AppState, Plan, PlanDay, PlanSpot } from '../types';
import appApi from '../utils/api';

import { formatDate } from '../utils/datetime';

// new Date().setHours(9)としてstartTimeに指定するとエラーとなる
const nowTime = new Date();
nowTime.setHours(9);

const initialState: Plan = {
  id: 0,
  memo: '',
  name: '',
  priorityCar: false,
  priorityMode: '',
  startDate: undefined,
  startTime: nowTime,
};

//-------------------------------------------------------------
// 非同期処理
//-------------------------------------------------------------
// プラン名/メモを更新する処理
export const updateNameToApi = createAsyncThunk<void, void>('plan/updateName', async (arg: void, thunkAPI) => {
  const { id, name, memo } = (thunkAPI.getState() as AppState).plan;
  if (!name) {
    return;
  }

  await appApi.patch(`${(thunkAPI.getState() as AppState).config.rootPath}/plans/${id}`, { name, memo });
});

// ゴールロック・解除, 優先する交通手段を更新
export const updatePlanToApi = createAsyncThunk<void, void>('plan/update', async (arg: void, thunkAPI) => {
  const { id, priorityMode } = (thunkAPI.getState() as AppState).plan;
  await appApi.patch(`${(thunkAPI.getState() as AppState).config.rootPath}/plans/${id}`, {
    priority_mode: priorityMode,
  });
});

// プランを削除
export const deletePlanToApi = createAsyncThunk<{ plan: Plan }, number>(
  'plan/delete',
  async (arg: number, thunkAPI) => {
    const response = await appApi.delete(`${(thunkAPI.getState() as AppState).config.rootPath}/plans/${arg}`);
    if (response.data === 'no_content') {
      return { plan: undefined };
    }
    return { plan: response.data };
  }
);

// プランを作成
export const createPlanToApi = createAsyncThunk<{ plan: Plan }, void>('plan/create', async (arg: void, thunkAPI) => {
  const response = await appApi.post(`${(thunkAPI.getState() as AppState).config.rootPath}/active_plan/`);
  return { plan: response.data };
});

// アクティブプランに設定する処理
export const updateActivePlanToApi = createAsyncThunk<{ plan: Plan; planSpots: PlanSpot[] }, number>(
  'activePlan/update',
  async (id: number, thunkAPI) => {
    const response = await appApi.patch(`${(thunkAPI.getState() as AppState).config.rootPath}/active_plan`, {
      plan_id: id,
    });
    return response.data;
  }
);

// active_planの情報を取得する処理
export const getActivePlanToApi = createAsyncThunk<{ plan: Plan; planDays: PlanDay[]; planSpots: PlanSpot[] }, void>(
  'activePlan/show',
  async (arg: void, thunkAPI) => {
    const response = await appApi.get(`${(thunkAPI.getState() as AppState).config.rootPath}/active_plan/`);
    return response.data;
  }
);

// 出発日を更新する処理
export const updateStartDateToApi = createAsyncThunk<void, void>(
  'plan/updateStartTime',
  async (arg: void, thunkAPI) => {
    const { id, startDate } = (thunkAPI.getState() as AppState).plan;
    if (!startDate) return;
    // 日付がJSTのためそのままAPIに渡すと日付がずれるので文字列として日付のみをサーバへ送信
    await appApi.patch(`${(thunkAPI.getState() as AppState).config.rootPath}/plans/${id}`, {
      start_date: formatDate(startDate, 0, '-'),
    });
  }
);

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    reset: (state: Plan) => {
      Object.assign(state, initialState);
    },
    setPlan: (state: Plan, action: PayloadAction<{ plan: Plan }>) => {
      if (action.payload.plan) {
        Object.assign(state, action.payload.plan);
      }
    },
    changePriorityCar: (state: Plan, action: PayloadAction<boolean>) => {
      state.priorityCar = action.payload;
    },
    changePriorityMode: (state: Plan, action: PayloadAction<string>) => {
      state.priorityMode = action.payload;
    },
    changeDate: (state: Plan, action: PayloadAction<Date | undefined>) => {
      state.startDate = action.payload;
    },
    setPlanId: (state: Plan, action: PayloadAction<number>) => {
      state.id = action.payload;
    },
  },
  extraReducers: (builder) => {
    // 画面表示時/リロード時にサーバから取得時に呼ばれる。
    builder.addCase(getActivePlanToApi.fulfilled, (state, action) => {
      planSlice.caseReducers.setPlan(state, action);
    });
    builder.addCase(deletePlanToApi.fulfilled, (state, action) => {
      planSlice.caseReducers.setPlan(state, action);
    });
    builder.addCase(createPlanToApi.fulfilled, (state, action) => {
      planSlice.caseReducers.setPlan(state, action);
    });
    builder.addCase(updateActivePlanToApi.fulfilled, (state, action) => {
      planSlice.caseReducers.setPlan(state, action);
    });
  },
});

export const { reset, setPlan, changePriorityCar, changeDate, setPlanId, changePriorityMode } = planSlice.actions;
export default planSlice.reducer;
