import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { AppState, Plan } from '../../types';

import I18n from '../../i18n/i18n';
import { updateNameToApi, setPlan } from '../../slices/planSlice';
import { changeName, changeMemo } from '../../slices/modalPlanSlice';
import { changePlanName } from '../../slices/planMenuSlice';
import { useAppDispatch } from "../../active_plan/hook";

const ModalContentPlan: FC = () => {
  const plan = useSelector<AppState, Plan | undefined>((state: AppState) => state.modalPlan.plan);
  const isError = useSelector<AppState, boolean>((state: AppState) => state.modalPlan.isError);

  if (!plan) {
    return <div data-testid="no_data_plan" />;
  }

  const dispatch = useAppDispatch();
  const update = () => {
    // マイプラン一覧のプラン名を変更する
    dispatch(
      changePlanName({
        id: plan?.id || 0,
        name: plan?.name || '',
      })
    );
    dispatch(setPlan({ plan }));
    //  非同期にてAPIサーバに変更後のプラン名を更新する
    dispatch(updateNameToApi());
  };

  const htmlForIdByName = 'plan_name';
  const htmlForIdByMemo = 'plan_memo';

  return (
    <>
      <div className="jc-modal-for-react__header">
        <h4 className="jc-modal-for-react__header-ttl">
          {I18n.t('japan_concierge.active_plans.show.change_plan_name_memo')}
        </h4>
      </div>
      <div className="jc-modal-for-react__body">
        <div className="jc-modal-content-plan-item">
          <label className="jc-modal-content-plan-item__ttl" htmlFor={htmlForIdByName}>
            {I18n.t('japan_concierge.active_plans.show.name')}
          </label>
          <input
            type="text"
            name={htmlForIdByName}
            id="plan_name"
            data-testid="plan_name"
            value={plan?.name}
            className="jc-modal-content-plan-item__content"
            onChange={(e) => {
              dispatch(changeName(e.target.value));
            }}
          />
          {isError && React.createElement('span', {}, 'This field is required')}
        </div>

        <div className="jc-modal-content-plan-item">
          <label className="jc-modal-content-plan-item__ttl" htmlFor={htmlForIdByMemo}>
            {I18n.t('japan_concierge.active_plans.show.memo')}
          </label>
          <textarea
            className="jc-modal-content-plan-item__content jc-modal-content-plan-item__note"
            data-testid="plan_memo"
            value={plan?.memo}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              dispatch(changeMemo(e.target.value));
            }}
          />
        </div>

        <div className="jc-u-text-center">
          <button
            className="jc-modal-content-done-btn"
            type="button"
            onClick={() => {
              update();
            }}
            data-testid="updateButton"
            disabled={isError}
          >
            {I18n.t('japan_concierge.active_plans.show.confirm')}
          </button>
        </div>
      </div>
    </>
  );
};

export default ModalContentPlan;
