import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { ExportAppState, ExportPlanDaySpot } from '../../types/export_type';
import { formatTime } from '../../utils/datetime';
import { totalStayTransitTimeSelector } from '../../slices/exportSlice';
import IconPencil from '../icons/iconPencilComponent';
import IconChevronRight from '../icons/iconChevronRightComponent';
import { toggle } from '../../slices/modalSlice';
import { getSpotToApi } from '../../slices/modalSpotSlice';
import I18n from '../../i18n/i18n';
import { useAppDispatch } from '../../export/hook';

interface Props {
  exportPlanDaySpot: ExportPlanDaySpot;
  day: number;
  goal: number;
  startTime: Date;
}

const PlanDaySpot: FC<Props> = (props: Props) => {
  const { exportPlanDaySpot, day, goal, startTime } = props;
  const dispatch = useAppDispatch();
  const totalStayTime = useSelector((state: ExportAppState) =>
    totalStayTransitTimeSelector(day, exportPlanDaySpot.position)(state)
  );

  if (!exportPlanDaySpot) {
    return <div data-testid="export_plan_day_spot_no_data" />;
  }

  // [note] フロントでのstringのトリム用関数。汎用関数として別ファイルに切り出しても良い
  const truncate = (str: string, len: number) => (str.length <= len ? str : `${str.substring(0, len)}...`);

  // 滞在時間（開始）を設定
  startTime.setMinutes(startTime.getMinutes() + totalStayTime);
  // 滞在時間（終了）を設定
  const stayEndTime = new Date(startTime);
  stayEndTime.setMinutes(stayEndTime.getMinutes() + exportPlanDaySpot.stay);

  return (
    <div className="jc-export-course-item-spot">
      {/* スポット名 */}
      <h3 className="jc-export-course-item-spot__ttl">{exportPlanDaySpot.spot.name}</h3>

      {/* 滞在時間 */}
      <div className="jc-export-course-item-spot__time">
        {exportPlanDaySpot.position !== goal && `${formatTime(startTime)} 〜 ${formatTime(stayEndTime)}`}
        {exportPlanDaySpot.position === goal && `${formatTime(startTime)}`}
      </div>

      {/* メモ */}
      {exportPlanDaySpot.memo && (
        <div className="jc-export-course-item-spot__memo">
          <IconPencil additionalClassNames={['jc-export-course-item-spot__memo-icon']} />
          {exportPlanDaySpot.memo}
        </div>
      )}

      <div className="jc-export-course-item-spot__detail">
        {/* スポット画像 */}
        {/* TODO: FEチームへ 画像のサイズは100x100で設定してますので、適宜変更してください。 対応後はこのコメントの削除もお願いします */}
        <img
          className="jc-export-course-item-spot__img"
          alt={exportPlanDaySpot.spot.name}
          src={exportPlanDaySpot.spot.image}
          width={100}
          height={100}
        />

        <div className="jc-export-course-item-spot__leads">
          {/* 説明 */}
          <p className="jc-export-course-item-spot__lead">
            {exportPlanDaySpot.spot.description && truncate(exportPlanDaySpot.spot.description, 100)}
          </p>

          {/* more btn */}
          <button
            className="jc-export-course-item-spot__more"
            type="button"
            aria-label="StayEdit"
            onClick={() => {
              // dispatch(setContentId('spot'));
              dispatch(toggle());
              dispatch(getSpotToApi(exportPlanDaySpot.spot.id));
            }}
          >
            {I18n.t('japan_concierge.exports.show.more')}
            <IconChevronRight additionalClassNames={['jc-export-course-item-spot__more-icon']} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlanDaySpot;
