import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { mapSpotSelectors } from '../../slices/mapSpotSlice';
import { AppState, MapSpot } from '../../types';
import SpotSlideTileCard from './spotSlideTileCardComponent';
import I18n from '../../i18n/i18n';
import { hidePopup, setPopupContentText, showPopup } from '../../slices/popupSlice';
import { useAppDispatch } from '../../active_plan/hook';
import IconAngleRight from '../icons/iconAngleRightComponent';
import IconAngleLeft from '../icons/iconAngleLeftComponent';

const SpotSlider = (): JSX.Element => {
  const mapSpots = useSelector((state: AppState) => mapSpotSelectors.selectAll(state));
  const isOpen = useSelector((state: AppState) => state.spotSlideTile.isOpen);
  const mapMenuSelectedIndex = useSelector((state: AppState) => state.mapMenuCategory.selectedIndex);
  const isLoading = useSelector((state: AppState) => state.mapSpot.isLoading);
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [hoveringNext, setHoveringNext] = useState(false);
  const [hoveringPrev, setHoveringPrev] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  const selectedIndex = useSelector((state: AppState) => state.planMenu.selectedIndex);
  const updateButtonVisibility = () => {
    const container = containerRef.current;
    if (container) {
      const canScrollLeft = container.scrollLeft > 0;
      const canScrollRight = container.scrollLeft + container.clientWidth < container.scrollWidth;

      setCanScrollLeft(canScrollLeft);
      setCanScrollRight(canScrollRight);
    }
  };

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWindowWidth);
    window.addEventListener('resize', updateButtonVisibility);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
      window.removeEventListener('resize', updateButtonVisibility);
    };
  }, []);

  useEffect(() => {
    if (!hoveringNext && !hoveringPrev) {
      updateButtonVisibility();
    }
  }, [hoveringNext, hoveringPrev]);

  useEffect(() => {
    itemRefs.current = itemRefs.current.slice(0, mapSpots.length);

    updateButtonVisibility();

    if (!hoveringNext && !hoveringPrev) {
      updateButtonVisibility();
    }
  }, [mapSpots]);

  const handleNextClick = () => {
    const container = containerRef.current;
    if (!container) return;

    const containerRight = container.scrollLeft + container.clientWidth;

    for (let i = itemRefs.current.length - 1; i >= 0; i--) {
      const item = itemRefs.current[i];
      if (!item) continue;
      const itemLeft = item.offsetLeft;

      if (itemLeft < containerRight) {
        container.scrollLeft = itemLeft;
        break;
      }
    }
    updateButtonVisibility();
  };

  const handlePrevClick = () => {
    const container = containerRef.current;
    if (!container) return;

    const containerLeft = container.scrollLeft;

    for (let i = 0; i < itemRefs.current.length; i++) {
      const item = itemRefs.current[i];
      if (!item) continue;
      const itemRight = item.offsetLeft + item.clientWidth;

      if (itemRight > containerLeft) {
        container.scrollLeft = itemRight - container.clientWidth;
        break;
      }
    }
    updateButtonVisibility();
  };

  // 未選択、検索中、プラン編集画面以外の場合
  if (mapMenuSelectedIndex === 0 || isLoading || selectedIndex !== 0) {
    return <div data-testid="no_data" />;
  }

  // お気に入り・カテゴリー検索にて検索結果が0件の場合
  if (mapSpots.length === 0) {
    const message = mapMenuSelectedIndex === 1 ? 'no_favorite_spots' : 'no_matching_spot_found';
    dispatch(setPopupContentText(I18n.t(`japan_concierge.active_plans.show.${message}`)));
    dispatch(showPopup());
    setTimeout(() => dispatch(hidePopup()), 3000);

    return <div data-testid="no_data" />;
  }

  return (
    <div className={`jc-map-slider__slides-elms ${!isOpen ? 'jc-u-d-none' : ''}`} data-testid="spotSlider">
      <div ref={containerRef} onScroll={updateButtonVisibility} className="jc-map-slider__slides" id="mapSliders">
        {mapSpots.map((mapSpot: MapSpot, index: number) => (
          <div ref={(el) => (itemRefs.current[index] = el)} key={mapSpot.id}>
            <SpotSlideTileCard mapSpot={mapSpot} />
          </div>
        ))}
        {windowWidth >= 576 && (
          <>
            {(canScrollLeft || hoveringPrev) && (
              <button
                className="jc-btn-style-reset jc-map-slider__page-nation jc-map-slider__page-nation--prev"
                onClick={handlePrevClick}
                onMouseEnter={() => !isTouchDevice && setHoveringPrev(true)}
                onMouseLeave={() => !isTouchDevice && setHoveringPrev(false)}
                onTouchEnd={() => isTouchDevice && setHoveringPrev(false)}
              >
                <IconAngleLeft additionalClassNames={['jc-map-slider__page-nation-icon']} />
              </button>
            )}
            {(canScrollRight || hoveringNext) && (
              <button
                className="jc-btn-style-reset jc-map-slider__page-nation jc-map-slider__page-nation--next"
                onClick={handleNextClick}
                onMouseEnter={() => !isTouchDevice && setHoveringNext(true)}
                onMouseLeave={() => !isTouchDevice && setHoveringNext(false)}
                onTouchEnd={() => isTouchDevice && setHoveringNext(false)}
              >
                <IconAngleRight additionalClassNames={['jc-map-slider__page-nation-icon']} />
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SpotSlider;
