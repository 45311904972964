import React, { FC, useEffect } from 'react';

import Map from './mapComponent';
import Form from './formComponent';
import { MapConfigProps } from '../../types';
import { setLatLng, getCustomSpotNameToApi } from '../../slices/customeSpotSlice';
import { useAppDispatch } from '../../active_plan/hook';
import I18n from '../../i18n/i18n';

// import I18n from '../../i18n/i18n';

type Props = {
  mapConfig: MapConfigProps;
};

const CustomSpotApp: FC<Props> = (props: Props) => {
  const { mapConfig } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLatLng({ lat: mapConfig.startLat, lng: mapConfig.startLng }));
    // 初回、activePlanIdが変更された場合呼び出し時に呼ばれる
    dispatch(getCustomSpotNameToApi());
  }, []);

  return (
    <div className="jc-container">
      <div className="jc-row">
        <div className="jc-col-12 jc-u-text-center jc-u-mb-4">
          <p className="jc-u-mb-1">{I18n.t('japan_concierge.custom_spots.new.contents_description')}</p>
          <p className="jc-u-text-attention">{I18n.t('japan_concierge.custom_spots.new.contents_description_note')}</p>
        </div>
      </div>
      <div className="jc-u-col-12">
        <div className="simple_form jc-custom-spot-form">
          <div className="jc-custom-spot-form__map-wrap jc-u-mb-3 jc-u-mb-lg-0">
            <div className="jc-custom-spot-form__map">
              <Map zoom={mapConfig.initialZoomLevel} center={{ lat: mapConfig.startLat, lng: mapConfig.startLng }} />
            </div>
          </div>
          <div className="jc-custom-spot-form__definition-wrap">
            <Form />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CustomSpotApp);
