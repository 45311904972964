import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { ModalPlanState, Plan } from '../types';

import { toggle } from './modalSlice';
import { setPlan as planSliceSetPlan } from './planSlice';

const initialState: ModalPlanState = {
  isError: false,
  plan: undefined,
};

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const modalPlanSlice = createSlice({
  name: 'modalPlan',
  initialState,
  reducers: {
    reset(state: ModalPlanState) {
      Object.assign(state, initialState);
    },
    setPlan: (state: ModalPlanState, action: PayloadAction<Plan>) => {
      state.plan = action.payload;
    },
    changeName: (state: ModalPlanState, action: PayloadAction<string>) => {
      if (state.plan) {
        state.plan.name = action.payload;
      }
      state.isError = !action.payload;
    },
    changeMemo: (state: ModalPlanState, action: PayloadAction<string>) => {
      if (state.plan) {
        state.plan.memo = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    // 確定ボタン押下後planSpotを初期化
    builder.addMatcher(planSliceSetPlan.match, (state: ModalPlanState) => {
      modalPlanSlice.caseReducers.reset(state);
    });
    // Modalが閉じられたらplanSpotを初期化
    builder.addMatcher(toggle.match, (state: ModalPlanState) => {
      modalPlanSlice.caseReducers.reset(state);
    });
  },
});

export const { reset, setPlan, changeName, changeMemo } = modalPlanSlice.actions;
export default modalPlanSlice.reducer;
