import React, { FC } from 'react';
import { Website } from '../../types';

type Props = {
  website: Website;
};

const WebsiteFC: FC<Props> = (props: Props) => {
  const { website } = props;

  return (
    <a href={website.url} className="stock-near-sites" target="_blank" rel="noreferrer" data-testid="websiteFC">
      <div className="stock-near-sites__img-wrap">
        <img src={website.image} alt={website.name} className="stock-near-sites__img" />
      </div>
      <div className="stock-near-sites__detail-wrap">
        <div className="stock-near-sites__txts">
          <div className="stock-near-sites__ttl">{website.name}</div>
          <div className="stock-near-sites__sub-ttl">{website.areaName}</div>
        </div>
      </div>
    </a>
  );
};

export default WebsiteFC;
