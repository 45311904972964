import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { ModalState } from '../types';
import { updateStay, updateModeAndTransitTime } from './planSpotSlice';
import { updateStartTime } from './planDaySlice';
import { setPlan } from './planSlice';

const initialState: ModalState = {
  isOpen: false,
  contentId: undefined,
  clientHeight: 0,
  iframeRect: undefined,
};

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    reset: (state: ModalState) => {
      Object.assign(state, initialState);
    },
    toggle: (state: ModalState) => {
      state.isOpen = !state.isOpen;
    },
    setContentId: (state: ModalState, action: PayloadAction<string>) => {
      state.contentId = action.payload;
    },
    setClientHeight: (state: ModalState, action: PayloadAction<number>) => {
      state.clientHeight = action.payload;
    },
    setIframeTop: (state: ModalState, action: PayloadAction<DOMRect>) => {
      state.iframeRect = action.payload;
    },
    resetIsOpen: (state: ModalState) => {
      state.isOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(updateStay.match, (state: ModalState) => {
      modalSlice.caseReducers.resetIsOpen(state);
    });
    builder.addMatcher(updateModeAndTransitTime.match, (state: ModalState) => {
      modalSlice.caseReducers.resetIsOpen(state);
    });
    builder.addMatcher(updateStartTime.match, (state: ModalState) => {
      modalSlice.caseReducers.resetIsOpen(state);
    });
    builder.addMatcher(setPlan.match, (state: ModalState) => {
      modalSlice.caseReducers.resetIsOpen(state);
    });
  },
});

export const { reset, toggle, setContentId, setClientHeight, setIframeTop } = modalSlice.actions;

export default modalSlice.reducer;
