import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { AppState, ModalTransitState, Transit } from '../types';

import { toggle } from './modalSlice';
import { updateModeAndTransitTime } from './planSpotSlice';
import appApi from '../utils/api';

const initialState: ModalTransitState = {
  transit: undefined,
  mode: '',
  isChangeRecommended: false,
  isDefaultValue: false,
  isDisabled: true,
  transitTime: 0,
  transitRemark: '',
};

//-------------------------------------------------------------
// 非同期処理
//-------------------------------------------------------------
// 移動時間更新処理
export const updateTransitTimeToApi = createAsyncThunk<void, ModalTransitState>(
  'modalTransit/update',
  async (arg: ModalTransitState, thunkAPI) => {
    const { isChangeRecommended, transitTime, transitRemark, transit, mode } = arg;
    const { id } = (thunkAPI.getState() as AppState).plan;
    await appApi.patch(
      `${(thunkAPI.getState() as AppState).config.rootPath}/plans/${id}/plan_spots/${transit?.toPlanSpotId}`,
      {
        custom_transit_value: isChangeRecommended ? null : transitTime,
        custom_transit_memo: transitRemark,
        mode,
      }
    );
  }
);

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const modalTransitSlice = createSlice({
  name: 'modalTransit',
  initialState,
  reducers: {
    reset(state: ModalTransitState) {
      Object.assign(state, initialState);
    },
    setTransit: (
      state: ModalTransitState,
      action: PayloadAction<{ transit: Transit | undefined; transitTime: number; transitRemark: string; mode: string }>
    ) => {
      state.transit = action.payload.transit;
      state.transitTime = action.payload.transitTime;
      state.transitRemark = action.payload.transitRemark;
      state.mode = action.payload.mode;
      state.isDisabled = state.mode === 'auto' && state.transitRemark === '';
    },
    changeMode: (state: ModalTransitState, action: PayloadAction<string>) => {
      if (state.transit) {
        state.mode = action.payload;
        state.isChangeRecommended = true;
        state.isDisabled = false;
        state.isDefaultValue = false;
        if (state.mode === 'car') {
          state.transitTime = state.transit.car || 0;
        } else if (state.mode === 'train') {
          state.transitTime = state.transit.train || 0;
        } else if (state.mode === 'walk') {
          state.transitTime = state.transit.walk || 0;
        } else if (state.mode === 'bicycle') {
          state.transitTime = state.transit.bicycle || 0;
        }
      }
    },
    changeTransitTime: (state: ModalTransitState, action: PayloadAction<string>) => {
      if (state.transit) {
        state.transitTime = Number(action.payload);
        state.isChangeRecommended = false;
        state.isDisabled = false;
        state.isDefaultValue = false;
      }
    },
    changeTransitRemark: (state: ModalTransitState, action: PayloadAction<string>) => {
      if (state.transit) {
        state.transitRemark = action.payload;
        state.isDefaultValue = false;
        state.isDisabled = false;
        state.isChangeRecommended = false;
      }
    },
    setDefaultValue: (state: ModalTransitState) => {
      state.mode = 'auto';
      state.transitRemark = '';
      state.isDefaultValue = true;
      state.isDisabled = true;
      state.isChangeRecommended = false;
      if (state.transit?.autoDisplayMode === 'car') {
        state.transitTime = state.transit.car || 0;
      } else if (state.transit?.autoDisplayMode === 'train') {
        state.transitTime = state.transit.train || 0;
      } else if (state.transit?.autoDisplayMode === 'walk') {
        state.transitTime = state.transit.walk || 0;
      } else if (state.transit?.autoDisplayMode === 'bicycle') {
        state.transitTime = state.transit.bicycle || 0;
      }
    },
  },
  extraReducers: (builder) => {
    // Modalが閉じられたらplanSpotを初期化
    builder.addMatcher(toggle.match, (state: ModalTransitState) => {
      modalTransitSlice.caseReducers.reset(state);
    });
    // 確定ボタン押下後planSpotを初期化
    builder.addMatcher(updateModeAndTransitTime.match, (state: ModalTransitState) => {
      modalTransitSlice.caseReducers.reset(state);
    });
  },
});

export const { reset, setTransit, changeMode, changeTransitTime, changeTransitRemark, setDefaultValue } =
  modalTransitSlice.actions;
export default modalTransitSlice.reducer;
