import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppState, Plan } from '../../types';

import IconPencil from '../icons/iconPencilComponent';
import ExportButton from './exportButtonComponent';
import NearSiteButton from './nearSiteButtonComponent';
import { toggle } from '../../slices/modalSlice';
import { setPlan } from '../../slices/modalPlanSlice';

const PlanName: FC = () => {
  const { nearSite } = useSelector((state: AppState) => state.config);
  const plan = useSelector<AppState, Plan>((state: AppState) => state.plan);

  const dispatch = useDispatch();

  const onClickByPlanEdit = () => {
    dispatch(toggle());
    dispatch(setPlan(plan));
  };

  return (
    <div className="jc-container-fluid">
      <div className={'jc-plan-name'}>
        <div className="jc-plan-name__name-area" onClick={onClickByPlanEdit}>
          <h2 className="jc-plan-name__name" data-testid="planName">
            {plan.name}
          </h2>
          {/* eslint-disable-next-line react/button-has-type */}
          <button className="jc-plan-name__rename-btn" data-testid="renameButton">
            <IconPencil additionalClassNames={['jc-plan-name__rename-btn-icon']} />
          </button>
        </div>
        <div className="jc-plan-name__link-area">
          {nearSite && (
            <div>
              <NearSiteButton />
            </div>
          )}
          <div className='jc-plan-name__export-button-area'>
            <ExportButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanName;
