// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { Provider } from 'react-redux';

import App from './app';

import { setId } from '../slices/exportSlice';

import store from './store';
import I18n from '../i18n/i18n';
import { setConfig } from '../slices/configSlice';
import { ConfigProps, MapConfigProps } from '../types';
import Modal from '../components/export/modalComponent';
import Backdrop from '../components/backdrop/backdropComponent';

interface Props {
  config: ConfigProps;
  defaultLocale: string;
  id: string;
  locale: string;
  mapConfig: MapConfigProps;
}

export default class ActivePlan extends Component<Props> {
  constructor(props: Props) {
    super(props);
    I18n.locale = props.locale;
    I18n.fallbacks = true;
    I18n.defaultLocale = props.defaultLocale;
    store.dispatch(setConfig(props.config));
    store.dispatch(setId(props.id));
  }

  render(): JSX.Element {
    const { mapConfig } = this.props;

    return (
      <Provider store={store}>
        <Modal />
        <Backdrop />
        <App mapConfig={mapConfig} />
      </Provider>
    );
  }
}
