import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { AppState, Website } from '../../types';

import WebsiteFC from './websiteComponent';
import BackButton from './backToPlanEditButtonComponent';
import I18n from '../../i18n/i18n';

const WebsiteList: FC = () => {
  const websites = useSelector((state: AppState) => state.planMenu.websites);

  if (websites.length === 0) {
    return <div data-testid="no_data" />;
  }

  return (
    <div className="favorite-spot-list near-site">
      <h3 className="favorite-spot-list__title">
        {I18n.t('japan_concierge.active_plans.show.nearby_tourism_websites')}
      </h3>

      <div className="favorite-spot-list__original-spot-add-button">
        <BackButton />
      </div>

      <div className="near-site__container">
        {(websites || []).map((website: Website) => (
          <WebsiteFC website={website} key={website.name} />
        ))}
      </div>
    </div>
  );
};

export default WebsiteList;
