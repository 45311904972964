import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const IconBicycle: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="bicycle"
    data-testid="bicycle"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <g fill="currentColor">
      <path d="M163.16,43.79c-10.58-.03-19.17,8.53-19.2,19.11,0,.03,0,.06,0,.09-.03,10.58,8.53,19.17,19.11,19.2,.03,0,.06,0,.09,0h87.4l53.44,117.2h-125.52l-21.92-30.4c-3.61-5.03-9.41-8-15.6-8h-52.8c-10.6,0-19.2,8.6-19.2,19.2s8.6,19.2,19.2,19.2h42.96l17.68,24.56-21.28,42.48c-54.86-13.8-110.52,19.49-124.31,74.35-13.8,54.86,19.49,110.52,74.35,124.31,54.86,13.8,110.52-19.49,124.31-74.35,1.01-4,1.77-8.06,2.29-12.15h39.2c6.8-.02,13.08-3.6,16.56-9.44l67.84-114.8,17.36,32.08c-43.69,35.69-50.18,100.05-14.49,143.74s100.05,50.18,143.74,14.49c43.69-35.69,50.18-100.05,14.49-143.74-26.5-32.45-70.13-45.42-110.06-32.73L280.72,57.23c-4.47-8.3-13.14-13.46-22.56-13.44h-95Zm203.88,283.36l25.84,47.76c5.04,9.32,16.68,12.8,26,7.76s12.8-16.68,7.76-26l-25.84-47.76c2.96-.48,5.96-.73,8.96-.72,31.81,0,57.59,25.79,57.59,57.6s-25.79,57.59-57.6,57.59-57.59-25.79-57.59-57.6c0-14.28,5.3-28.04,14.88-38.63h0Zm-256.32,51.44h48c-7.07,31.02-37.94,50.43-68.96,43.36-31.02-7.07-50.43-37.94-43.36-68.96,5.97-26.21,29.28-44.8,56.16-44.8,1.36,0,2.72,.08,4.08,.16l-15.36,38.8c-5.38,10.76-1.01,23.85,9.76,29.22,3.01,1.5,6.32,2.29,9.68,2.3v-.08Zm22.96-38.4l40.56-81.04,58.32,80.96-.08,.08h-98.8Zm72.48-102.4h86.72l-39.12,66.24-47.68-66.24h.08Z" />
    </g>
  </svg>
);

export default IconBicycle;
