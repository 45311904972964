import React, { FC } from 'react';
import TotalTime from './totalTimeComponent';
import PriorityTransit from './priorityTransitComponent';
import OptimizeRouteButton from './optimizeRouteButtonComponent';

const TourRouteApp: FC = () => (
  <div className="app__tour-route-app jc-row jc-align-center" id="planRoute">
    <div className="jc-col-12 jc-col-md">
      <div className="jc-row">
        <div className="jc-col-auto">
          <div className="jc-u-d-flex jc-u-h-100">
            <OptimizeRouteButton location="head" />
            <PriorityTransit />
          </div>
        </div>
      </div>
    </div>
    <div className="jc-col-12 jc-col-md-auto">
      <TotalTime />
    </div>
  </div>
);

export default TourRouteApp;
