import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../types';
import { toggle } from '../../slices/modalSlice';

import ModalContentStay from './modalContentStayComponent';
import ModalContentTransit from './modalContentTransitComponent';
import ModalContentStart from './modalContentStartComponent';
import ModalContentSpot from './modalContentSpotComponent';
import ModalContentCalender from './modalContentCalendarComponent';
import ModalContentPlan from './modalContentPlanComponent';
import IconCloseModal from '../icons/iconCloseModalComponent';

const Modal: FC = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state: AppState) => state.modal.isOpen);
  const clientHeight = useSelector((state: AppState) => state.modal.clientHeight);
  const targetRef = useRef(null);
  const [styleTop, setStyleTop] = useState(0);
  const iframe = useSelector((state: AppState) => state.config.iframe);
  const spot = useSelector((state: AppState) => state.modalSpot.spot);
  const iframeRect = useSelector((state: AppState) => state.modal.iframeRect);

  useEffect(() => {
    if (isModalOpen) {
      // スポットは非同期でデータを取得する為、300msだと高さが取得できない為500msに変更
      setTimeout(
        () => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const rect = targetRef?.current?.getBoundingClientRect();
          // 計算式
          // IFrameのgetBoundingClientRect().top + ブラウザの高さ / 2 - モーダルの高さ / 2 - 12
          // 親フレームで取得したIFrameのgetBoundingClientRect().topでIFrameのtopの位置が取得出来る。スクロールするとIFrameの最上位部分が
          // 見えなくなるとマイナス値となる(15pxほどずれがある)
          const displayTop = -(iframeRect?.top || 0) + (clientHeight || 2) / 2 - (rect?.height || 2) / 2 - 12;
          if (displayTop + (rect?.height || 0) > (iframeRect?.height || 0)) {
            setStyleTop(-(iframeRect?.top || 10));
          } else {
            setStyleTop(displayTop > 10 ? displayTop : 10);
          }
        },
        spot ? 500 : 300
      );
    }
  }, [isModalOpen, iframeRect?.top, spot]);

  if (!isModalOpen) {
    return <div data-testid="no_data" />;
  }

  return (
    <div className="jc-modal-for-react --size_l">
      <div className="jc-modal-for-react__dialog">
        <div
          className="jc-modal-for-react__content"
          style={iframe ? { top: `${styleTop}px`, maxHeight: `${(clientHeight || 400) * 0.8}px` } : {}}
          ref={targetRef}
        >
          <button
            type="button"
            className="jc-modal-for-react__close-btn"
            onClick={() => {
              dispatch(toggle());
            }}
          >
            <IconCloseModal additionalClassNames={['jc-modal-for-react__close-btn-icon']} />
          </button>
          <ModalContentStay />
          <ModalContentTransit />
          <ModalContentStart />
          <ModalContentSpot isEdit={true} />
          <ModalContentCalender />
          <ModalContentPlan />
        </div>
      </div>
    </div>
  );
};

export default Modal;
