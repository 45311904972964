import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { ExportPlanDay, ExportPlanDaySpot } from '../../types/export_type';
import Map from './mapComponent';
import Position from './positionComponent';
import PlanDaySpot from './planDaySpotComponent';
import Transit from './transitComponent';
import SpotList from './spotListComponent';
import { MapConfigProps } from '../../types';
import { setIsShowMap } from '../../slices/exportSlice';

import IconMapLocation from '../icons/iconMapLocationComponent';
import IconChevronDown from '../icons/iconChevronDownComponent';

import I18n from '../../i18n/i18n';

type Props = {
  exportPlanDay: ExportPlanDay;
  mapConfig: MapConfigProps;
};

const ExportApp: FC<Props> = (props: Props) => {
  const { exportPlanDay, mapConfig } = props;
  const dispatch = useDispatch();

  return (
    <div className="jc-export-course" key={exportPlanDay.id}>
      <div className="jc-export-course__day">
        <h2 className="jc-export-course__day-inner">DAY {exportPlanDay.day}</h2>
      </div>

      {(exportPlanDay.exportPlanDaySpots || []).length === 0 && I18n.t('japan_concierge.exports.show.no_data')}

      {/* planSpot / Position / Transit */}
      {(exportPlanDay.exportPlanDaySpots || []).map((exportPlanDaySpot: ExportPlanDaySpot, index: number) => (
        <div className="jc-export-course-item" key={exportPlanDaySpot.id}>
          <div className="jc-export-course-item__row">
            <div className="jc-export-course-item__line" />
            <div>
              {/* JC-API側では移動時間・手段は前のスポットとの紐付けのため先頭のスポットは使用しない */}
              {index > 0 && index < (exportPlanDay.exportPlanDaySpots?.length || 0) && (
                <Transit exportPlanDaySpot={exportPlanDaySpot} />
              )}
            </div>
          </div>

          <div className="jc-export-course-item__row">
            <Position
              key={exportPlanDaySpot.id}
              position={exportPlanDaySpot.position}
              goal={(exportPlanDay.exportPlanDaySpots?.length || 0) - 1}
            />
            <div className="jc-export-course-item__contents">
              {/* スポット情報 */}
              <PlanDaySpot
                day={exportPlanDay.day}
                exportPlanDaySpot={exportPlanDaySpot}
                startTime={new Date(exportPlanDay.startTime)}
                goal={(exportPlanDay.exportPlanDaySpots?.length || 0) - 1}
              />
            </div>
          </div>
        </div>
      ))}

      {/* 地図関連 */}
      {(exportPlanDay.exportPlanDaySpots || []).length > 0 && (
        <div className="jc-export-course-map">
          {/* 地図を見るボタン */}
          <button
            className="jc-export-course-map__btn"
            type="button"
            onClick={() => {
              dispatch(setIsShowMap(exportPlanDay.day - 1));
            }}
          >
            <IconMapLocation additionalClassNames={['jc-export-course-map__btn-map']} />
            {I18n.t('japan_concierge.exports.show.view_map')}
            <IconChevronDown additionalClassNames={['jc-export-course-map__btn-allow']} />
          </button>

          {/* 地図/マーカー表示 */}
          <div className="jc-export-course-map__contents">
            <Map
              exportPlanDaySpots={exportPlanDay.exportPlanDaySpots || []}
              day={exportPlanDay.day - 1}
              zoom={mapConfig.initialZoomLevel}
              center={{ lat: mapConfig.startLat, lng: mapConfig.startLng }}
            />
            <SpotList day={exportPlanDay.day - 1} exportPlanDaySpots={exportPlanDay.exportPlanDaySpots || []} />
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(ExportApp);
