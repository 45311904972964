import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../types';
import { toggle, setContentId } from '../../slices/modalSlice';
import { setDate } from '../../slices/modalCalendarSlice';

import IconCalender from '../icons/iconCalendarComponent';

const PlanDayChangeButton: FC = () => {
  const dispatch = useDispatch();
  const startDate = useSelector((state: AppState) => state.plan.startDate);

  return (
    <button
      className="jc-plan-day-change-btn"
      type="button"
      aria-label="ChangeStartDate"
      onClick={() => {
        dispatch(setContentId('calender'));
        dispatch(toggle());
        dispatch(setDate(startDate));
      }}
    >
      <IconCalender additionalClassNames={['jc-plan-day-change-btn__icon']} />
    </button>
  );
};

export default PlanDayChangeButton;
