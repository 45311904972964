import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const iconPlus: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="plus"
    data-testid="plus"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M423.64,295.94l-127.72,0,0,127.72a39.92,39.92,0,0,1-79.83,0l0-127.72-127.72,0a39.92,39.92,0,0,1,0-79.83s127.73,0,127.74,0l0-127.72a39.92,39.92,0,1,1,79.83,0l0,127.72,127.72,0A39.92,39.92,0,0,1,463.55,256C464,277.46,445.08,296.38,423.64,295.94Z"
    />
  </svg>
);

export default iconPlus;
