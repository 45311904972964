import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import exportReducer from '../slices/exportSlice';
import { ExportAppState } from '../types/export_type';
import configSliceReducer from '../slices/configSlice';
import backdropSliceReducer from '../slices/backdropSlice';
import modalSliceReducer from '../slices/modalSlice';
import modalFavoriteSpotReducer from '../slices/modalSpotSlice';

const rootReducer = combineReducers<ExportAppState>({
  backdrop: backdropSliceReducer,
  config: configSliceReducer,
  export: exportReducer,
  modal: modalSliceReducer,
  modalSpot: modalFavoriteSpotReducer,
});

// reducerにDate型を指定すると[A non-serializable value was detected in the state]のエラーが出力される
// reduxの場合は発生しないが、redux-toolkitの場合のみ発生する模様。なので、serializableCheckを一旦falseに設定
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type AppDispatch = typeof store.dispatch;
export default store;
