import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { MapMenuHeaderState } from '../types/index';

const initialState: MapMenuHeaderState = {
  selectedIndex: 0,
};

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const mapMenuHeaderSlice = createSlice({
  name: 'mapMenuHeader',
  initialState,
  reducers: {
    reset: (state: MapMenuHeaderState) => {
      Object.assign(state, initialState);
    },
    setIndex: (state: MapMenuHeaderState, action: PayloadAction<number>) => {
      state.selectedIndex = action.payload;
    },
  },
});

export const { reset, setIndex } = mapMenuHeaderSlice.actions;

export default mapMenuHeaderSlice.reducer;
