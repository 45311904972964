import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { ConfigProps } from '../types';

const initialState: ConfigProps = {
  eventMenu: false,
  eventMonth: false,
  eventSeason: false,
  helpButton: false,
  helpUrl: '',
  helpUrlAnchorLink: '',
  iframe: false,
  nearSite: false,
  rootPath: '',
  parentUrl: '',
  snsAuth: false,
  socialMediaAuth: {
    name: '',
    provider: '',
    fingerprint: '',
  },
};

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig: (state: ConfigProps, action: PayloadAction<ConfigProps>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setConfig } = configSlice.actions;
export default configSlice.reducer;
