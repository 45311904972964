import React, { FC } from 'react';

interface Props {
  position: number;
  goal: number;
}

const Position: FC<Props> = (props: Props) => {
  const { position, goal } = props;

  const getPosition = (): string => {
    if (position === 0) {
      return 'START';
    }

    if (position === goal) {
      return 'GOAL';
    }

    return String(position);
  };

  const addtionalClass = position === goal ? "jc-export-course-item__position--type_last" : "";

  return  <div className={`jc-export-course-item__position ${addtionalClass}`}>
            <div className="jc-export-course-item__position-inner">
              {getPosition()}
            </div>
          </div>;
};

export default Position;
