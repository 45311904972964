export const formatTime = (time: Date): string => {
  if (time) {
    return `${time.getHours()}:${String(time.getMinutes()).padStart(2, '0')}`;
  }
  return '';
};

export const formatDate = (startTime: Date, num: number, delimiter: string): string => {
  if (startTime) {
    const date = new Date(startTime);
    date.setDate(date.getDate() + num);

    return `${date.getFullYear()}${delimiter}${String(date.getMonth() + 1).padStart(2, '0')}${delimiter}${String(
      date.getDate()
    ).padStart(2, '0')}`;
  }
  return '';
};
