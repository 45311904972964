import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { changePriorityMode, updatePlanToApi } from '../../slices/planSlice';
import { showPopup, hidePopup, setPopupContentText } from '../../slices/popupSlice';
import { initializeModeAndCustomTransitToApi } from '../../slices/planSpotSlice';

import IconCar from '../icons/iconCarComponent';
import IconTrain from '../icons/iconTrainComponent';
import IconBicycle from '../icons/iconBicycleComponent';
import { AppState, PlanSpot } from '../../types';
import { useAppDispatch } from '../../active_plan/hook';
import I18n from '../../i18n/i18n';

const PriorityTransit: FC = () => {
  const priorityMode = useSelector((state: AppState) => state.plan.priorityMode);
  const { entities } = useSelector((state: AppState) => state.planSpot);
  const dispatch = useAppDispatch();

  const popup = () => {
    dispatch(setPopupContentText(I18n.t('japan_concierge.active_plans.show.auto_compute_enabled')));
    dispatch(showPopup());
    setTimeout(() => dispatch(hidePopup()), 5000);
  };

  const onClick = (mode: string) => {
    const planSpots = Object.values(entities).filter((p: PlanSpot | undefined) => p && p.mode !== 'auto');
    if (planSpots && planSpots.length > 0) {
      popup();
    }
    dispatch(changePriorityMode(mode));
    dispatch(updatePlanToApi());
    dispatch(initializeModeAndCustomTransitToApi());
  };

  return (
    <div className="jc-priority-transit-selector">
      <div className="jc-priority-transit-selector__text">
        {I18n.t('japan_concierge.active_plans.show.means_transport')}：
      </div>
      <div className="jc-priority-transit-selector__btn-area">
        <button
          className={`jc-priority-transit-selector__btn ${
            priorityMode === 'car' ? 'jc-priority-transit-selector__btn--state_active' : ''
          }`}
          type="button"
          onClick={() => {
            onClick('car');
          }}
        >
          <IconCar additionalClassNames={['jc-priority-transit-selector__btn-icon']} />
        </button>
        <button
          className={`jc-priority-transit-selector__btn ${
            priorityMode === 'auto' ? 'jc-priority-transit-selector__btn--state_active' : ''
          }`}
          type="button"
          onClick={() => {
            onClick('auto');
          }}
        >
          <IconTrain additionalClassNames={['jc-priority-transit-selector__btn-icon']} />
        </button>
        <button
          className={`jc-priority-transit-selector__btn ${
            priorityMode === 'bicycle' ? 'jc-priority-transit-selector__btn--state_active' : ''
          }`}
          type="button"
          onClick={() => {
            onClick('bicycle');
          }}
        >
          <IconBicycle additionalClassNames={['jc-priority-transit-selector__btn-icon']} />
        </button>
      </div>
    </div>
  );
};

export default PriorityTransit;
