import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../types';
import { planDaySelectors } from '../../slices/planDaySlice';
import OptimizeRouteButton from './optimizeRouteButtonComponent';
import PlanDay from './planDayComponent';
import PlanDayAddButton from './planDayAddButtonComponent';
import PlanDayChangeButton from './planDayChangeButtonComponent';

const PlanDayApp: FC = () => {
  const planDays = useSelector((state: AppState) => planDaySelectors.selectAll(state));

  return (
    <div className="jc-container-fluid">
      <div className="jc-plan-day-app">
        <div className="jc-plan-day-app__optimize-route-button-container">
          <OptimizeRouteButton />
        </div>
        <div className="jc-plan-day-utils">
          <PlanDayChangeButton />
        </div>
        <div className="jc-plan-day-selector">
          <div className="jc-plan-day-select-items">
            <div className="jc-plan-day-select-items__inner">
              {(planDays || []).map((planDay) => (
                <PlanDay planDay={planDay} key={planDay.id} />
              ))}
            </div>
          </div>
        </div>
        <PlanDayAddButton />
      </div>
    </div>
  );
};

export default PlanDayApp;
