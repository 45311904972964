// eslint-disable-next-line no-use-before-define
import React, { FC } from 'react';
import ExportButton from './exportButtonComponent';
import CreatePlanButton from './createPlanButtonComponent';

const FootUtilityApp: FC = () => (
  <div className="jc-foot-utility-app">
    <div>
      <ExportButton location="foot" />
    </div>
    <div>
      <CreatePlanButton />
    </div>
  </div>
);

export default FootUtilityApp;
