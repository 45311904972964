import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const IconTrash: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="trash"
    data-testid="trash"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M112,512a48,48,0,0,1-48-48V122s104,23.43,192,23.43S448,122,448,122V464a48,48,0,0,1-48,48ZM336,208V432a16,16,0,0,0,32,0V208a16,16,0,0,0-32,0Zm-96,0V432a16,16,0,0,0,32,0V208a16,16,0,0,0-32,0Zm-96,0V432a16,16,0,0,0,32,0V208a16,16,0,0,0-32,0ZM48,96A16,16,0,0,1,32,80V48A16,16,0,0,1,48,32H176C189.4,5.13,198.91,0,208,0h96c9.11,0,18.54,5.15,32,32H464a16,16,0,0,1,16,16V80a16,16,0,0,1-16,16Z"
    />
  </svg>
);

export default IconTrash;
