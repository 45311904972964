import { createSlice } from '@reduxjs/toolkit';

import { MapMenuState } from '../types';

const initialState: MapMenuState = {
  isOpen: false,
};

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const mapMenuSlice = createSlice({
  name: 'mapMenu',
  initialState,
  reducers: {
    reset: (state: MapMenuState) => {
      Object.assign(state, initialState);
    },
    toggle: (state: MapMenuState) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { reset, toggle } = mapMenuSlice.actions;

export default mapMenuSlice.reducer;
