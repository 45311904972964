import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { type DateClickArg } from '@fullcalendar/interaction'; // ボタンを日本語表示にするために必要
import allLocales from '@fullcalendar/core/locales-all';

import I18n from '../../i18n/i18n';
import { AppState } from '../../types';
import { toggle } from '../../slices/modalSlice';
import { changedDate } from '../../slices/modalCalendarSlice';
import { planDaySelectors } from '../../slices/planDaySlice';
import { changeDate, updateStartDateToApi } from '../../slices/planSlice';
import { formatDate } from '../../utils/datetime';
import { useAppDispatch } from '../../active_plan/hook';

const ModalContentCalender: FC = () => {
  const dispatch = useAppDispatch();
  const planDays = useSelector((state: AppState) => planDaySelectors.selectAll(state));
  const startDate = useSelector((state: AppState) => state.modalCalendar.startDate);
  const isShown = useSelector((state: AppState) => state.modalCalendar.isShown);

  if (!isShown) {
    return <div data-testid="no_data_startDate" />;
  }

  return (
    <>
      <div className="jc-modal-for-react__header">
        {I18n.t('japan_concierge.active_plans.show.specify_departure_date')}
      </div>
      <div className="jc-modal-for-react__body">
        <div className="jc-modal-content-calender">
          {startDate && (
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              locales={allLocales}
              locale={I18n.locale}
              dateClick={(e: DateClickArg) => {
                dispatch(changedDate(e.date));
              }}
              events={[
                {
                  title: '',
                  start: formatDate(startDate, 0, '-'),
                  end: formatDate(startDate, planDays.length, '-'),
                },
              ]}
              initialDate={formatDate(startDate, 0, '-')}
              selectable
            />
          )}
          {!startDate && (
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              locales={allLocales}
              locale={I18n.locale}
              dateClick={(e: DateClickArg) => {
                dispatch(changedDate(e.date));
              }}
              selectable
            />
          )}
        </div>
        <div className="jc-u-text-center">
          <button
            type="button"
            className="jc-modal-content-done-btn"
            onClick={() => {
              dispatch(toggle());
              dispatch(changeDate(startDate));
              dispatch(updateStartDateToApi());
            }}
          >
            {I18n.t('japan_concierge.active_plans.show.confirm')}
          </button>
        </div>
      </div>
    </>
  );
};

export default ModalContentCalender;
