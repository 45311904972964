import { createSlice } from '@reduxjs/toolkit';
import { BackdropState } from '../types';
import { toggle as toggleModal } from './modalSlice';
import { updateStay, updateModeAndTransitTime } from './planSpotSlice';
import { updateStartTime } from './planDaySlice';
import { setPlan } from './planSlice';

const initialState: BackdropState = {
  isShown: false,
};

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const backdropSlice = createSlice({
  name: 'backdrop',
  initialState,
  reducers: {
    toggle: (state: BackdropState) => {
      state.isShown = !state.isShown;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(toggleModal.match, (state: BackdropState) => {
      backdropSlice.caseReducers.toggle(state);
    });
    builder.addMatcher(updateStay.match, (state: BackdropState) => {
      state.isShown = false;
    });
    builder.addMatcher(updateModeAndTransitTime.match, (state: BackdropState) => {
      state.isShown = false;
    });
    builder.addMatcher(updateStartTime.match, (state: BackdropState) => {
      state.isShown = false;
    });
    builder.addMatcher(setPlan.match, (state: BackdropState) => {
      state.isShown = false;
    });
  },
});

export const { toggle } = backdropSlice.actions;

export default backdropSlice.reducer;
