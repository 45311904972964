import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { formatTime } from '../../utils/datetime';
import { AppState } from '../../types';
import { useAppDispatch } from '../../active_plan/hook';

import { planSpotPositionSelector, planSpotSelectors, updateModeAndTransitTime } from '../../slices/planSpotSlice';
import {
  changeMode,
  changeTransitTime,
  changeTransitRemark,
  updateTransitTimeToApi,
  setDefaultValue,
} from '../../slices/modalTransitSlice';
import { transitPositionSelector, getTransitTime } from '../../slices/transitSlice';

import IconCar from '../icons/iconCarComponent';
import IconTrain from '../icons/iconTrainComponent';
import IconWalking from '../icons/iconWalkingComponent';
import IconBicycle from '../icons/iconBicycleComponent';
import { activePlanDaySelector } from '../../slices/planDaySlice';
import I18n from '../../i18n/i18n';

const ModalContentTransit: FC = () => {
  const dispatch = useAppDispatch();
  const planDay = useSelector((state: AppState) => activePlanDaySelector(state));
  const { mode, transit, transitTime, transitRemark, isDisabled, isDefaultValue, isChangeRecommended } = useSelector(
    (state: AppState) => state.modalTransit
  );
  const planSpot = useSelector((state: AppState) => planSpotSelectors.selectById(state, transit?.toPlanSpotId || 0));
  const planSpots = useSelector((state: AppState) => planSpotPositionSelector(planSpot?.position || 0)(state));
  const transits = useSelector((state: AppState) => transitPositionSelector(planSpot?.position || 0)(state));
  const [errorMessage, setErrorMessage] = useState('');

  // ここに記載することでundifinedの場合はreturnされるので、if文以下はtransit? || planSpot?と記載しなくて良い
  if (!transit || !planSpot || !planDay) {
    return <div data-testid="no_data_transit" />;
  }

  // DAYの起点となる出発時刻
  const planDayStartTime = new Date();
  // planDay.startTimeHour || 9 で判定するとplanDay.startTimeHourが0の場合もnull or undifined判定され９が設定される為以下のようにして
  // 0も設定できるようにしています
  if (planDay.startTimeHour !== undefined) {
    planDayStartTime.setHours(planDay.startTimeHour, planDay.startTimeMinute || 0);
  }
  const htmlForId = 'transit_time_input';
  const htmlForRemarkInputId = 'transit_remark_input';

  // 表示するプランスポットのpositionより以前の全てのスポットの滞在時間の合計値
  const stayTotalBeforeSpot = planSpots.reduce((sum, p) => {
    if (p?.position !== planSpot?.position) {
      return sum + (p?.stay || 0);
    }
    return sum;
  }, 0);

  // 表示するプランスポットのpositionより以前の全ての移動時間の合計値
  const transitTotal = transits.reduce((sum, t) => {
    if (t?.toPlanSpotId !== planSpot.id) {
      const targetPlanSpot = planSpots.find((p) => p?.id === t?.toPlanSpotId);
      const time = getTransitTime(targetPlanSpot?.transitTime || 0, targetPlanSpot?.mode || 'auto', t);
      return sum + time;
    }
    return sum;
  }, 0);

  // 到着時刻
  const arrivalTime = new Date(
    planDayStartTime.setMinutes(planDayStartTime.getMinutes() + transitTime + transitTotal + stayTotalBeforeSpot)
  );

  const isActive = (modeType: string): boolean =>
    mode === modeType || (mode === 'auto' && transit.autoDisplayMode === modeType);

  return (
    <>
      <div className="jc-modal-for-react__header">
        <h4 className="jc-modal-for-react__header-ttl">
          {I18n.t('japan_concierge.active_plans.show.change_mean_time_transport')}
        </h4>
      </div>
      <div className="jc-modal-for-react__body">
        <div className="jc-modal-content-time-list">
          <div className="jc-modal-content-time-list__main-cnt">
            <span className="jc-modal-content-time-list__label">
              {I18n.t('japan_concierge.active_plans.show.means_transport')}
            </span>
            <span className='jc-modal-content-time-list__buttons'>
              <button
                type="button"
                data-testid="trainButton"
                className={`jc-modal-content-time-list__select-btn ${
                  isActive('train') ? 'jc-modal-content-time-list__select-btn--state_active' : ''
                }`}
                onClick={() => {
                  dispatch(changeMode('train'));
                }}
              >
                <IconTrain additionalClassNames={['jc-modal-content-time-list__select-btn-icon']} />
              </button>
              <button
                type="button"
                data-testid="carButton"
                className={`jc-modal-content-time-list__select-btn ${
                  isActive('car') ? 'jc-modal-content-time-list__select-btn--state_active' : ''
                }`}
                onClick={() => {
                  dispatch(changeMode('car'));
                }}
              >
                <IconCar additionalClassNames={['jc-modal-content-time-list__select-btn-icon']} />
              </button>
              <button
                type="button"
                data-testid="walkButton"
                className={`jc-modal-content-time-list__select-btn ${
                  isActive('walk') ? 'jc-modal-content-time-list__select-btn--state_active' : ''
                }`}
                onClick={() => {
                  dispatch(changeMode('walk'));
                }}
              >
                <IconWalking additionalClassNames={['jc-modal-content-time-list__select-btn-icon']} />
              </button>
              <button
                type="button"
                data-testid="bicycleButton"
                className={`jc-modal-content-time-list__select-btn ${
                  isActive('bicycle') ? 'jc-modal-content-time-list__select-btn--state_active' : ''
                }`}
                onClick={() => {
                  dispatch(changeMode('bicycle'));
                }}
              >
                <IconBicycle additionalClassNames={['jc-modal-content-time-list__select-btn-icon']} />
              </button>
            </span>
          </div>
        </div>

        <div className="jc-modal-content-time-list">
          <div className="jc-modal-content-time-list__main-cnt">
            <label className="jc-modal-content-time-list__label" htmlFor={htmlForId}>
              {I18n.t('japan_concierge.active_plans.show.travel_time')}
            </label>
            <div className="needs-validation was-validated">
              <input
                className="jc-modal-content-time-list__input-txt jc-u-me-2"
                type="text"
                id={htmlForId}
                data-testid="transit_time_input"
                pattern="[0-9]*"
                value={transitTime}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.validity.valid) {
                    setErrorMessage('');
                    dispatch(changeTransitTime(e.target.value));
                  } else {
                    setErrorMessage(I18n.t('japan_concierge.active_plans.show.error_message_half_width_numbers'));
                  }
                }}
              />
              <span className="jc-u-pe-2">{I18n.t('japan_concierge.active_plans.show.minutes')}</span>
            </div>
            {(isChangeRecommended || isDefaultValue) && (
              <span className="jc-u-pe-2 jc-modal-content-time-list__note">
                {isChangeRecommended && I18n.t('japan_concierge.active_plans.show.changed_recommended_travel_time')}
                {isDefaultValue && `※${I18n.t('japan_concierge.active_plans.show.settings_reset')}`}
              </span>
            )}
          </div>

          <div className="jc-modal-content-time-list__sub-cnt">
            <span className="jc-modal-content-time-list__valid-txt">{errorMessage}</span>
          </div>
        </div>

        <div className="jc-modal-content-time-list">
          <div className="jc-modal-content-time-list__main-cnt">
            <span className="jc-modal-content-time-list__label">
              {I18n.t('japan_concierge.active_plans.show.arrival_time')}
            </span>
            <span>{formatTime(arrivalTime)}</span>
          </div>
        </div>

        <div className="jc-modal-content-time-list">
          <div className="jc-modal-content-time-list__main-cnt">
            <label className="jc-modal-content-time-list__label" htmlFor={htmlForRemarkInputId}>
              {I18n.t('japan_concierge.active_plans.show.memo')}
            </label>
            <textarea
              className="jc-modal-content-time-list__input-txtarea"
              id={htmlForRemarkInputId}
              data-testid="transit_remark_textarea"
              value={transitRemark}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                dispatch(changeTransitRemark(e.target.value));
              }}
            />
          </div>
        </div>
        <div className="jc-modal-for-react__button-area">
          <button
            className={`jc-modal-content-reset-btn ${isDisabled && 'jc-modal-content-reset-btn--disabled'}`}
            type="button"
            disabled={isDisabled}
            data-testid="setDefaultValueButton"
            onClick={() => {
              dispatch(setDefaultValue());
            }}
          >
            {I18n.t('japan_concierge.active_plans.show.reset_all_settings')}
          </button>

          <button
            className="jc-modal-content-done-btn"
            type="button"
            onClick={() => {
              const targetMode =
                planSpot.transitTime !== transitTime && mode === 'auto' ? transit.autoDisplayMode : mode;
              dispatch(
                updateModeAndTransitTime({
                  mode: targetMode,
                  transitTime,
                  transitRemark,
                  id: transit.toPlanSpotId,
                })
              );
              if (
                planSpot.mode !== mode ||
                planSpot.transitTime !== transitTime ||
                planSpot?.transitRemark !== transitRemark
              ) {
                dispatch(
                  updateTransitTimeToApi({
                    mode: targetMode,
                    transitTime,
                    transitRemark,
                    transit,
                    isChangeRecommended,
                    isDefaultValue,
                    isDisabled,
                  })
                );
              }
            }}
          >
            {I18n.t('japan_concierge.active_plans.show.confirm')}
          </button>
        </div>
      </div>
    </>
  );
};

export default ModalContentTransit;
