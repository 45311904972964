import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { AppState, SpotLink } from '../../types';
import I18n from '../../i18n/i18n';
import OutsideLink from '../plan_spot/outsideLinkComponent';
import IconPlus from '../icons/iconPlusComponent';
import IconTrash from '../icons/iconTrashComponent';
import { selectedImage } from '../../slices/modalSpotSlice';
import {
  createPlanSpotToApi,
  deletePlanSpot,
  deletePlanSpotToApi,
  returnRegistedAsPlanSpotSelector,
} from '../../slices/planSpotSlice';
import { toggle } from '../../slices/modalSlice';
import { useAppDispatch } from '../../active_plan/hook';
import { deleteTransit } from '../../slices/transitSlice';
import ModalContentSpotButton from './modalContentSpotButtonComponent';

interface Props {
  isEdit: boolean;
}

const ModalContentSpot: FC<Props> = (props: Props) => {
  const { isEdit } = props;
  const { spot, isDisplayRemoveButton } = useSelector((state: AppState) => state.modalSpot);
  const selectedImageIndex = useSelector((state: AppState) => state.modalSpot.selectedImageIndex);
  const dispatch = useAppDispatch();
  const planSpot = isEdit
    ? useSelector((state: AppState) => returnRegistedAsPlanSpotSelector(spot?.id || 0)(state))
    : null;

  if (!spot) {
    return <div data-testid="no_data_spot" />;
  }

  const imageUrl = spot.images[selectedImageIndex] || spot.image;
  const isDisplayRemove = isDisplayRemoveButton && planSpot;

  // 1件もデータがない場合、table > tbodyを表示するとwarningが出力され、rspecのテストエラーが発生する可能性がある為１件も表示するデータがない場合は
  // table自体非表示になるようフラグを設定
  const isRow =
    spot.address ||
    spot.tel ||
    spot.utilizationTime ||
    spot.holiday ||
    spot.remarks ||
    spot.url ||
    (spot.links?.length || 0) > 0;

  // 空文字の場合 {spot.address && ..の場合 trueとみなされ、Warning: validateDOMNesting(...)が発生するためそれを抑制する
  const isDisplay = (content: string): boolean => {
    if (content) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="jc-modal-content-spot-info-view">
        {planSpot && isEdit && <ModalContentSpotButton />}
        <h4 className="jc-modal-content-spot-info-view__title">{spot.name}</h4>
        <div
          className={`jc-modal-content-spot-info-view__inner ${
            isRow
              ? 'jc-modal-content-spot-info-view__inner--isTable'
              : 'jc-modal-content-spot-info-view__inner--isNoTable'
          }`}
        >
          <div className="jc-modal-content-spot-info-view__promotion">
            <div className="jc-modal-content-spot-info-view__pic-col">
              <img src={imageUrl} alt={spot.name} className="jc-modal-content-spot-info-view__pic" />
            </div>
            <div className="jc-modal-content-pics-view__thumbs">
              <div className="jc-modal-content-pics-view__thumbs-inner">
                {spot.images.map((image: string, index: number) => (
                  <div
                    className="jc-modal-content-pics-view__thumb"
                    role="button"
                    tabIndex={0}
                    key={image}
                    onClick={() => dispatch(selectedImage(index))}
                  >
                    <img src={image} alt={spot.name} className="jc-modal-content-pics-view__thumb-pic" />
                  </div>
                ))}
              </div>
            </div>
            {/* 編集画面のみプランに追加ボタンを表示する */}
            {isEdit && !isDisplayRemove && (
              <div className="jc-modal-content-pics-view__add-spot-button">
                <button
                  className="jc-add-plan"
                  type="button"
                  aria-label="addPlanButton"
                  onClick={() => {
                    dispatch(toggle());
                    dispatch(createPlanSpotToApi({ spotId: spot.id, position: 'auto' }));
                  }}
                >
                  <IconPlus additionalClassNames={['jc-add-plan__plus-icon']} />
                  {I18n.t('japan_concierge.spot_modals.show.add_to_my_plan')}
                </button>
              </div>
            )}
            {/* アクティブプランに既に登録されている場合のみプランから削除ボタンを表示する */}
            {isEdit && isDisplayRemove && (
              <div className="jc-modal-content-pics-view__remove-spot-button">
                <button
                  className="jc-add-plan"
                  type="button"
                  aria-label="deletePlanSpotButton"
                  onClick={() => {
                    dispatch(toggle());
                    if (planSpot) {
                      dispatch(deletePlanSpotToApi(planSpot.id));
                    }
                    dispatch(deleteTransit(planSpot.id));
                    dispatch(deletePlanSpot({ id: planSpot.id, position: planSpot.position }));
                  }}
                >
                  <IconTrash additionalClassNames={['jc-add-plan__trash-icon']} />
                  {I18n.t('japan_concierge.spot_modals.show.remove_from_my_plan')}
                </button>
              </div>
            )}
          </div>
          {/* eslint-disable react/no-danger */}
          <div
            className="jc-modal-content-spot-info-view__description"
            dangerouslySetInnerHTML={{ __html: spot.description.replace(/\r?\n/g, '<br/>') }}
          />
          {isRow && (
            <div className="jc-modal-content-spot-info-view__info-col">
              <table className="jc-modal-content-table" data-testid="spot_detail_table">
                <tbody>
                  {isDisplay(spot.address) && (
                    <tr className="jc-modal-content-table__tr">
                      <th className="jc-modal-content-table__th">
                        {I18n.t('japan_concierge.spot_modals.show.address')}
                      </th>
                      <td className="jc-modal-content-table__td">{spot.address}</td>
                    </tr>
                  )}
                  {isDisplay(spot.tel) && (
                    <tr className="jc-modal-content-table__tr">
                      <th className="jc-modal-content-table__th">{I18n.t('japan_concierge.spot_modals.show.tel')}</th>
                      <td className="jc-modal-content-table__td">{spot.tel}</td>
                    </tr>
                  )}
                  {isDisplay(spot.utilizationTime) && (
                    <tr className="jc-modal-content-table__tr">
                      <th className="jc-modal-content-table__th">
                        {I18n.t('japan_concierge.spot_modals.show.open_hours')}
                      </th>
                      <td className="jc-modal-content-table__td">{spot.utilizationTime}</td>
                    </tr>
                  )}
                  {isDisplay(spot.holiday) && (
                    <tr className="jc-modal-content-table__tr">
                      <th className="jc-modal-content-table__th">
                        {I18n.t('japan_concierge.spot_modals.show.closed')}
                      </th>
                      <td className="jc-modal-content-table__td">{spot.holiday}</td>
                    </tr>
                  )}
                  {isDisplay(spot.remarks) && (
                    <tr className="jc-modal-content-table__tr">
                      <th className="jc-modal-content-table__th">{I18n.t('japan_concierge.spot_modals.show.note')}</th>
                      <td className="jc-modal-content-table__td">{spot.remarks}</td>
                    </tr>
                  )}
                  {isDisplay(spot.url) && (
                    <tr className="jc-modal-content-table__tr">
                      <th className="jc-modal-content-table__th">{I18n.t('japan_concierge.spot_modals.show.url')}</th>
                      <td className="jc-modal-content-table__td">
                        <a href={spot.url} target="_blank" rel="noreferrer">
                          {spot.url}
                        </a>
                      </td>
                    </tr>
                  )}
                  {(spot.links?.length || 0) > 0 && (
                    <tr className="jc-modal-content-table__tr">
                      <th className="jc-modal-content-table__th">
                        {I18n.t('japan_concierge.spot_modals.show.other_links')}
                      </th>
                      <td className="jc-modal-content-table__td">
                        {/* eslint-disable-next-line array-callback-return */}
                        <div className="jc-modal-content-table__outside-link">
                          {(spot.links || []).map((link: SpotLink) => (
                            <OutsideLink link={link} key={link.id} />
                          ))}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ModalContentSpot;
