import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const IconPencil: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="pencil"
    data-testid="pencil"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M24,511.88c-14.43.34-26.56-14-23.64-28.15l21.2-121.5,262.6-262.6a11.94,11.94,0,0,1,8.51-3.53c5.92-4.11,57.68,54.51,62.27,57.21,2.65,4.38,65.12,60.32,60.84,65.82a11.94,11.94,0,0,1-3.52,8.5l-262.6,262.6-121.5,21.3A25.21,25.21,0,0,1,24,511.88ZM75,372.24c-6-.23-19,2.2-23.32,3.59l-11.3,64.5,31.1,31.1,64.5-11.3c24-44.37-16.39-43.32-48-36.31.74-2.23,4.63-28.71,3.38-37.09C90.39,374.9,86.9,372.6,75,372.24ZM349.26,190.53C342.58,182,209,328,203.31,329.84a15.92,15.92,0,0,0-4.68,11.32c-.48,13.78,17.89,21.45,27.31,11.31L360.57,217.84c10.1-9.39,2.51-27.83-11.31-27.31Zm94,1a11.91,11.91,0,0,1-8.5-3.53l-111-111a11.94,11.94,0,0,1-3.53-8.5C317,61.8,366.78,19,369.88,13.92a48.1,48.1,0,0,1,67.9,0L497.88,74c18.67,17.77,18.62,50.1,0,67.9-5.15,3.35-47.71,52.57-54.6,49.62Z"
    />
  </svg>
);

export default IconPencil;
