import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../types';
import { useAppDispatch } from '../../active_plan/hook';
import { setIndex } from '../../slices/planMenuSlice';
import IconBook from '../icons/iconBookComponent';
import I18n from '../../i18n/i18n';

const PlanEditButton: FC = () => {
  const selectedIndex = useSelector((state: AppState) => state.planMenu.selectedIndex);
  const dispatch = useAppDispatch();

  return (
    <button
      aria-label="PlanEdit"
      className={`jc-top-menu__button ${selectedIndex === 0 ? 'jc-top-menu__button-active' : ''}`}
      type="button"
      data-testid="planEditButton"
      onClick={() => {
        dispatch(setIndex(0));
      }}
    >
      <IconBook additionalClassNames={['jc-top-menu__icon']} />
      <span className="jc-top-menu__text">{I18n.t('japan_concierge.active_plans.show.edit_plan')}</span>
    </button>
  );
};

export default PlanEditButton;
