import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { AppState, PlanDay } from '../../types';
import { formatDate } from '../../utils/datetime';
import IconCircleDelete from '../icons/iconCircleDeleteComponent';
import { activePlanDaySelector, deletePlanDay, deletePlanDayToApi } from '../../slices/planDaySlice';
import { deletePlanSpotWithDay } from '../../slices/planSpotSlice';
import { setDay } from '../../slices/activeDaySlice';
import { setIsRunFitBounds } from '../../slices/mapSlice';
import { useAppDispatch } from '../../active_plan/hook';

type Props = {
  planDay: PlanDay | undefined;
};

const PlanDayFC: FC<Props> = (props: Props) => {
  const dispatch = useAppDispatch();
  const planStartDate = useSelector((state: AppState) => state.plan.startDate);
  const selectedDay = useSelector((state: AppState) => state.activeDay.day);
  const planDayIds = useSelector((state: AppState) => state.planDay.ids);
  const activePlanDay = useSelector((state: AppState) => activePlanDaySelector(state));
  const { planDay } = props;

  if (!planDay) {
    return <div data-testid="no_data" />;
  }

  return (
    <div
      key={planDay.id}
      className={`jc-plan-day-select-item ${planDay.day === selectedDay ? '--state_active' : ''}`}
      data-testid={`planDay-${planDay.day}`}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        dispatch(setDay(planDay.day));
        dispatch(setIsRunFitBounds(true));
      }}
    >
      <div className="jc-plan-day-select-item__title">DAY{planDay.day}</div>
      {planStartDate && (
        <div className="jc-plan-day-select-item__sub-title">{formatDate(planStartDate, planDay.day - 1, '/')}</div>
      )}
      {planDayIds.length > 1 && (
        <button
          type="button"
          aria-label="DeleteDay"
          className="jc-plan-day-select-item__delete"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            // デイ削除によって繰り上がってきた新しい同日のプランデイを表示
            // 繰り上がってくるプランデイがない場合には消したのよりも1日前の日のプランデイを表示
            let day = activePlanDay?.day || 0;
            if (planDay.day === 1 || activePlanDay?.day === planDayIds.length) {
              day -= 1;
            } else if (
              activePlanDay &&
              planDay.day > 1 &&
              activePlanDay.day === planDay.day &&
              activePlanDay?.day > planDayIds.length - 1
            ) {
              day += 1;
            }
            if (day > 0) {
              dispatch(setDay(day));
            }
            dispatch(deletePlanSpotWithDay({ planDayId: planDay.id, day: planDay.day }));
            dispatch(deletePlanDay(planDay.id));
            dispatch(setIsRunFitBounds(true));
            dispatch(deletePlanDayToApi(planDay.id));
          }}
        >
          <IconCircleDelete additionalClassNames={['jc-plan-day-select-item__delete-icon']} />
        </button>
      )}
    </div>
  );
};

export default PlanDayFC;
