import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const IconList: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="chevron-down-circle"
    data-testid="chevron-down-circle"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 13"
  >
    <path
      fill="currentColor"
      d="M1.5,1.5A1.5,1.5,0,1,0,3,3,1.5,1.5,0,0,0,1.5,1.5Zm0,5A1.5,1.5,0,1,0,3,8,1.5,1.5,0,0,0,1.5,6.5Zm0,5A1.5,1.5,0,1,0,3,13a1.5,1.5,0,0,0-1.5-1.5Zm14,.5H5.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h10a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,15.5,12Zm0-10H5.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h10a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,15.5,2Zm0,5H5.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h10a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,15.5,7Z"
      transform="translate(0 -1.5)"
    />
  </svg>
);

export default IconList;
