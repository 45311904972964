import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const IconSort2: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="sort2"
    data-testid="sort2"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M172.88,60c-12.56-16.49-40.55-15.52-51.55,1.79L63.26,119.85c-31.52,37.47,23,77.77,52.37,38.14V432a32,32,0,0,0,64,0V157.25c13.3,17.12,36.18,24.53,53.11,7.86a32,32,0,0,0,0-45.26Z"
    />

    <path
      fill="currentColor"
      d="M448.74,346.89c-16.18-16.21-39.52-9.66-52.37,7.12V80a32,32,0,0,0-64,0V354.75c-29.95-40.63-84.58-.71-53.11,37.4L339.12,452a29.72,29.72,0,0,0,2.14,2.37A32.78,32.78,0,0,0,364.37,464a32,32,0,0,0,26.3-13.78l58.07-58.07A32,32,0,0,0,448.74,346.89Z"
    />
  </svg>
);

export default IconSort2;
