import React, { FC } from 'react';
import IconLink from '../icons/iconLinkComponent';
import { SpotLink } from '../../types';

interface Props {
  link: SpotLink;
}

const OutsideLink: FC<Props> = ({link}) => {
  return (
    <a
      href={link.url}
      className="jc-outside-link jc-plan-spot-other-links__item"
      target="_blank"
      rel="noreferrer"
    >
      <IconLink additionalClassNames={['jc-outside-link__icon']} />
      {link.name}
    </a>
  );
};

export default OutsideLink;
