import React, { useEffect, FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { type EntityId } from '@reduxjs/toolkit';

import { planSpotSelectors } from '../../slices/planSpotSlice';
import { getTransitToApi, getTransitTime, transitSelectors } from '../../slices/transitSlice';
import { setTransit } from '../../slices/modalTransitSlice';
import { toggle, setContentId } from '../../slices/modalSlice';

import { AppState } from '../../types';

import I18n from '../../i18n/i18n';
import { activePlanDaySelector } from '../../slices/planDaySlice';
import { useAppDispatch } from '../../active_plan/hook';
import IconCar from '../icons/iconCarComponent';
import TransitInfo from './transitInfoComponent';
import IconTrain from '../icons/iconTrainComponent';
import IconBicycle from '../icons/iconBicycleComponent';
import IconWalking from '../icons/iconWalkingComponent';
import IconMapRoot from '../icons/iconMapRootComponent';

interface Props {
  fromPlanSpotId: EntityId;
  toPlanSpotId: EntityId;
}

const TransitFC: FC<Props> = (props: Props) => {
  const { fromPlanSpotId, toPlanSpotId } = props;
  const priorityMode = useSelector((state: AppState) => state.plan.priorityMode);
  const fromPlanSpot = useSelector((state: AppState) => planSpotSelectors.selectById(state, fromPlanSpotId));
  const toPlanSpot = useSelector((state: AppState) => planSpotSelectors.selectById(state, toPlanSpotId));
  const planDay = useSelector((state: AppState) => activePlanDaySelector(state));

  const transit = useSelector((state: AppState) => transitSelectors.selectById(state, toPlanSpot?.id || 0));
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (fromPlanSpot && toPlanSpot && fromPlanSpot.day === toPlanSpot.day) {
      dispatch(getTransitToApi({ priorityMode, fromPlanSpot, toPlanSpot }));
      // 初期表示、スポットの追加、スポット間の並び替え時にローダーを表示。
      setIsLoading(true);
    }
  }, [fromPlanSpot, toPlanSpot, priorityMode]);

  if (!transit && isLoading) {
    return (
      <div className="jc-plan-spot__flow jc-plan-spot-loading" data-testid="transit_nodate">
        <div className="jc-row jc-plan-spot-loading__lds-dual-ring" />
      </div>
    );
  }

  if (toPlanSpot?.position === 0 || !transit || !toPlanSpot || !planDay || planDay.day !== transit.day) {
    return (
      <div className="jc-plan-spot__flow" data-testid="transit_nodate">
        <div className="jc-row" />
      </div>
    );
  }

  const formatHour = (hour: number): string => {
    if (!hour) return '';
    return `${hour}${I18n.t('japan_concierge.active_plans.show.hour_short')}`;
  };

  const formatMinute = (hour: number, minute: number): string => {
    if (hour > 0 && minute === 0) return '';
    if (hour === 0 && minute === 0) return `0${I18n.t('japan_concierge.active_plans.show.minute_short')}`;
    return `${hour > 0 ? ` ${minute}` : minute}${I18n.t('japan_concierge.active_plans.show.minute_short')}`;
  };

  const format = (time: number): string => {
    if (!time) return 'ー';

    const hour = Math.floor(time / 60);
    const minute = time % 60;

    return `${formatHour(hour)}${formatMinute(hour, minute)}`;
  };

  const transitTime = getTransitTime(toPlanSpot?.transitTime || 0, toPlanSpot.mode, transit);
  const formatTransitTime = format(transitTime);

  // 行き方URLの取得
  const directionUrl = (): string => {
    if (toPlanSpot.mode === 'car' || (toPlanSpot.mode === 'auto' && transit.autoDisplayMode === 'car')) {
      return transit.carDirectionUrl;
    }
    if (toPlanSpot.mode === 'train' || (toPlanSpot.mode === 'auto' && transit.autoDisplayMode === 'train')) {
      return transit.trainDirectionUrl;
    }
    if (toPlanSpot.mode === 'walk' || (toPlanSpot.mode === 'auto' && transit.autoDisplayMode === 'walk')) {
      return transit.walkDirectionUrl;
    }
    if (toPlanSpot.mode === 'bicycle' || (toPlanSpot.mode === 'auto' && transit.autoDisplayMode === 'bicycle')) {
      return transit.bicycleDirectionUrl;
    }
    return '';
  };

  const isMode = (mode: string): boolean =>
    (toPlanSpot.mode === 'auto' && transit.autoDisplayMode === mode) || toPlanSpot.mode === mode;

  return (
    <div className="jc-plan-spot__transit">
      <div className="jc-plan-spot__transit-timetable">
        <div className="jc-plan-spot__transit-timetable-line" />
        <div className="jc-plan-spot__transit-content-main">
          <div className="jc-plan-spot__transit-view">
            {isMode('walk') && (
              <TransitInfo key="walk" TransitIconComponent={IconWalking} formatTransitTime={formatTransitTime} />
            )}
            {isMode('car') && (
              <TransitInfo key="car" TransitIconComponent={IconCar} formatTransitTime={formatTransitTime} />
            )}
            {isMode('train') && (
              <TransitInfo key="train" TransitIconComponent={IconTrain} formatTransitTime={formatTransitTime} />
            )}
            {isMode('bicycle') && (
              <TransitInfo key="bicycle" TransitIconComponent={IconBicycle} formatTransitTime={formatTransitTime} />
            )}
          </div>

          <button
            type="button"
            className="jc-plan-spot__transit-time-edit-btn"
            aria-label="TransitEdit"
            onClick={() => {
              dispatch(setContentId('transit'));
              dispatch(toggle());
              dispatch(
                setTransit({
                  transit,
                  transitTime,
                  transitRemark: toPlanSpot?.transitRemark || '',
                  mode: toPlanSpot.mode,
                })
              );
            }}
          >
           <span className='jc-u-d-none jc-u-d-md-block'>{I18n.t('japan_concierge.active_plans.show.edit_transit_duration')}</span>
           <span className='jc-u-d-block jc-u-d-md-none'>{I18n.t('japan_concierge.active_plans.show.edit')}</span>
          </button>
        </div>
        <div>
          <a className="jc-plan-spot__direction-btn" target="_blank" href={directionUrl()} rel="noreferrer">
            <IconMapRoot additionalClassNames={['jc-plan-spot__direction-btn-icon']} />
            <span>{I18n.t('japan_concierge.transits.transit.direction')}</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default TransitFC;
