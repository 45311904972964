import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { AppState, Plan } from '../../types';

import CreatePlanButton from './createPlanButtonComponent';
import PlanFC from './planComponent';
import I18n from '../../i18n/i18n';

const PlanList: FC = () => {
  const plans = useSelector((state: AppState) => state.planMenu.plans);

  return (
    <div className="favorite-spot-list plan-list">
      <h4 className="favorite-spot-list__title">{I18n.t('japan_concierge.active_plans.show.my_plans')}</h4>

      <div className="favorite-spot-list__original-spot-add-button">
        <CreatePlanButton />
      </div>
      <div className="plan-list__items">
        <div className="plan-list__item">
          {(plans || []).map((plan: Plan) => (
            <PlanFC plan={plan} key={plan.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlanList;
