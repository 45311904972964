import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { MapMenuMonthState } from '../types';

const initialState: MapMenuMonthState = {
  selectedMonth: 0,
};

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const mapMenuMonthSlice = createSlice({
  name: 'mapMenuMonth',
  initialState,
  reducers: {
    reset: (state: MapMenuMonthState) => {
      Object.assign(state, initialState);
    },
    setMonth: (state: MapMenuMonthState, action: PayloadAction<number>) => {
      // 既に選択状態の場合は解除する必要がある(現行仕様)
      state.selectedMonth = state.selectedMonth === action.payload ? 0 : action.payload;
    },
  },
});

export const { reset, setMonth } = mapMenuMonthSlice.actions;

export default mapMenuMonthSlice.reducer;
