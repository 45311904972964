import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { AppState, ModalSpotState, Spot } from '../types';

import { toggle } from './modalSlice';
import appApi from '../utils/api';

const initialState: ModalSpotState = {
  spot: undefined,
  selectedImageIndex: 0,
  isDisplayRemoveButton: false,
};

//-------------------------------------------------------------
// 非同期処理
//-------------------------------------------------------------
// スポット一覧情報を取得する処理
export const getSpotToApi = createAsyncThunk<Spot, number>('favoriteSpot/spotIndex', async (id: number, thunkAPI) => {
  const response = await appApi.get(`${(thunkAPI.getState() as AppState).config.rootPath}/spot_modals/${id}`);
  return response.data;
});

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const modalSpotSlice = createSlice({
  name: 'modalFavoriteSpot',
  initialState,
  reducers: {
    reset: (state: ModalSpotState) => {
      Object.assign(state, initialState);
    },
    selectedImage: (state: ModalSpotState, action: PayloadAction<number>) => {
      state.selectedImageIndex = action.payload;
    },
    setSpot: (state: ModalSpotState, action: PayloadAction<Spot>) => {
      state.spot = action.payload;
    },
    setIsDisplayRemoveButton: (state: ModalSpotState, action: PayloadAction<boolean>) => {
      state.isDisplayRemoveButton = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSpotToApi.fulfilled, (state: ModalSpotState, action) => {
      modalSpotSlice.caseReducers.setSpot(state, action);
    });
    // Modalが閉じられたらModalFavoriteSpotStateを初期化
    builder.addMatcher(toggle.match, (state: ModalSpotState) => {
      modalSpotSlice.caseReducers.reset(state);
    });
  },
});

export const { reset, selectedImage, setSpot, setIsDisplayRemoveButton } = modalSpotSlice.actions;
export default modalSpotSlice.reducer;
