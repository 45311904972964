import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { ModalCalendarState } from '../types';
import { toggle } from './modalSlice';

const initialState: ModalCalendarState = {
  isShown: false,
  startDate: undefined,
};

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const modalCalendarSlice = createSlice({
  name: 'modalCalendar',
  initialState,
  reducers: {
    reset: (state: ModalCalendarState) => {
      Object.assign(state, initialState);
    },
    setDate: (state: ModalCalendarState, action: PayloadAction<Date | undefined>) => {
      state.startDate = action.payload;
      state.isShown = true;
    },
    changedDate: (state: ModalCalendarState, action: PayloadAction<Date>) => {
      state.startDate = new Date(action.payload);
    },
  },
  extraReducers: (builder) => {
    // Modalが閉じられたらModalFavoriteSpotStateを初期化
    builder.addMatcher(toggle.match, (state: ModalCalendarState) => {
      modalCalendarSlice.caseReducers.reset(state);
    });
  },
});

export const { reset, setDate, changedDate } = modalCalendarSlice.actions;

export default modalCalendarSlice.reducer;
