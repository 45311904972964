import React, { FC } from 'react';

import { useAppDispatch } from '../../export/hook';
import IconChevronRight from '../icons/iconChevronRightComponent';
import { setIsShow } from '../../slices/customeSpotSlice';
import I18n from '../../i18n/i18n';

const backToFavoriteListButton: FC = () => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(setIsShow(false));
  };

  return (
    <button
      data-jc-role="plan-list-plan-new-btn"
      aria-label="plan-list-plan-new-btn"
      className="foot-utility-btn --color_add_plan"
      type="button"
      onClick={handleClick}
    >
      {I18n.t('japan_concierge.active_plans.show.return_favorite_list')}
      <IconChevronRight additionalClassNames={['foot-utility-btn__icon']} />
    </button>
  );
};

export default backToFavoriteListButton;
