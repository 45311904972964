import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../types';
import { setStayTime, changeToRecommendedValue, updateStayToApi, changeMemo } from '../../slices/modalStaySlice';
import { formatTime } from '../../utils/datetime';
import { planSpotPositionSelector, updateStay } from '../../slices/planSpotSlice';
import { getTransitTime, transitPositionSelector } from '../../slices/transitSlice';
import { activePlanDaySelector } from '../../slices/planDaySlice';
import I18n from '../../i18n/i18n';
import { useAppDispatch } from "../../active_plan/hook";

const ModalContentStay: FC = () => {
  const dispatch = useAppDispatch();
  const planDay = useSelector((state: AppState) => activePlanDaySelector(state));
  const planSpot = useSelector((state: AppState) => state.modalStay.planSpot);
  const planSpots = useSelector((state: AppState) => planSpotPositionSelector(planSpot?.position || 0)(state));
  const transits = useSelector((state: AppState) => transitPositionSelector(planSpot?.position || 0)(state));
  const htmlForId = 'stay_time_input';
  const [errorMessage, setErrorMessage] = useState('');

  if (!planSpot || !planDay) {
    return <div data-testid="no_data_stay" />;
  }

  // DAYの起点となる出発時刻
  const planDayStartTime = new Date();
  // planDay.startTimeHour || 9 で判定するとplanDay.startTimeHourが0の場合もnull or undifined判定され９が設定される為以下のようにして
  // 0も設定できるようにしています
  if (planDay.startTimeHour !== undefined) {
    planDayStartTime.setHours(planDay.startTimeHour, planDay.startTimeMinute || 0);
  }

  // 表示するプランスポットのpositionより以前の全てのスポットの滞在時間の合計値
  const stayTotalBeforeSpot = planSpots.reduce((sum, p) => {
    if (p?.position !== planSpot?.position) {
      return sum + (p?.stay || 0);
    }
    return sum;
  }, 0);
  // 表示するプランスポットのpositionより以前の全ての移動時間の合計値
  const transitTotal = transits.reduce((sum, t) => {
    const time = getTransitTime(planSpots.find((p) => p?.id === t?.toPlanSpotId)?.transitTime || 0, planSpot.mode, t);
    return sum + time;
  }, 0);

  // 到着時刻
  const arrivalTime = new Date(
    planDayStartTime.setMinutes(planDayStartTime.getMinutes() + transitTotal + stayTotalBeforeSpot)
  );
  // 出発時刻
  const departureTime = new Date(planDayStartTime.setMinutes(planDayStartTime.getMinutes() + (planSpot.stay || 0)));
  const htmlForMemoInputId = 'plan_spot_memo';

  return (
    <>
      <div className="jc-modal-for-react__header">
        <h4 className="jc-modal-for-react__header-ttl">
          {I18n.t('japan_concierge.active_plans.show.modify_time_of_stay')}
        </h4>
      </div>
      <div className="jc-modal-for-react__body">
        <p className="jc-modal-content-note">
          ※{I18n.t('japan_concierge.active_plans.show.annotation_modifying_your_time')}
        </p>

        <div className="jc-modal-content-stay-item">
          <span className="jc-modal-content-stay-item__ttl">
            {I18n.t('japan_concierge.active_plans.show.arrival_time')}
          </span>
          <span>{formatTime(arrivalTime)}</span>
        </div>

        <div className="jc-modal-content-stay-item">
          <label className="jc-modal-content-stay-item__ttl" htmlFor={htmlForId}>
            {I18n.t('japan_concierge.active_plans.show.stay_time')}
          </label>
          <div className="jc-modal-content-stay-item__content">
            <input
              className="jc-modal-content-stay-item__minute"
              type="text"
              id={htmlForId}
              data-testid="stay_input"
              pattern="[0-9]*"
              value={planSpot.stay}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.validity.valid) {
                  setErrorMessage('');
                  dispatch(setStayTime(e.target.value));
                } else {
                  setErrorMessage(I18n.t('japan_concierge.active_plans.show.error_message_half_width_numbers'));
                }
              }}
            />
            <small className="jc-u-ps-1 jc-u-pe-2">{I18n.t('japan_concierge.active_plans.show.minutes')}</small>
            <button
              className="jc-modal-content-stay-item__btn"
              aria-label="ChangeToRecommended"
              type="button"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                dispatch(changeToRecommendedValue());
              }}
            >
              {I18n.t('japan_concierge.active_plans.show.change_recommended_value')}
            </button>
            {errorMessage &&
              React.createElement(
                'span',
                {},
                I18n.t('japan_concierge.active_plans.show.error_message_half_width_numbers')
              )}
          </div>
        </div>

        <div className="jc-modal-content-stay-item">
          <span className="jc-modal-content-stay-item__ttl">
            {I18n.t('japan_concierge.active_plans.show.departure_time')}
          </span>
          <span>{formatTime(departureTime)}</span>
        </div>

        <div className="jc-modal-content-stay-item">
          <label className="jc-modal-content-stay-item__ttl" htmlFor={htmlForMemoInputId}>
            {I18n.t('japan_concierge.active_plans.show.memo')}
          </label>
          <textarea
            className="jc-modal-content-stay-item__note"
            id={htmlForMemoInputId}
            data-testid="plan_spot_memo_textarea"
            value={planSpot.stayMemo}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              dispatch(changeMemo(e.target.value));
            }}
          />
        </div>

        <div className="jc-u-text-center">
          <button
            className="jc-modal-content-done-btn"
            type="button"
            onClick={() => {
              dispatch(updateStay(planSpot));
              dispatch(updateStayToApi(planSpot));
            }}
          >
            {I18n.t('japan_concierge.active_plans.show.confirm')}
          </button>
        </div>
      </div>
    </>
  );
};

export default ModalContentStay;
