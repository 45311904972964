import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { spotSlidTileState } from '../types';
import { setDay } from './activeDaySlice';

const initialState: spotSlidTileState = {
  selectedSpotId: 0,
  isOpen: true,
};

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const spotSlideTileSlice = createSlice({
  name: 'spotSlideTileSlice',
  initialState,
  reducers: {
    reset: (state: spotSlidTileState) => {
      Object.assign(state, initialState);
    },
    setSpotId: (state: spotSlidTileState, action: PayloadAction<number>) => {
      state.selectedSpotId = action.payload;
    },
    toggle: (state: spotSlidTileState) => {
      state.isOpen = !state.isOpen;
    },
  },
  extraReducers: (builder) => {
    // planDay選択時にinfowindowを閉じる
    builder.addMatcher(setDay.match, (state: spotSlidTileState) => {
      spotSlideTileSlice.caseReducers.setSpotId(state, { payload: 0, type: spotSlideTileSlice.actions.setSpotId.type });
    });
  },
});

export const { reset, setSpotId, toggle } = spotSlideTileSlice.actions;

export default spotSlideTileSlice.reducer;
