import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../types';
import I18n from '../../i18n/i18n';
import { staySelector } from '../../slices/planSpotSlice';
import { transitTimeSelector } from '../../slices/transitSlice';

const TotalTime: FC = () => {
  const stays = useSelector((state: AppState) => staySelector(state));
  const transits = useSelector((state: AppState) => transitTimeSelector(state));

  const stayTotal = stays.reduce((sum, stay) => sum + stay, 0);
  const transitTimeTotal = transits.reduce((sum, transitTime) => sum + transitTime, 0);

  const formatHour = (hour: number): string => {
    if (hour === 0) return '';
    if (I18n.locale === 'ja' || I18n.locale === 'zh-CN' || I18n.locale === 'zh-TW' || I18n.locale === 'ko') {
      return `${hour}${I18n.t('japan_concierge.active_plans.show.hours')}`;
    }
    if (hour === 1) return `${hour} ${I18n.t('japan_concierge.active_plans.show.hour')}`;
    return `${hour} ${I18n.t('japan_concierge.active_plans.show.hours')}`;
  };

  const formatMinute = (hour: number, minute: number): string => {
    if (hour > 0 && minute === 0) return ``;
    if (I18n.locale === 'ja' || I18n.locale === 'zh-CN' || I18n.locale === 'zh-TW' || I18n.locale === 'ko') {
      return ` ${minute}${I18n.t('japan_concierge.active_plans.show.minutes')}`;
    }
    if (hour >= 0 && minute < 2) return ` ${minute} ${I18n.t('japan_concierge.active_plans.show.minute')}`;
    return ` ${minute} ${I18n.t('japan_concierge.active_plans.show.minutes')}`;
  };

  const format = (time: number): string => {
    const hour = Math.floor(time / 60);
    const min = time % 60;

    return `${formatHour(hour)}${formatMinute(hour, min)}`;
  };

  return (
    <div className="jc-plan-total-time">
      <span className="jc-plan-total-time__label">{I18n.t('japan_concierge.active_plans.show.total')} :</span>
      <span className="jc-plan-total-time__val">{format(stayTotal + transitTimeTotal)}</span>
    </div>
  );
};

export default TotalTime;
