import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  type EntityId,
  type EntityState,
  type PayloadAction,
} from '@reduxjs/toolkit';

import { getActivePlanToApi } from './planSlice';
import { ActiveDayState, AppState, PlanDay } from '../types';
import appApi from '../utils/api';

const planDayAdapter = createEntityAdapter<PlanDay>({
  // idフィールドに`id`キー以外のものを使いたい場合は下記のように明示的に指定する
  selectId: (planDay: PlanDay) => planDay.id,
  sortComparer: (a: PlanDay, b: PlanDay) => {
    if (a.day > b.day) {
      return 1;
    }
    return -1;
  },
});
const initialState: EntityState<PlanDay> = planDayAdapter.getInitialState({
  selectedDay: 1,
});

//-------------------------------------------------------------
// 非同期処理
//-------------------------------------------------------------
export const updatePlanDayToApi = createAsyncThunk<void, void>('planDay/update', async (arg: void, thunkAPI) => {
  const { id } = (thunkAPI.getState() as AppState).plan;
  const { entities } = (thunkAPI.getState() as AppState).planDay;
  const { day } = (thunkAPI.getState() as AppState).activeDay;
  const planDay = Object.values(entities).find((p: PlanDay | undefined) => p?.day === day);
  if (planDay) {
    await appApi.patch(`${(thunkAPI.getState() as AppState).config.rootPath}/active_plan/plan_days/${planDay.id}`, {
      plan_id: id,
      plan_day: {
        start_time_hour: planDay.startTimeHour,
        start_time_minute: planDay.startTimeMinute,
        fixed_goal: planDay.fixedGoal,
      },
    });
  }
});

// DAYの追加
export const createPlanDayToApi = createAsyncThunk<PlanDay[], void>('planDay/create', async (arg: void, thunkAPI) => {
  const { id } = (thunkAPI.getState() as AppState).plan;
  const response = await appApi.post(`${(thunkAPI.getState() as AppState).config.rootPath}/active_plan/plan_days/`, {
    plan_id: id,
  });
  return response.data;
});

// DAYの削除
export const deletePlanDayToApi = createAsyncThunk<void, EntityId>(
  'planDay/delete',
  async (arg: EntityId, thunkAPI) => {
    const { id } = (thunkAPI.getState() as AppState).plan;
    await appApi.delete(`${(thunkAPI.getState() as AppState).config.rootPath}/active_plan/plan_days/${arg}`, {
      data: { plan_id: id },
    });
  }
);

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const planDaySlice = createSlice({
  name: 'planDay',
  initialState,
  reducers: {
    reset: (state: EntityState<PlanDay>) => {
      Object.assign(state, initialState);
    },
    setPlanDay: (state: EntityState<PlanDay>, action: PayloadAction<{ planDays: PlanDay[] }>) => {
      planDayAdapter.setAll(state, action.payload.planDays);
    },
    deletePlanDay: (state: EntityState<PlanDay>, action: PayloadAction<EntityId>) => {
      planDayAdapter.removeOne(state, action.payload);
      state.ids.forEach((id: EntityId, index: number) => {
        planDayAdapter.updateOne(state, {
          id,
          changes: { day: index + 1 },
        });
      });
    },
    updateStartTime: (
      state: EntityState<PlanDay>,
      action: PayloadAction<{ id: number; startTimeHour: number; startTimeMinute: number }>
    ) => {
      const { id, startTimeHour, startTimeMinute } = action.payload;
      planDayAdapter.updateOne(state, {
        id,
        changes: { startTimeHour, startTimeMinute },
      });
    },
  },
  extraReducers: (builder) => {
    // 画面表示時/リロード時にサーバから取得時に呼ばれる。
    builder.addCase(getActivePlanToApi.fulfilled, (state: EntityState<PlanDay>, action) => {
      planDaySlice.caseReducers.setPlanDay(state, action);
    });
    // DAY追加時にサーバから取得時に呼ばれる。
    builder.addCase(createPlanDayToApi.fulfilled, (state: EntityState<PlanDay>, action) => {
      planDayAdapter.setAll(state, action.payload);
    });
  },
});

//-------------------------------------------------------------
// Selector
//-------------------------------------------------------------
// 全件取得用
export const planDaySelectors = planDayAdapter.getSelectors<AppState>((state: AppState) => state.planDay);

// 選択されているPlanDayを取得する
export const activePlanDaySelector = createSelector(
  [(state: AppState) => state.planDay, (state: AppState) => state.activeDay],
  (state: EntityState<PlanDay>, stateActiveDay: ActiveDayState): PlanDay | undefined => {
    const ids = state.ids.filter((id: EntityId) => stateActiveDay.day === (state.entities[id]?.day || 0));
    return state.entities[ids[0]];
  }
);

export const { reset, setPlanDay, deletePlanDay, updateStartTime } = planDaySlice.actions;
export default planDaySlice.reducer;
