import React, { FC, useCallback, useState } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

import { setLatLng, setName } from '../../slices/customeSpotSlice';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../active_plan/hook';
import { AppState } from '../../types';

interface MapProps extends google.maps.MapOptions {
  zoom: number;
  center: { lat: number; lng: number };
}

const Map: FC<MapProps> = (mapProps: MapProps) => {
  const { zoom, center } = mapProps;
  const { lat, lng } = useSelector((state: AppState) => state.customSpot);
  const dispatch = useAppDispatch();
  const [placesService, setPlacesService] = useState<any>(null);

  const onLoad = useCallback((mapInstance: google.maps.Map) => {
    mapInstance.setCenter(center);
    setPlacesService(new window.google.maps.places.PlacesService(mapInstance));
  }, []);

  return (
    <GoogleMap
      mapContainerClassName="jc-custom-spot-form__map"
      center={center}
      zoom={zoom}
      options={{
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        gestureHandling: 'greedy',
        clickableIcons: true,
      }}
      onLoad={onLoad}
      onClick={(e) => {
        if (e.latLng) {
          dispatch(setLatLng({ lat: e.latLng.lat(), lng: e.latLng.lng() }));
        }
        if ('placeId' in e) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          placesService.getDetails({ placeId: e.placeId }, (place, status) => {
            if (status === 'OK' && place && place.geometry && place.geometry.location) {
              dispatch(setName(place.name));
            }
          });
        }
      }}
    >
      <Marker position={{ lat, lng }} />
    </GoogleMap>
  );
};

export default Map;
