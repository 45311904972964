import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { MapMenuSeasonState, Season } from '../types';
import { getMapMenuListToApi } from './mapMenuCategorySlice';

const initialState: MapMenuSeasonState = {
  seasons: [],
  selectedId: 0,
  selectedIndex: 0,
};

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const mapMenuSeasonSlice = createSlice({
  name: 'mapMenuSeason',
  initialState,
  reducers: {
    reset: (state: MapMenuSeasonState) => {
      Object.assign(state, initialState);
    },
    setId: (state: MapMenuSeasonState, action: PayloadAction<number>) => {
      // 既に選択状態の場合は解除する必要がある(現行仕様)
      state.selectedId = state.selectedId === action.payload ? 0 : action.payload;
    },
    setIndex: (state: MapMenuSeasonState, action: PayloadAction<number>) => {
      state.selectedIndex = action.payload;
    },
    setSeasons: (state: MapMenuSeasonState, action: PayloadAction<{ seasons: Season[] }>) => {
      state.seasons = action.payload.seasons;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMapMenuListToApi.fulfilled, (state, action) => {
      mapMenuSeasonSlice.caseReducers.setSeasons(state, action);
    });
  },
});

export const { reset, setId, setSeasons, setIndex } = mapMenuSeasonSlice.actions;

export default mapMenuSeasonSlice.reducer;
