import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const IconChevronDown: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="chevron-down"
    data-testid="chevron-down"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M453.77,142.91a40,40,0,0,0-56.57,0L256.13,284,115.06,142.91c-37.68-36.5-93.17,18.71-56.56,56.57L223.37,364.35a40.55,40.55,0,0,0,65.53,0L453.77,199.48A40,40,0,0,0,453.77,142.91Z"
    />
  </svg>
);

export default IconChevronDown;
