import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { deepEqual } from 'fast-equals';
import { OverlayView } from '@react-google-maps/api';

import { AppState, MarkerType } from '../../types';
import PlanSpotMarker from './planSpotMarkerComponent';
import { planSpotMarkerSelector } from '../../slices/planSpotSlice';

// Mapに関してはWrapperの子componentとして外部で指定しないと上手く表示されない。
// 原因はわかりませんが、本家の例も同様の記載方法。useRefが影響していると思います。

const PlanSpotMarkers: FC = () => {
  // useSelector の比較ロジックは状態の「更新」が発生した場合にのみ、再レンダリングが発生する
  // 更新が発生したかどうかの判定には、SameValueではなく[===]が利用されるため、
  // 更新が発生したかどうかチェックする対象は「セレクタの返り値」であり、それは必ずしもstateとは限らない。
  // === で比較する場合、オブジェクト、配列は値ではなく参照先が同じかの判定になるため、毎回 falseとなり再レンダリングが発生する
  // deepEqualを利用して、ネストしているspotオブジェクトの値を比較できるようにしている
  // その為、planSpotのmarker以外の値が更新されたとしてもplanSpotMarkerSelectorは前回と同じ値と判定され再レンダリングの対象外となる
  const planSpotMarkers = useSelector((state: AppState) => planSpotMarkerSelector(state), deepEqual);

  return (
    <>
      {planSpotMarkers.map((marker: MarkerType | undefined) => {
        if (marker) {
          return (
            <OverlayView
              key={Math.random()}
              position={{ lat: marker.lat, lng: marker.lng }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <PlanSpotMarker planSpotMarker={marker} />
            </OverlayView>
          );
        }
        return null;
      })}
    </>
  );
};

export default React.memo(PlanSpotMarkers);
