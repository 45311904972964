import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const IconMapRoot: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="map-location"
    data-testid="map-location"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
  >
    <path
      fill="currentColor"
      d="M11.375,8.75H8.75A.875.875,0,0,1,8.75,7h2.625S14,4.074,14,2.625a2.625,2.625,0,0,0-5.25,0A5.768,5.768,0,0,0,9.989,5.25H8.75a2.625,2.625,0,0,0,0,5.25h2.625a.875.875,0,0,1,0,1.75h-6.3A18.878,18.878,0,0,1,3.779,14h7.6a2.625,2.625,0,0,0,0-5.25Zm0-7a.875.875,0,1,1-.875.875A.874.874,0,0,1,11.375,1.75ZM2.625,7A2.626,2.626,0,0,0,0,9.625C0,11.074,2.625,14,2.625,14S5.25,11.074,5.25,9.625A2.626,2.626,0,0,0,2.625,7Zm0,3.5A.875.875,0,1,1,3.5,9.625.874.874,0,0,1,2.625,10.5Z"
    />
  </svg>
);

export default IconMapRoot;
