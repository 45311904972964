import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { Plan, PlanMenu, FavoriteSpot, Website, AppState } from '../types';
import appApi from '../utils/api';
import { customSpotCreateToApi } from './customeSpotSlice';

const initialState: PlanMenu = {
  favoriteSpots: [],
  isWebsiteShown: false,
  plans: [],
  websites: [],
  selectedIndex: 0,
};

//-------------------------------------------------------------
// 非同期処理
//-------------------------------------------------------------
// プラン情報を取得する処理
export const getPlanListToApi = createAsyncThunk<Plan[], void>('planMenu/index', async (arg: void, thunkAPI) => {
  const response = await appApi.get(`${(thunkAPI.getState() as AppState).config.rootPath}/plans`);
  return response.data;
});

// お気に入りスポット一覧情報を取得する処理
export const getFavoriteSpotListToApi = createAsyncThunk<FavoriteSpot[], void>(
  'planMenu/favoriteSpotIndex',
  async (arg: void, thunkAPI) => {
    const response = await appApi.get(`${(thunkAPI.getState() as AppState).config.rootPath}/favorite_spots`);
    return response.data;
  }
);

// お気に入りスポットを削除する処理
export const deleteFavoriteSpotToApi = createAsyncThunk<void, number>(
  'planMenu/favoriteSpotDelete',
  async (id: number, thunkAPI) => {
    await appApi.delete(`${(thunkAPI.getState() as AppState).config.rootPath}/favorite_spots/${id}`);
  }
);

// 近隣サイト一覧情報を取得する処理
export const getWebsiteListToApi = createAsyncThunk<Website[], void>(
  'planMenu/websiteIndex',
  async (arg: void, thunkAPI) => {
    const response = await appApi.get(`${(thunkAPI.getState() as AppState).config.rootPath}/websites`);
    return response.data;
  }
);

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const planMenuSlice = createSlice({
  name: 'planMenu',
  initialState,
  reducers: {
    reset: (state: PlanMenu) => {
      Object.assign(state, initialState);
    },
    deletePlan: (state: PlanMenu, action: PayloadAction<number>) => {
      state.plans = state.plans.filter((plan: Plan) => plan.id !== action.payload);
    },
    deleteFavoriteSpot: (state: PlanMenu, action: PayloadAction<number>) => {
      state.favoriteSpots = state.favoriteSpots.filter(
        (favoriteSpot: FavoriteSpot) => favoriteSpot.id !== action.payload
      );
    },
    changePlanName: (state: PlanMenu, action: PayloadAction<{ id: number; name: string }>) => {
      const { id, name } = action.payload;
      state.plans = state.plans.map((plan: Plan) => {
        if (plan.id === id) {
          plan.name = name;
        }
        return plan;
      });
    },
    setPlans: (state: PlanMenu, action: PayloadAction<Plan[]>) => {
      state.plans = action.payload;
    },
    setFavoriteSpots: (state: PlanMenu, action: PayloadAction<FavoriteSpot[]>) => {
      state.favoriteSpots = action.payload;
    },
    setWebsites: (state: PlanMenu, action: PayloadAction<Website[]>) => {
      state.websites = action.payload;
    },
    setIndex: (state: PlanMenu, action: PayloadAction<number>) => {
      if (action.payload < 5) {
        state.selectedIndex = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPlanListToApi.fulfilled, (state: PlanMenu, action) => {
      planMenuSlice.caseReducers.setPlans(state, action);
    });
    builder.addCase(getPlanListToApi.rejected, (state: PlanMenu) => {
      state.plans = [];
    });
    builder.addCase(getFavoriteSpotListToApi.fulfilled, (state: PlanMenu, action) => {
      planMenuSlice.caseReducers.setFavoriteSpots(state, action);
    });
    builder.addCase(getFavoriteSpotListToApi.rejected, (state: PlanMenu) => {
      state.favoriteSpots = [];
    });
    builder.addCase(getWebsiteListToApi.fulfilled, (state: PlanMenu, action) => {
      planMenuSlice.caseReducers.setWebsites(state, action);
    });
    builder.addCase(getWebsiteListToApi.rejected, (state: PlanMenu) => {
      state.websites = [];
      state.isWebsiteShown = false;
    });
    builder.addCase(customSpotCreateToApi.fulfilled, (state: PlanMenu, action) => {
      state.selectedIndex = 0;
      state.favoriteSpots = action.payload.favoriteSpots;
    });
  },
});

export const {
  reset,
  deletePlan,
  deleteFavoriteSpot,
  changePlanName,
  setPlans,
  setFavoriteSpots,
  setWebsites,
  setIndex,
} = planMenuSlice.actions;
export default planMenuSlice.reducer;
