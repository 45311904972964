import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  type EntityState,
  type PayloadAction,
  type EntityId,
  createSelector,
} from '@reduxjs/toolkit';

import appApi from '../utils/api';
import { ActiveDayState, AppState, MarkerType, Plan, PlanDay, PlanSpot } from '../types';

import { getActivePlanToApi } from './planSlice';
import { customSpotCreateToApi } from './customeSpotSlice';

// createEntityAdapter
// https://redux-toolkit.js.org/api/createEntityAdapter
// 従来のreducerとの違いはデータを正規化しデータベースのように管理してくれます。(実際はDictionary型)
// Entityにはids, entitiesがあります
// idsは一意となるidの配列（idは指定したキーに変更可能) → selectId
// entitiesは実際のデータを格納する。
// 従来のreducerの場合以下のようになります。
// {
//   tasks: [
//     { id: 1, title: 'aaa', done: false },
//     { id: 2, title: 'bbb', done: false },
//   ];
// }
// 上記をcreateEntityAdapterで管理する場合は以下のようになります。
// {
//   ids: [1, 2]
//   entities: {
//     {
//       1: {id: 1, title: 'aaa', done: false},
//       2: {id: 2, title: 'bbb', done: false}
//     }
//   }
// }
// データの操作に関しては
// https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
const planSpotAdapter = createEntityAdapter<PlanSpot>({
  // idフィールドに`id`キー以外のものを使いたい場合は下記のように明示的に指定する
  selectId: (planSpot: PlanSpot) => planSpot.id,
  // idsが常にpositionの昇順になるよう設定
  sortComparer: (a: PlanSpot, b: PlanSpot) => {
    if (a.position > b.position) {
      return 1;
    }
    return -1;
  },
});
const initialState: EntityState<PlanSpot> = planSpotAdapter.getInitialState();

//-------------------------------------------------------------
// Componentで利用されるplanSpotの共通関数
//-------------------------------------------------------------
export const getPosition = (position: number, goal: number, start: number): string => {
  if (position === 0 || position === start) {
    return 'S';
  }

  if (position === goal) {
    return 'G';
  }

  return String(position - start);
};

//-------------------------------------------------------------
// 非同期処理
//-------------------------------------------------------------
// プランスポット削除処理
// storeの配列から削除し画面描画を先に行う。非同期にてサーバ通信で削除処理を実行し、結果を受け取る
export const deletePlanSpotToApi = createAsyncThunk<PlanSpot[], number>(
  'planSpot/delete',
  async (arg: number, thunkAPI) => {
    const planSpot = (thunkAPI.getState() as AppState).planSpot.entities[arg];
    if (planSpot) {
      // api部分はstimulusで使用されているものを利用
      const response = await appApi.delete(
        `${(thunkAPI.getState() as AppState).config.rootPath}/plans/${planSpot.planId}/plan_spots/${planSpot.id}`
      );
      return response.data;
    }
  }
);

// stimulusから呼ばれる。
// 地図上のinfowindow Startボタン押下時に呼ばれる処理
export const setStartFromInfoWindowToApi = createAsyncThunk<PlanSpot[], PlanSpot>(
  'planSpot/setStartFromInfoWindow',
  async (arg: PlanSpot, thunkAPI) => {
    const { planId, id } = arg;
    // api部分はstimulusで使用されているものを利用
    const response = await appApi.patch(
      `${(thunkAPI.getState() as AppState).config.rootPath}/plans/${planId}/plan_spots/${id}`,
      { position: 'start', custom_transit_value: null, mode: 'auto' }
    );
    return response.data;
  }
);

// 地図上のinfowindow Goalボタン押下時に呼ばれる処理
export const setGoalFromInfoWindowToApi = createAsyncThunk<{ plan: Plan; planSpots: PlanSpot[] }, PlanSpot>(
  'planSpot/setGoalFromInfoWindow',
  async (arg: PlanSpot, thunkAPI) => {
    const { planId, id } = arg;
    // api部分はstimulusで使用されているものを利用
    const response = await appApi.patch(
      `${(thunkAPI.getState() as AppState).config.rootPath}/plans/${planId}/plan_spots/${id}`,
      { position: 'goal' }
    );
    return response.data;
  }
);

// plan_spots_controller.jsから呼ばれる並び替え処理
// 通常のfunction定義をした場合、stimulusにて呼び出した際に以下のエラーが出る
// [Actions must be plain objects. Use custom middleware for async actions]
export const sortToApi = createAsyncThunk<{ plan: Plan; planSpots: PlanSpot[] }, { id: number; position: number }>(
  'planSpot/sortToApi',
  async (arg: { id: number; position: number }, thunkAPI) => {
    const { id, position } = arg;
    const planSpot = (thunkAPI.getState() as AppState).planSpot.entities[id];
    if (planSpot) {
      const response = await appApi.put(
        `${(thunkAPI.getState() as AppState).config.rootPath}/plans/${planSpot.planId}/plan_spots/${planSpot.id}`,
        {
          position: position + 1,
        }
      );
      return response.data;
    }
    return {
      plan: (thunkAPI.getState() as AppState).plan,
      planSpots: (thunkAPI.getState() as AppState).planSpot.entities,
    };
  }
);

// ルート最適化を行う
export const optimizeToApi = createAsyncThunk<PlanSpot[], void>('plan/optimize', async (arg: void, thunkAPI) => {
  const { id } = (thunkAPI.getState() as AppState).plan;
  const { entities } = (thunkAPI.getState() as AppState).planDay;
  const { day } = (thunkAPI.getState() as AppState).activeDay;
  const planDay = Object.values(entities).find((p: PlanDay | undefined) => p?.day === day);
  const response = await appApi.put(`${(thunkAPI.getState() as AppState).config.rootPath}/reorder_plans/${id}`, {
    plan_day_id: planDay?.id,
  });
  return response.data;
});

// planSpot追加処理
export const createPlanSpotToApi = createAsyncThunk<{ planSpots: PlanSpot[] }, { spotId: number; position: string }>(
  'planSpot/create',
  async (arg: { spotId: number; position: string }, thunkAPI) => {
    const { id } = (thunkAPI.getState() as AppState).plan;
    const { entities } = (thunkAPI.getState() as AppState).planDay;
    const { day } = (thunkAPI.getState() as AppState).activeDay;
    const planDay = Object.values(entities).find((p: PlanDay | undefined) => p?.day === day);
    const { spotId, position } = arg;
    const response = await appApi.post(`${(thunkAPI.getState() as AppState).config.rootPath}/plans/${id}/plan_spots`, {
      plan_spot: { spot_id: spotId, position, plan_day_id: planDay?.id },
    });
    return response.data;
  }
);

export const initializeModeAndCustomTransitToApi = createAsyncThunk<{ planSpots: PlanSpot[] }, void>(
  'planSpot/initializeModeAndCustomTransit',
  async (arg: void, thunkAPI) => {
    const { entities } = (thunkAPI.getState() as AppState).planSpot;
    const planSpots = Object.values(entities).filter((p: PlanSpot | undefined) => p && p.mode !== 'auto');

    if (planSpots.length > 0) {
      let response = null;
      for (const planSpot of planSpots) {
        response = await appApi.patch(
          `${(thunkAPI.getState() as AppState).config.rootPath}/plans/${planSpot?.planId}/plan_spots/${planSpot?.id}`,
          {
            mode: 'auto',
            custom_transit_value: null,
          }
        );
      }
      return response?.data;
    }
    return null;
  }
);

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
// redux-toolkit には Immer が入っているので以下のように書く必要がありません。
// return {
//   ...state,
//   value: 123,
// }
// 参考サイト: https://redux-toolkit.js.org/usage/immer-reducers#redux-toolkit-and-immer
const planSpotSlice = createSlice({
  name: 'planSpot',
  initialState,
  reducers: {
    reset: (state: EntityState<PlanSpot>) => {
      Object.assign(state, initialState);
    },
    // planSpotSlice内から呼ばれる共通処理
    resetPosition: (state: EntityState<PlanSpot>) => {
      state.ids.forEach((id: EntityId, index: number) => {
        planSpotAdapter.updateOne(state, {
          id,
          changes: { position: index },
        });
      });
    },
    deletePlanSpot: (state: EntityState<PlanSpot>, action: PayloadAction<{ id: EntityId; position: number }>) => {
      const { id, position } = action.payload;
      // 削除対象の1つ下にあるplanSpotのカスタム移動時間を削除し、modeをautoに更新
      state.ids.forEach((planSpotId: EntityId) => {
        if (state.entities[planSpotId]?.position === position + 1) {
          planSpotAdapter.updateOne(state, {
            id: planSpotId,
            changes: { transitTime: undefined, mode: 'auto' },
          });
        }
      });
      planSpotAdapter.removeOne(state, id);
      // positionの再採番
      planSpotSlice.caseReducers.resetPosition(state);
    },
    setStart: (state: EntityState<PlanSpot>, action: PayloadAction<{ id: EntityId; position: number }>) => {
      // 処理対象となるplanSpotと次のplanSpotのカスタム移動時間をクリアする
      const { id, position } = action.payload;
      const targetPlanSpot = state.entities[id];
      // DAYの先頭に移動するPlanSpotのカスタム移動時間をクリアする
      planSpotAdapter.updateOne(state, {
        id: targetPlanSpot?.id || 0,
        changes: { transitTime: undefined, mode: 'auto' },
      });
      // DAYの先頭に移動するPlanSpotの+1のPlanSpotのカスタム移動時間をクリアする
      state.ids.forEach((_id: EntityId) => {
        if (state.entities[_id]?.position === (targetPlanSpot?.position || 0) + 1) {
          planSpotAdapter.updateOne(state, {
            id: _id,
            changes: { transitTime: undefined, mode: 'auto' },
          });
        }
      });

      // 引数で渡されたDAYの先頭position以降のpositionを+1に設定（更新対象のplanSpotは除く)
      state.ids.forEach((_id: EntityId) => {
        const planSpot = state.entities[_id];
        if (position <= (planSpot?.position || 0) && planSpot?.id !== id) {
          planSpotAdapter.updateOne(state, {
            id: planSpot?.id || 0,
            changes: { position: (planSpot?.position || 0) + 1 },
          });
        }
      });

      // DAYの先頭へ移動
      planSpotAdapter.updateOne(state, {
        id: targetPlanSpot?.id || 0,
        changes: { position },
      });

      // positionの再採番
      planSpotSlice.caseReducers.resetPosition(state);
    },
    setPlanSpots: (state: EntityState<PlanSpot>, action: PayloadAction<{ planSpots: PlanSpot[] }>) => {
      planSpotAdapter.setAll(state, action.payload.planSpots);
    },
    updateModeAndTransitTime: (
      state: EntityState<PlanSpot>,
      action: PayloadAction<{ mode: string; transitTime: number; transitRemark: string; id: number }>
    ) => {
      const { mode, transitTime, transitRemark } = action.payload;
      planSpotAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          mode,
          transitTime,
          transitRemark,
        },
      });
    },
    updateStay: (state: EntityState<PlanSpot>, action: PayloadAction<PlanSpot | undefined>) => {
      const planSpot = action.payload;
      if (planSpot) {
        planSpotAdapter.updateOne(state, {
          id: planSpot.id,
          changes: { stay: planSpot.stay, stayMemo: planSpot.stayMemo },
        });
      }
    },
    deletePlanSpotWithDay: (
      state: EntityState<PlanSpot>,
      action: PayloadAction<{ planDayId: number; day: number }>
    ) => {
      const { planDayId, day } = action.payload;
      // 削除されたplanDayに紐づくplanSpotを削除する
      state.ids.forEach((id: EntityId) => {
        if (state.entities[id]?.planDayId === planDayId) {
          planSpotAdapter.removeOne(state, id);
        }
      });
      // 削除後、planSpot.dayを-1する
      state.ids.forEach((id: EntityId) => {
        const planSpot: PlanSpot | undefined = state.entities[id];
        if (planSpot && day < planSpot.day) {
          planSpotAdapter.updateOne(state, {
            id: planSpot.id,
            changes: { day: planSpot.day - 1 },
          });
        }
      });
      // positionの再採番
      planSpotSlice.caseReducers.resetPosition(state);
    },
  },
  extraReducers: (builder) => {
    // extraReducersの本家サイト: https://redux-toolkit.js.org/api/createslice
    // 外部から呼び出しや、非同期、ドメイン跨いでの呼び出しが可能とのこと
    // pending, fulfilled, rejectedの3つが用意されている
    // https://redux-toolkit.js.org/api/createAsyncThunk
    // プランスポット情報取得に成功した場合
    builder.addCase(getActivePlanToApi.fulfilled, (state, action) => {
      planSpotSlice.caseReducers.setPlanSpots(state, action);
    });
    // infowindowのGAOLボタン押下後、更新に成功した場合
    builder.addCase(setGoalFromInfoWindowToApi.fulfilled, (state: EntityState<PlanSpot>, action) => {
      // setGoalではplanSpotsからゴールに指定されたスポットを配列の最後に入れ直しているが、サーバ側にてスポットの並び順が
      // 変更される為取得した値を設定する
      planSpotSlice.caseReducers.setPlanSpots(state, action);
    });
    // ルート最適化取得後
    builder.addCase(optimizeToApi.fulfilled, (state, action) => {
      planSpotAdapter.setAll(state, action.payload);
    });
    // マイプランに追加押下後、更新に成功した場合
    builder.addCase(createPlanSpotToApi.fulfilled, (state, action) => {
      planSpotSlice.caseReducers.reset(state);
      planSpotSlice.caseReducers.setPlanSpots(state, action);
    });
    // 並び替え処後、更新に成功した場合
    builder.addCase(sortToApi.fulfilled, (state, action) => {
      planSpotSlice.caseReducers.reset(state);
      planSpotSlice.caseReducers.setPlanSpots(state, action);
    });
    // 優先する交通手段が変更された場合、planSpotのmodeをautoに更新する処理が成功した場合
    builder.addCase(initializeModeAndCustomTransitToApi.fulfilled, (state, action) => {
      if (action && action.payload) {
        planSpotSlice.caseReducers.reset(state);
        planSpotSlice.caseReducers.setPlanSpots(state, action);
      }
    });
    builder.addCase(customSpotCreateToApi.fulfilled, (state, action) => {
      planSpotSlice.caseReducers.reset(state);
      planSpotSlice.caseReducers.setPlanSpots(state, action);
    });
  },
});

export const {
  setPlanSpots,
  deletePlanSpot,
  setStart,
  updateModeAndTransitTime,
  resetPosition,
  updateStay,
  reset,
  deletePlanSpotWithDay,
} = planSpotSlice.actions;

//-------------------------------------------------------------
// Selector
//-------------------------------------------------------------
// 全件取得用
export const planSpotSelectors = planSpotAdapter.getSelectors<AppState>((state: AppState) => state.planSpot);

// 指定されたpositionより小さいデータを取得する
type ReturnSelectorType<S> = (state: AppState) => S;
export const planSpotPositionSelector = (position: number): ReturnSelectorType<(PlanSpot | undefined)[]> =>
  createSelector(
    [(state: AppState) => state.planSpot, (state: AppState) => state.activeDay],
    (state: EntityState<PlanSpot>, activeDayState: ActiveDayState): (PlanSpot | undefined)[] => {
      const ids = state.ids.filter((id: EntityId) => position >= (state.entities[id]?.position || 0));
      return Object.values<PlanSpot | undefined>(state.entities).filter(
        (planSpot) => ids.includes(planSpot?.id || 0) && activeDayState.day === planSpot?.day
      );
    }
  );

// 合計時間算出用Selector
export const staySelector = createSelector(
  [(state: AppState) => state.planSpot, (state: AppState) => state.activeDay],
  (state: EntityState<PlanSpot>, activeDayState: ActiveDayState) =>
    Object.values<PlanSpot | undefined>(state.entities).map((planSpot) => {
      if (activeDayState.day === planSpot?.day) {
        return planSpot?.stay || planSpot?.spot?.stay || 0;
      }
      return 0;
    })
);

// 選択されている日付のPlanSpot算出用Selector
export const planSpotInDaySelector = createSelector(
  [(state: AppState) => state.planSpot, (state: AppState) => state.activeDay],
  (state: EntityState<PlanSpot>, activeDayState: ActiveDayState): EntityId[] => {
    const planSpots = Object.values<PlanSpot | undefined>(state.entities).map((planSpot) => {
      if (activeDayState.day === planSpot?.day) {
        return planSpot.id;
      }
      return 0;
    });
    return planSpots.filter((planSpot) => planSpot !== 0);
  }
);

export const planSpotMarkerSelector = createSelector(
  [(state: AppState) => state.planSpot, (state: AppState) => state.activeDay],
  (state: EntityState<PlanSpot>, activeDayState: ActiveDayState): (MarkerType | undefined)[] => {
    const markers = Object.values<PlanSpot | undefined>(state.entities).map((planSpot) => {
      if (planSpot && planSpot.spot && planSpot.mapSpot && activeDayState.day === planSpot.day) {
        return {
          name: planSpot.name,
          lat: planSpot.spot.lat,
          lng: planSpot.spot.lng,
          image: planSpot.mapSpot.markerImage,
          position: planSpot.position,
          spot: planSpot.mapSpot,
          day: planSpot.day,
          planSpotId: planSpot.id,
        };
      }
      return undefined;
    });
    return markers.filter((marker) => marker !== undefined);
  }
);

// 選択されている日付のPlanSpotの最大position算出用Selector
export const planSpotInMaxPositionSelector = createSelector(
  [(state: AppState) => state.planSpot, (state: AppState) => state.activeDay],
  (state: EntityState<PlanSpot>, activeDayState: ActiveDayState): number => {
    const positions = Object.values<PlanSpot | undefined>(state.entities).map((planSpot) => {
      if (activeDayState.day === planSpot?.day) {
        return planSpot.position;
      }
      return 0;
    });
    if (positions.length === 0) return -1;
    if (positions?.filter((position) => position >= 0).length === 0) return -1;
    return positions?.reduce((a: number, b: number): number => Math.max(a, b));
  }
);

// 選択されている日付のPlanSpotの最小position算出用Selector
export const planSpotInMinPositionSelector = createSelector(
  [(state: AppState) => state.planSpot, (state: AppState) => state.activeDay],
  (state: EntityState<PlanSpot>, activeDayState: ActiveDayState): number => {
    const positions = Object.values<PlanSpot | undefined>(state.entities).map((planSpot) => {
      if (activeDayState.day === planSpot?.day) {
        return planSpot.position;
      }
      return -1;
    });
    if (positions.length === 0) return -1;
    if (positions?.filter((position) => position >= 0).length === 0) return -1;
    return positions?.filter((position) => position >= 0).reduce((a: number, b: number): number => Math.min(a, b));
  }
);

// 指定されたspot.idはplanSpotに既に登録されているか
export const isRegistedAsPlanSpotSelector = (spotId: number): ReturnSelectorType<boolean> =>
  createSelector(
    [(state: AppState) => state.planSpot, (state: AppState) => state.activeDay],
    (state: EntityState<PlanSpot>, activeDayState: ActiveDayState): boolean => {
      const results = Object.values<PlanSpot | undefined>(state.entities).map((planSpot) => {
        if (activeDayState.day === planSpot?.day && planSpot?.spot?.id === spotId) {
          return true;
        }
        return false;
      });
      if (results.length === 0) return false;
      if (results?.filter((isRegisted) => isRegisted === true).length === 0) return false;
      return true;
    }
  );

// 指定されたspot.idがplanSpotに存在する場合、PlanSpotインスタンスを返却する。存在しない場合はnullを返却
export const returnRegistedAsPlanSpotSelector = (spotId: number): ReturnSelectorType<PlanSpot | null> =>
  createSelector(
    [(state: AppState) => state.planSpot, (state: AppState) => state.activeDay],
    (state: EntityState<PlanSpot>, activeDayState: ActiveDayState): PlanSpot | null => {
      const result = Object.values<PlanSpot | undefined>(state.entities).find((planSpot) => {
        return activeDayState.day === planSpot?.day && planSpot?.spot?.id === spotId;
      });

      if (result) {
        return result;
      }
      return null;
    }
  );

export default planSpotSlice.reducer;
