import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const IconLink: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="chevron-right"
    data-testid="chevron-right"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 12.444"
  >
    <path
      fill="currentColor"
      d="M14,.583V3.694a.584.584,0,0,1-1,.412l-.868-.868L6.217,9.158a.583.583,0,0,1-.825,0l-.55-.55a.583.583,0,0,1,0-.825l5.919-5.919L9.894,1a.584.584,0,0,1,.412-1h3.111A.583.583,0,0,1,14,.583Zm-4.107,6L9.5,6.971a.583.583,0,0,0-.171.412v3.506H1.556V3.111H7.972a.583.583,0,0,0,.412-.171l.389-.389a.583.583,0,0,0-.412-1H1.167A1.167,1.167,0,0,0,0,2.722v8.556a1.167,1.167,0,0,0,1.167,1.167H9.722a1.167,1.167,0,0,0,1.167-1.167V6.994A.583.583,0,0,0,9.893,6.582Z"
    />
  </svg>
);

export default IconLink;
