import React, { FC, useEffect } from 'react';

import { getWebsiteListToApi, setIndex } from '../../slices/planMenuSlice';
import { useAppDispatch } from '../../export/hook';
import I18n from '../../i18n/i18n';
import IconExternalLink from '../icons/iconExternalLinkComponent';

const NearSiteButton: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getWebsiteListToApi());
  }, []);

  const handleClick = () => {
    dispatch(setIndex(4));
  };

  return (
    <button className="jc-near-site-button" type="button" onClick={handleClick}>
      <IconExternalLink />
      <span>{I18n.t('japan_concierge.active_plans.show.add_spots_from_related_travel_website')}</span>
    </button>
  );
};

export default NearSiteButton;
