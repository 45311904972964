import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const IconQuestionCircle: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="question-circle"
    data-testid="question-circle"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M256-.1C114.61-.1,0,114.52,0,255.9s114.61,256,256,256,256-114.62,256-256S397.38-.1,256-.1Zm-1.43,440.5a36.5,36.5,0,1,1,36.5-36.5A36.5,36.5,0,0,1,254.57,440.4Zm98.65-217.72L297.66,284.3q-11.06,13.38-10.45,32.58v29.55c-7.77,3.68-54.59,4.25-62.43,0V312.58a109.69,109.69,0,0,1,3-26.77q3.28-11.37,12.85-22.73L293.75,202c10.15-12.13,14.47-19.68,15.23-31.81-.4-14.49-4.45-25.85-15.2-34.1a59.89,59.89,0,0,0-35.84-11.88q-23.9,0-39.13,12.38c-11.55,8.59-17.32,44-17.32,44H139.07V170.16q.6-42.94,34.94-70.47,33.75-27.78,80.64-28.29,47.79.51,82.14,26.27,35.54,27,36.14,73C372.53,192.39,366,208.2,353.22,222.68Z"
    />
  </svg>
);

export default IconQuestionCircle;
