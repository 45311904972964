import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../types';
import { toggle } from '../../slices/backdropSlice';

const Backdrop: FC = () => {
  const dispatch = useDispatch();
  const isbackdropShown = useSelector((state: AppState) => state.backdrop.isShown);

  if (!isbackdropShown) {
    return <div data-testid="no_data" />;
  }

  function handleClick() {
    dispatch(toggle());
  }

  function handleKeyDown() {
    return false;
  }

  return (
    <div
      className={`jc-backdrop ${isbackdropShown && 'jc-backdrop--state_show'}`}
      role="button"
      tabIndex={0}
      onClick={() => {
        handleClick();
      }}
      onKeyDown={() => {
        handleKeyDown();
      }}
    />
  );
};

export default Backdrop;
