import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { AppState, FavoriteSpot, MapConfigProps } from '../../types';
import I18n from '../../i18n/i18n';

import BackButton from './backToFavoriteListButtonComponent';
import CustomSpot from './customSpotComponent';
import FavoriteSpotFC from './favoriteSpotComponent';
import OriginalSpotAddButton from './originalSpotAddButtonComponent';

type Props = {
  mapConfig: MapConfigProps;
};

const FavoriteSpotList: FC<Props> = (props: Props) => {
  const { mapConfig } = props;
  const favoriteSpots = useSelector((state: AppState) => state.planMenu.favoriteSpots);
  const { isShow } = useSelector((state: AppState) => state.customSpot);

  if (isShow) {
    return (
      <div className="favorite-spot-list">
        <h3 className="favorite-spot-list__title">{I18n.t('japan_concierge.active_plans.map_menu.new_custom_spot')}</h3>

        <div className="favorite-spot-list__original-spot-add-button">
          <BackButton />
        </div>

        <div className="favorite-spot-list__container">
          <CustomSpot mapConfig={mapConfig} />
        </div>
      </div>
    );
  }

  return (
    <div className="favorite-spot-list">
      <h3 className="favorite-spot-list__title">{I18n.t('japan_concierge.active_plans.show.favorites_list')}</h3>

      {mapConfig.customSpot && (
        <div className="favorite-spot-list__original-spot-add-button">
          <OriginalSpotAddButton />
        </div>
      )}
      {(favoriteSpots || []) && (
        <div className="favorite-spot-list__container">
          {favoriteSpots.map((favoriteSpot: FavoriteSpot) => (
            <FavoriteSpotFC favoriteSpot={favoriteSpot} key={favoriteSpot.id} />
          ))}
        </div>
      )}
      {favoriteSpots.length === 0 && (
        <p className="favorite-spot-list__no-favorite-text">
          {I18n.t('japan_concierge.active_plans.show.no_favorite_spots')}
        </p>
      )}
    </div>
  );
};

export default FavoriteSpotList;
