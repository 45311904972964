import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { ActiveDayState } from '../types';
import { getActivePlanToApi } from './planSlice';

const initialState: ActiveDayState = {
  day: 0,
};

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const activeDaySlice = createSlice({
  name: 'activeDay',
  initialState,
  reducers: {
    setDay: (state: ActiveDayState, action: PayloadAction<number>) => {
      state.day = action.payload;
    },
  },
  extraReducers: (builder) => {
    // 画面表示時/リロード時にサーバから取得時に呼ばれる。
    builder.addCase(getActivePlanToApi.fulfilled, (state, action) => {
      const { planDays } = action.payload;
      // 初期表示以外はDAYの設定は行わない。DAYの設定はreducer.setDayのみ
      if (planDays.length > 0 && state.day === 0) {
        state.day = planDays[0].day;
      }
    });
  },
});

export const { setDay } = activeDaySlice.actions;
export default activeDaySlice.reducer;
