import axios from 'axios';
import Rails from '@rails/ujs';
import qs from 'qs';

const TOKEN_IDENTIFIER = 'iframe-user-token';

function getToken(): string | null {
  return localStorage.getItem(TOKEN_IDENTIFIER);
}

const api = axios.create({
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
});

// get CSRF token
api.interceptors.request.use((config) => ({
  ...config,
  headers: { ...config.headers, 'X-CSRF-Token': Rails.csrfToken() },
}));

// get user token
api.interceptors.request.use((config) => {
  const token = getToken();

  if (token) {
    return { ...config, headers: { ...config.headers, 'USER-TOKEN': token } };
  }

  return config;
});

export default api;
