import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
  createEntityAdapter,
  type EntityState,
  type EntityId,
  createSelector,
} from '@reduxjs/toolkit';

import appApi from '../utils/api';
import { ActiveDayState, AppState, PlanSpot, Transit } from '../types';

const transitAdapter = createEntityAdapter<Transit>({
  // idフィールドに`id`キー以外のものを使いたい場合は下記のように明示的に指定する
  selectId: (transit: Transit) => transit.toPlanSpotId,
});

const initialState: EntityState<Transit> = transitAdapter.getInitialState();

// 表示対象の移動時間を取得
export const getTransitTime = (transitTime: number, mode: string, transit: Transit | undefined): number => {
  if (!transit) {
    return 0;
  }
  if (transitTime > 0) {
    return transitTime;
  }
  if (mode === 'car' || (mode === 'auto' && transit.autoDisplayMode === 'car')) {
    return transit.car || 0;
  }
  if (mode === 'train' || (mode === 'auto' && transit.autoDisplayMode === 'train')) {
    return transit.train || 0;
  }
  if (mode === 'walk' || (mode === 'auto' && transit.autoDisplayMode === 'walk')) {
    return transit.walk || 0;
  }
  if (mode === 'bicycle' || (mode === 'auto' && transit.autoDisplayMode === 'bicycle')) {
    return transit.bicycle || 0;
  }
  return 0;
};

//-------------------------------------------------------------
// 非同期処理
//-------------------------------------------------------------

type GetTransitProps = {
  priorityMode: string;
  fromPlanSpot: PlanSpot;
  toPlanSpot: PlanSpot;
};

// 移動手段情報を取得する処理
export const getTransitToApi = createAsyncThunk<Transit, GetTransitProps>(
  'transit/create',
  async (arg: GetTransitProps, thunkAPI) => {
    const { priorityMode, fromPlanSpot, toPlanSpot } = arg;

    const response = await appApi.post(`${(thunkAPI.getState() as AppState).config.rootPath}/spot_transits`, {
      from_plan_spot_id: fromPlanSpot.id,
      to_plan_spot_id: toPlanSpot.id,
      mode: toPlanSpot.mode,
      priority_mode: priorityMode,
    });
    return { ...response.data, fromPlanSpotId: fromPlanSpot.id, toPlanSpotId: toPlanSpot.id, day: fromPlanSpot.day };
  }
);

// redux-toolkit には Immer が入っているので以下のように書く必要がありません。
// return {
//   ...state,
//   value: 123,
// }
// 参考サイト: https://redux-toolkit.js.org/usage/immer-reducers#redux-toolkit-and-immer
const transitSlice = createSlice({
  name: 'transit',
  initialState,
  reducers: {
    reset: (state: EntityState<Transit>) => {
      Object.assign(state, initialState);
    },
    setTransit: transitAdapter.setOne,
    deleteTransit: (state: EntityState<Transit>, action: PayloadAction<EntityId>) => {
      // 削除対象のfrom, to合わせて削除
      state.ids.forEach((id: EntityId) => {
        const transit = state.entities[id];
        if (action.payload === transit?.fromPlanSpotId || action.payload === transit?.toPlanSpotId) {
          transitAdapter.removeOne(state, id);
        }
      });
    },
  },
  extraReducers: (builder) => {
    // transit情報取得に成功した場合
    builder.addCase(getTransitToApi.fulfilled, (state, action) => {
      transitSlice.caseReducers.setTransit(state, action);
    });
  },
});

export const { setTransit, deleteTransit, reset } = transitSlice.actions;
export const transitSelectors = transitAdapter.getSelectors((state: AppState) => state.transit);
// 指定されたpositionより小さいデータを取得する
type ReturnSelectorType<S> = (state: AppState) => S;
export const transitPositionSelector = (position: number): ReturnSelectorType<(Transit | undefined)[]> =>
  createSelector(
    [(state: AppState) => state.transit, (state: AppState) => state.planSpot, (state: AppState) => state.activeDay],
    (
      stateTransit: EntityState<Transit>,
      statePlanSpot: EntityState<PlanSpot>,
      activeDayState: ActiveDayState
    ): (Transit | undefined)[] => {
      const ids = statePlanSpot.ids.filter(
        (id: EntityId) =>
          position >= (statePlanSpot.entities[id]?.position || 0) &&
          activeDayState.day === statePlanSpot.entities[id]?.day
      );
      const transits = Object.values(stateTransit.entities).filter((transit) =>
        ids.includes(transit?.toPlanSpotId || 0)
      );
      return transits.filter((transit) => transit !== undefined);
    }
  );

export const transitTimeSelector = createSelector(
  [(state: AppState) => state.transit, (state: AppState) => state.planSpot, (state: AppState) => state.activeDay],
  (stateTransit: EntityState<Transit>, statePlanSpot: EntityState<PlanSpot>, activeDayState: ActiveDayState) =>
    Object.values(stateTransit.entities).map((transit) => {
      const planSpot = statePlanSpot.entities[transit?.toPlanSpotId || 0];
      if (activeDayState.day === planSpot?.day) {
        return getTransitTime(planSpot?.transitTime || 0, planSpot?.mode || 'auto', transit);
      }
      return 0;
    })
);
export default transitSlice.reducer;
