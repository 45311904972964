import React, { FC } from 'react';

import IconPlus from '../icons/iconPlusComponent';
import { useDispatch } from 'react-redux';
import I18n from '../../i18n/i18n';
import { setIsShow } from '../../slices/customeSpotSlice';

const OriginalSpotAddButton: FC = () => {
  const dispatch = useDispatch();

  return (
    <button
      className="foot-utility-btn --color_add_plan"
      onClick={() => {
        dispatch(setIsShow(true));
      }}
    >
      <IconPlus additionalClassNames={['btn__icon']} />
      {I18n.t('japan_concierge.active_plans.show.create_new_list')}
    </button>
  );
};

export default OriginalSpotAddButton;
