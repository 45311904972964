import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const IconSort: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="sort"
    data-testid="sort"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 15.75"
  >
    <path
      fill="currentColor"
      d="M8.437,34.25h2.25a.562.562,0,0,0,.562-.562V32.563A.563.563,0,0,0,10.687,32H8.437a.562.562,0,0,0-.562.563v1.125A.562.562,0,0,0,8.437,34.25Zm0,4.5h4.5a.562.562,0,0,0,.563-.562V37.062a.562.562,0,0,0-.562-.562h-4.5a.562.562,0,0,0-.562.563v1.125A.562.562,0,0,0,8.437,38.75Zm9,6.75h-9a.562.562,0,0,0-.562.563v1.125a.562.562,0,0,0,.562.563h9A.563.563,0,0,0,18,47.187V46.062A.563.563,0,0,0,17.437,45.5Zm-9-2.25h6.75a.562.562,0,0,0,.563-.562V41.562A.563.563,0,0,0,15.187,41H8.437a.562.562,0,0,0-.562.563v1.125A.562.562,0,0,0,8.437,43.25Zm-2.25,0H4.5V32.563A.563.563,0,0,0,3.937,32H2.812a.563.563,0,0,0-.562.563V43.25H.562a.563.563,0,0,0-.4.96l2.812,3.375a.562.562,0,0,0,.8,0L6.585,44.21A.563.563,0,0,0,6.187,43.25Z"
      transform="translate(0.001 -32)"
    />
  </svg>
);

export default IconSort;
