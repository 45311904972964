import React, { FC, useEffect } from 'react';

import { getFavoriteSpotListToApi, setIndex } from '../../slices/planMenuSlice';
import { useAppDispatch } from '../../export/hook';
import { useSelector } from 'react-redux';
import { AppState } from '../../types';
import IconHeart from '../icons/iconHeartComponent';
import I18n from '../../i18n/i18n';

const FavoriteButton: FC = () => {
  const dispatch = useAppDispatch();
  const selectedIndex = useSelector((state: AppState) => state.planMenu.selectedIndex);

  useEffect(() => {
    dispatch(getFavoriteSpotListToApi());
  }, []);

  const handleClick = () => {
    dispatch(setIndex(1));
  };

  return (
    <button
      aria-label="Favorite"
      className={`jc-top-menu__button ${selectedIndex === 1 ? 'jc-top-menu__button-active' : ''}`}
      type="button"
      onClick={handleClick}
      data-testid="favoriteSpotButton"
    >
      <IconHeart additionalClassNames={['jc-top-menu__icon']} />
      <span className="jc-top-menu__txt">{I18n.t('japan_concierge.active_plans.top_menu.my_favorite_spots')}</span>
    </button>
  );
};

export default FavoriteButton;
