import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import type { AppState, Category } from '../../types';
import CategoryButton from './categoryButtonComponent';
import FavoriteButton from './favoriteButtonComponent';
import IconAngleLeft from '../icons/iconAngleLeftComponent';
import IconAngleRight from '../icons/iconAngleRightComponent';

const App: FC = () => {
  const categories = useSelector((state: AppState) => state.mapMenuCategory.categories);
  const itemRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [hoveringNext, setHoveringNext] = useState(false);
  const [hoveringPrev, setHoveringPrev] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

  const updateButtonVisibility = () => {
    const container = containerRef.current;
    if (container) {
      const canScrollLeft = container.scrollLeft > 0;
      const canScrollRight = container.scrollLeft + container.clientWidth < container.scrollWidth;

      setCanScrollLeft(canScrollLeft);
      setCanScrollRight(canScrollRight);
    }
  };

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWindowWidth);
    window.addEventListener('resize', updateButtonVisibility);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
      window.removeEventListener('resize', updateButtonVisibility);
    };
  }, []);

  useEffect(() => {
    if (!hoveringNext && !hoveringPrev) {
      updateButtonVisibility();
    }
  }, [hoveringNext, hoveringPrev]);

  useEffect(() => {
    updateButtonVisibility();
    if (!hoveringNext && !hoveringPrev) {
      updateButtonVisibility();
    }

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', updateButtonVisibility);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', updateButtonVisibility);
      }
    };
  }, [categories]);

  const handleNextClick = () => {
    const container = containerRef.current;
    if (!container) return;

    const containerRight = container.scrollLeft + container.clientWidth;

    for (let i = itemRefs.current.length - 1; i >= 0; i--) {
      const item = itemRefs.current[i];
      if (!item) continue;
      const itemLeft = item.offsetLeft;

      if (itemLeft < containerRight) {
        container.scrollLeft = itemLeft - 35;
        break;
      }
    }
    updateButtonVisibility();
  };

  const handlePrevClick = () => {
    const container = containerRef.current;
    if (!container) return;

    const containerLeft = container.scrollLeft;

    for (let i = 0; i < itemRefs.current.length; i++) {
      const item = itemRefs.current[i];
      if (!item) continue;
      const itemRight = item.offsetLeft + item.clientWidth;

      if (itemRight > containerLeft) {
        container.scrollLeft = itemRight - container.clientWidth + 35;
        break;
      }
    }
    updateButtonVisibility();
  };

  if (categories.length === 0) {
    return <div data-testid="no_data" />;
  }

  return (
    <div className="jc-map-category-container">
      <div className="jc-map-category" ref={containerRef}>
        {/* お気に入り */}
        <FavoriteButton ref={(el) => (itemRefs.current[0] = el)} />

        {/* カテゴリー */}
        {categories.map((category: Category, index: number) => (
          <CategoryButton
            ref={(el) => (itemRefs.current[index + 1] = el)}
            category={category}
            index={index}
            key={category.id}
          />
        ))}
      </div>
      {windowWidth >= 576 && (
        <>
          {(canScrollLeft || hoveringPrev) && (
            <button
              className="jc-btn-style-reset jc-map-slider__page-nation jc-map-slider__page-nation--prev"
              onClick={handlePrevClick}
              onMouseEnter={() => !isTouchDevice && setHoveringPrev(true)}
              onMouseLeave={() => !isTouchDevice && setHoveringPrev(false)}
              onTouchEnd={() => isTouchDevice && setHoveringPrev(false)}
            >
              <IconAngleLeft additionalClassNames={['jc-map-slider__page-nation-icon']} />
            </button>
          )}
          {(canScrollRight || hoveringNext) && (
            <button
              className="jc-btn-style-reset jc-map-slider__page-nation jc-map-slider__page-nation--next"
              onClick={handleNextClick}
              onMouseEnter={() => !isTouchDevice && setHoveringNext(true)}
              onMouseLeave={() => !isTouchDevice && setHoveringNext(false)}
              onTouchEnd={() => isTouchDevice && setHoveringNext(false)}
            >
              <IconAngleRight additionalClassNames={['jc-map-slider__page-nation-icon']} />
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(App);
