import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import CategoryApp from '../components/category';
import FavoriteSpotList from '../components/favorite_spot_list';
import FootUtilityApp from '../components/foot_utility';
import PlanRouteApp from '../components/plan_route';
import Map from '../components/map';
import MapSliderApp from '../components/spot_slider';
import PlanDayApp from '../components/plan_day_list';
import PlanList from '../components/plan_list';
import PlanNameApp from '../components/plan_name';
import PlanSpotApp from '../components/plan_spot';
import SnsApp from '../components/sns';
import TopMenuApp from '../components/top_menu';
import WebsiteList from '../components/website_list';

import { getMapMenuListToApi } from '../slices/mapMenuCategorySlice';
import { getActivePlanToApi } from '../slices/planSlice';
import { setParentUrl, setDataRemote } from '../slices/snsSlice';

import { AppState, ConfigProps, MapConfigProps } from '../types';
import { useAppDispatch } from './hook';

interface Props {
  config: ConfigProps;
  mapConfig: MapConfigProps;
}

const App: FC<Props> = (props: Props) => {
  const dispatch = useAppDispatch();
  const { config, mapConfig } = props;
  const activePlanId = useSelector((state: AppState) => state.plan.id);
  const selectedIndex = useSelector((state: AppState) => state.planMenu.selectedIndex);

  useEffect(() => {
    // 初回、activePlanIdが変更された場合呼び出し時に呼ばれる
    dispatch(getActivePlanToApi());
  }, [activePlanId]);

  useEffect(() => {
    // 初回呼び出し時に呼ばれる
    dispatch(getMapMenuListToApi());
    dispatch(setParentUrl(config.parentUrl));
    dispatch(setDataRemote(config.iframe));
  }, []);

  const favariteList = () => (
    <div>
      <FavoriteSpotList mapConfig={mapConfig} />
    </div>
  );

  const planList = () => (
    <div>
      <PlanList />
    </div>
  );

  const sns = () => (
    <div>
      <SnsApp socialMediaAuth={config.socialMediaAuth} iframe={config.iframe} />
    </div>
  );

  const websiteList = () => (
    <div>
      {/* SNS認証機能有効 & SNSログイン中 & 連携する観光サイト情報が１件以上ある場合 */}
      <WebsiteList />
    </div>
  );

  return (
    <div className="app">
      <div className="jc-container-fluid">
        <div className="jc-row">
          <div className="jc-col-12 jc-u-px-0">
            <TopMenuApp />
          </div>
        </div>
      </div>

      <div className={selectedIndex !== 0 ? 'jc-u-d-none' : ''}>
        {/* [START] plan name section */}
        <div id="plan-name" className="jc-u-py-3">
          <PlanNameApp />
        </div>
        {/* [END] plan name section */}

        {/* [START] plan_days */}
        <PlanDayApp />
        {/* [END] plan_days */}

        {/* [START] spot map */}
        <div className="jc-container-fluid">
          <div className="jc-row">
            <div className="jc-col-12 jc-u-px-0">
              <div className="jc-map-wrap">
                <div className="jc-map">
                  <CategoryApp />
                  <Map
                    zoom={mapConfig.initialZoomLevel}
                    center={{ lat: mapConfig.startLat, lng: mapConfig.startLng }}
                    customSpot={mapConfig.customSpot || false}
                    initialZoomLevel={mapConfig.initialZoomLevel}
                  />
                  <MapSliderApp />
                </div>
              </div>
            </div>
          </div>
          {/* [END] spot map */}

          {/* [START] head utility buttons */}
          <div id="head-utility-buttons" className="app__plan-route-app-container jc-container-fluid">
            <PlanRouteApp />
          </div>
          {/* [END] head utility buttons */}

          {/* [START] plan spot */}
          <PlanSpotApp />
          {/* [END] plan spot */}

          {/* [START] foot utility buttons */}
          <div id="foot-utility-buttons" className="jc-container-fluid jc-u-py-3 jc-u-my-md-7">
            <FootUtilityApp />
          </div>
          {/* [END] foot utility buttons */}
        </div>
      </div>
      {selectedIndex === 1 && favariteList()}
      {selectedIndex === 2 && planList()}
      {selectedIndex === 3 && sns()}
      {selectedIndex === 4 && websiteList()}
    </div>
  );
};

export default App;
