import React, { FC, useState } from 'react';

import { createPlanToApi } from '../../slices/planSlice';
import { reset as planSpotReset } from '../../slices/planSpotSlice';
import { reset as transitReset } from '../../slices/transitSlice';
import { setDay } from '../../slices/activeDaySlice';
import { setIsInitializeMapCenter } from '../../slices/mapSlice';
import { useAppDispatch } from '../../export/hook';
import IconPlus from '../icons/iconPlusComponent';
import { setIndex } from '../../slices/planMenuSlice';
import I18n from '../../i18n/i18n';
import { FormattedMessage, IntlProvider } from 'react-intl';

const CreatePlanButton: FC = () => {
  const dispatch = useAppDispatch();
  const [hovering, setHovering] = useState(false);

  const handleMouseEnter = () => {
    setHovering(true);
  };

  const handleMouseLeave = () => {
    setHovering(false);
  };

  const handleClick = () => {
    dispatch(planSpotReset());
    dispatch(transitReset());
    dispatch(setDay(1));
    dispatch(setIsInitializeMapCenter(true));
    dispatch(createPlanToApi());
  };

  const createLinkElement = () => {
    return (
      <a
        className="jc-create-plan-button__tooltip-emphasis-text"
        onClick={() => {
          dispatch(setIndex(2));
        }}
      >
        {I18n.t('japan_concierge.active_plans.show.my_plan')}
      </a>
    );
  };

  const stringToHTML = () => {
    return (
      <IntlProvider locale="en">
        <FormattedMessage
          id="successfullyAddedPlan"
          defaultMessage={I18n.t('japan_concierge.active_plans.show.saved_to_plans')}
          values={{ link: createLinkElement() }}
        />
      </IntlProvider>
    );
  };

  return (
    <div className="jc-create-plan-button">
      <div
        className={`jc-create-plan-button__tooltip-container ${
          hovering && 'jc-create-plan-button__tooltip-container--open'
        }`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="jc-create-plan-button__tooltip">{stringToHTML()}</div>
      </div>

      <button
        data-jc-role="foot-plan-new-btn"
        className="foot-utility-btn --color_add_plan"
        type="button"
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <IconPlus additionalClassNames={['btn__icon']} />
        {I18n.t('japan_concierge.active_plans.show.create_new_plan')}
      </button>
    </div>
  );
};

export default CreatePlanButton;
