import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  type EntityState,
  type PayloadAction,
} from '@reduxjs/toolkit';

import { MapSpot, AppState, MarkerType, PlanSpot, ActiveDayState, MapSpotEntityState } from '../types';
import appApi from '../utils/api';

const mapSpotAdapter = createEntityAdapter<MapSpot>({
  // idフィールドに`id`キー以外のものを使いたい場合は下記のように明示的に指定する
  selectId: (spot: MapSpot) => spot.id,
});
const initialState: MapSpotEntityState = mapSpotAdapter.getInitialState({
  isLoading: false,
  selectedId: 0,
});

//-------------------------------------------------------------
// 非同期処理
//-------------------------------------------------------------

// お気に入りスポット情報を取得する処理
export const getFavoriteMapSpotToApi = createAsyncThunk<MapSpot[], void>(
  'mapSpot/favoriteSpotIndex',
  async (arg: void, thunkAPI) => {
    const response = await appApi.get(`${(thunkAPI.getState() as AppState).config.rootPath}/map_spots`, {
      params: { q: { favorite: true } },
    });
    return response.data;
  }
);

// スタートスポット情報を取得する処理
export const getStartSpotToApi = createAsyncThunk<MapSpot[], void>(
  'mapSpot/startSpotIndex',
  async (arg: void, thunkAPI) => {
    const response = await appApi.get(`${(thunkAPI.getState() as AppState).config.rootPath}/map_spots`, {
      params: { q: { start_spot: true } },
    });
    return response.data;
  }
);

// カテゴリースポット情報を取得する処理
export const getSpotToApi = createAsyncThunk<MapSpot[], number>('mapSpot/spotIndex', async (arg: number, thunkAPI) => {
  // 地図の中心座標を取得し、小数点6桁四捨五入（railsに合わせてます)
  const lat = Math.round(((thunkAPI.getState() as AppState).map.map?.getCenter()?.lat() || 1) * 100000) / 100000;
  const lng = Math.round(((thunkAPI.getState() as AppState).map.map?.getCenter()?.lng() || 1) * 100000) / 100000;

  const response = await appApi.get(`${(thunkAPI.getState() as AppState).config.rootPath}/map_spots`, {
    params: { q: { categories: [arg], lat, lng } },
  });
  return response.data;
});

// イベント季節のスポット情報を取得する処理
export const getSeasonSpotToApi = createAsyncThunk<MapSpot[], number>(
  'mapSpot/seasonSpotIndex',
  async (id: number, thunkAPI) => {
    // 地図の中心座標を取得し、小数点6桁四捨五入（railsに合わせてます)
    const lat = Math.round(((thunkAPI.getState() as AppState).map.map?.getCenter()?.lat() || 1) * 100000) / 100000;
    const lng = Math.round(((thunkAPI.getState() as AppState).map.map?.getCenter()?.lng() || 1) * 100000) / 100000;
    const response = await appApi.get(`${(thunkAPI.getState() as AppState).config.rootPath}/map_spots`, {
      params: { q: { season: id, lat, lng } },
    });
    return response.data;
  }
);

// イベント月のスポット情報を取得する処理
export const getMonthSpotToApi = createAsyncThunk<MapSpot[], number>(
  'mapSpot/seasonSpotIndex',
  async (id: number, thunkAPI) => {
    // 地図の中心座標を取得し、小数点6桁四捨五入（railsに合わせてます)
    const lat = Math.round(((thunkAPI.getState() as AppState).map.map?.getCenter()?.lat() || 1) * 100000) / 100000;
    const lng = Math.round(((thunkAPI.getState() as AppState).map.map?.getCenter()?.lng() || 1) * 100000) / 100000;
    const response = await appApi.get(`${(thunkAPI.getState() as AppState).config.rootPath}/map_spots`, {
      params: { q: { month: id, lat, lng } },
    });
    return response.data;
  }
);

//-------------------------------------------------------------
// Selector
//-------------------------------------------------------------
// 全件取得用
export const mapSpotSelectors = mapSpotAdapter.getSelectors<AppState>((state: AppState) => state.mapSpot);

// Marker表示用 marker/infowindowに必要な情報を抽出し、interface MarkerTypeとして配列に設定・返却する
export const mapSpotMarkerSelector = createSelector(
  [(state: AppState) => state.mapSpot, (state: AppState) => state.planSpot, (state: AppState) => state.activeDay],
  (
    state: MapSpotEntityState,
    statePlanSpot: EntityState<PlanSpot>,
    stateActiveDay: ActiveDayState
  ): (MarkerType | undefined)[] =>
    Object.values<MapSpot | undefined>(state.entities).map((mapSpot) => {
      // planSpotに含まれているスポットの場合は除外する
      const isPlanSpot = Object.values<PlanSpot | undefined>(statePlanSpot.entities).find((planSpot) => {
        if (planSpot && planSpot.spot && mapSpot) {
          return planSpot.spot.id === mapSpot.id && stateActiveDay.day === planSpot.day;
        }
        return false;
      });

      if (isPlanSpot) {
        return undefined;
      }

      if (mapSpot) {
        return {
          day: stateActiveDay.day,
          name: mapSpot.spot.name,
          lat: mapSpot.spot.lat,
          lng: mapSpot.spot.lng,
          image: mapSpot.markerImage,
          spot: mapSpot,
        };
      }
      return undefined;
    })
);

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const mapSpotSlice = createSlice({
  name: 'mapSpot',
  initialState,
  reducers: {
    reset: (state: MapSpotEntityState) => {
      Object.assign(state, initialState);
    },
    setMapSpot: (state: MapSpotEntityState, action: PayloadAction<MapSpot[]>) => {
      if (action.payload.length > 0) {
        mapSpotAdapter.addMany(state, action);
      }
    },
    removeMapSpot: (state: MapSpotEntityState) => {
      mapSpotAdapter.removeAll(state);
    },
    setLoading: (state: MapSpotEntityState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSelectedId: (state: MapSpotEntityState, action: PayloadAction<number>) => {
      state.selectedId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFavoriteMapSpotToApi.pending, (state: MapSpotEntityState) => {
      state.isLoading = true;
    });
    builder.addCase(getFavoriteMapSpotToApi.rejected, (state: MapSpotEntityState) => {
      state.isLoading = false;
    });
    builder.addCase(getFavoriteMapSpotToApi.fulfilled, (state: MapSpotEntityState, action) => {
      mapSpotSlice.caseReducers.setMapSpot(state, action);
      state.isLoading = false;
    });
    builder.addCase(getStartSpotToApi.pending, (state: MapSpotEntityState) => {
      state.isLoading = true;
    });
    builder.addCase(getStartSpotToApi.rejected, (state: MapSpotEntityState) => {
      state.isLoading = false;
    });
    builder.addCase(getStartSpotToApi.fulfilled, (state: MapSpotEntityState, action) => {
      mapSpotSlice.caseReducers.setMapSpot(state, action);
      state.isLoading = false;
    });
    builder.addCase(getSpotToApi.pending, (state: MapSpotEntityState) => {
      state.isLoading = true;
    });
    builder.addCase(getSpotToApi.rejected, (state: MapSpotEntityState, action) => {
      if (action.payload === 'interruption') {
        // 中断されたタイミングにより、既に非同期処理が実施されて結果をセットしてしまっている可能性がある為、検索結果を一旦削除する
        mapSpotSlice.caseReducers.removeMapSpot(state);
      }
      state.isLoading = false;
    });
    builder.addCase(getSpotToApi.fulfilled, (state: MapSpotEntityState, action) => {
      mapSpotSlice.caseReducers.setMapSpot(state, action);
      state.isLoading = false;
    });
    builder.addCase(getSeasonSpotToApi.fulfilled, (state, action) => {
      mapSpotSlice.caseReducers.setMapSpot(state, action);
    });
  },
});

export const { reset, setMapSpot, setLoading, setSelectedId } = mapSpotSlice.actions;

export default mapSpotSlice.reducer;
