import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { deepEqual } from 'fast-equals';

import IconTrash from '../icons/iconTrashComponent';
import {
  deletePlanSpot,
  deletePlanSpotToApi,
  getPosition,
  planSpotInMaxPositionSelector,
  planSpotInMinPositionSelector,
} from '../../slices/planSpotSlice';
import { AppState, MarkerType } from '../../types';
import { deleteTransit } from '../../slices/transitSlice';
import { useAppDispatch } from '../../active_plan/hook';
import { setContentId, toggle } from '../../slices/modalSlice';
import { getSpotToApi, setIsDisplayRemoveButton } from '../../slices/modalSpotSlice';

type MapPointProps = {
  planSpotMarker: MarkerType;
};

const PlanSpotMarker = (props: MapPointProps) => {
  const { planSpotMarker } = props;
  // DAYに紐づくplanSpotの最小、最大positionを算出
  const maxPosition = useSelector((state: AppState) => planSpotInMaxPositionSelector(state), deepEqual);
  const minPosition = useSelector((state: AppState) => planSpotInMinPositionSelector(state), deepEqual);
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const handleClickDelete = () => {
    if (planSpotMarker) {
      dispatch(deletePlanSpotToApi(planSpotMarker.planSpotId || 0));
      dispatch(deleteTransit(planSpotMarker.planSpotId || 0));
      dispatch(deletePlanSpot({ id: planSpotMarker.planSpotId || 0, position: planSpotMarker.position || 0 }));
    }
  };

  // imageに関しては、jc-apiから返却されたspotとローカルspotのインスタンスの違いにより画像のURL取得可否がある為、サーバ側にてimgタグとして返却される
  // ため、dangerouslySetInnerHTMLにてimgを表示できるように設定。
  return (
    <div
      ref={containerRef}
      onClick={() => {
        if (planSpotMarker && planSpotMarker.spot) {
          dispatch(setContentId('spot'));
          dispatch(toggle());
          dispatch(setIsDisplayRemoveButton(true));
          dispatch(getSpotToApi(planSpotMarker.spot.id));
          const planSpot = document.getElementById(`${planSpotMarker.spot.id}`);
          planSpot?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
          });
        }
      }}
    >
      <div className="jc-map-marker">
        <span className="jc-map-marker__index-wrap jc-u-d-table jc-u-text-center">
          <span className="jc-map-marker__index jc-u-d-table-cell jc-u-text-middle">
            {getPosition(planSpotMarker.position || 0, maxPosition, minPosition)}
          </span>
        </span>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: planSpotMarker.image }} className="jc-map-marker__img" />
        {/* ゴミ箱アイコン */}
        <button
          className="jc-btn-style-reset jc-map-marker__action-button jc-map-marker__action-button--delete"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            handleClickDelete();
            e.stopPropagation();
          }}
        >
          <IconTrash additionalClassNames={['jc-map-marker__action-button-icon']} />
        </button>
        {/* スポット名 */}
        {planSpotMarker && <div className="jc-map-marker__spot-name">{planSpotMarker.name || ''}</div>}
      </div>
    </div>
  );
};

export default React.memo(PlanSpotMarker);
