import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GoogleMap, OverlayView } from '@react-google-maps/api';

import { ExportAppState, ExportPlanDaySpot } from '../../types/export_type';
import PlanDaySpotMarker from './planDaySpotMarkerComponent';

interface MapProps extends google.maps.MapOptions {
  exportPlanDaySpots: ExportPlanDaySpot[];
  // dayは1から始まるため、呼び出し元で-1しておく
  day: number;
  zoom: number;
  center: { lat: number; lng: number };
}

const Map: FC<MapProps> = (mapProps: MapProps) => {
  const { zoom, center, day, exportPlanDaySpots } = mapProps;
  const isShow = useSelector((state: ExportAppState) => state.export.isShowMap)[day];
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [isFitBound, setIsFitBound] = useState<boolean>(false);

  useEffect(() => {
    if (map && !isFitBound) {
      map.fitBounds(
        exportPlanDaySpots.reduce(
          (bounds: google.maps.LatLngBounds, exportPlanDaySpot: ExportPlanDaySpot) =>
            bounds.extend(new google.maps.LatLng(exportPlanDaySpot.spot.lat, exportPlanDaySpot.spot.lng)),
          new google.maps.LatLngBounds()
        )
      );
      setIsFitBound(true);
    }
  }, [map]);

  const onLoad = useCallback((mapInstance: google.maps.Map) => {
    setMap(mapInstance);
  }, []);

  return (
    <GoogleMap
      mapContainerClassName={isShow ? 'jc-export-course-map__view' : 'jc-export-course-map__view jc-u-d-none'}
      center={center}
      zoom={zoom}
      options={{
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        gestureHandling: 'greedy',
        clickableIcons: false,
      }}
      onLoad={onLoad}
    >
      {(exportPlanDaySpots || []).map((exportPlanDaySpot: ExportPlanDaySpot) => (
        <OverlayView
          key={Math.random()}
          position={new google.maps.LatLng(exportPlanDaySpot.spot.lat, exportPlanDaySpot.spot.lng)}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <PlanDaySpotMarker position={exportPlanDaySpot.position + 1} />
        </OverlayView>
      ))}
    </GoogleMap>
  );
};

export default Map;
