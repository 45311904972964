import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const IconDoubleDown: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="double-down"
    data-testid="double-down"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 19.923"
  >
    <path
      fill="currentColor"
      transform="translate(0.002 -2.66)"
      d="M7.148,12.635l-6.8-6.8a1.2,1.2,0,0,1,0-1.694l1.13-1.13a1.2,1.2,0,0,1,1.694,0L7.993,7.831l4.818-4.818a1.2,1.2,0,0,1,1.694,0l1.14,1.125a1.2,1.2,0,0,1,0,1.694l-6.8,6.8a1.2,1.2,0,0,1-1.7,0Zm1.7,9.6,6.8-6.8a1.2,1.2,0,0,0,0-1.694l-1.13-1.13a1.2,1.2,0,0,0-1.694,0L8,17.423,3.179,12.6a1.2,1.2,0,0,0-1.694,0L.35,13.734a1.2,1.2,0,0,0,0,1.694l6.8,6.8a1.2,1.2,0,0,0,1.7.005Z"
    />
  </svg>
);

export default IconDoubleDown;
