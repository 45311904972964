import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const IconTrain: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="train"
    data-testid="train"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M415.7,512H96.3V496.52L160.18,448c-20.78,0-128-29.95-127.76-78.21.09-20.93.09-270,0-291.58C46.31-3,125.39,1.39,256,0,387.06,2,466.42-4.12,479.59,78.21c-.1,21.26-.1,269.53,0,291.58.18,46-107,78.21-127.76,78.21l63.88,48.52Zm-65-224c-7.76,0-23.64,5-22.81,16.21,0,4.74,0,45.35,0,49.82,4.37,21.49,42.18,11.9,57,14,7.77,0,23.36-2.74,22.82-14,0-3.3,0-45.35,0-49.82C403.73,280.67,366.46,289.76,350.68,288ZM127.1,288c-7.76,0-23.41,5.73-22.81,16.21,0,4.69,0,45.35,0,49.82,3.46,21.55,42.55,11.87,57,14,7.77,0,24.42-3.16,22.82-14,0-4.19,0-45.78,0-49.82C181.17,280.54,142.55,289.84,127.1,288Zm160.84-59.93c2.57,29.67,33.82,27.06,79.85,27.93,41.64,0,49.24-1.26,56.2-3.65,7.17-2.45,23.74-6.27,23.66-24.28,0-7.17,0-97.14,0-104.14C445.07,94.26,413.83,96.87,367.8,96c-41.64,0-49.25,1.26-56.21,3.64-7.16,2.46-23.73,6-23.65,24.29,0,7.17,0,97.14,0,104.14Zm-223.58,0c2.48,29.7,33.84,27.05,79.85,27.93,41.64,0,49.24-1.26,56.2-3.65,7.16-2.45,23.73-5,23.65-24.28-.05-11.06,0-94.49,0-104.14-3-29.6-33.68-27.1-79.85-27.93C102.57,96,95,97.26,88,99.65c-7.17,2.45-23.74,5.68-23.66,24.28,0,7,0,94.49,0,104.14Z"
    />
  </svg>
);

export default IconTrain;
