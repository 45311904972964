import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { type EntityId } from '@reduxjs/toolkit';
import { deepEqual } from 'fast-equals';

import { planSpotSelectors, planSpotInDaySelector } from '../../slices/planSpotSlice';

import Spot from './planSpotComponent';
import StartTime from './startTimeComponent';
import Transit from './transitComponent';
import { AppState } from '../../types';

const PlanSpotApp: FC = () => {
  // idsの場合、追加・削除以外変更されることが無いため1planSpotの内部情報が更新されてもここには影響受けない為再レンダリングが発生せず、
  // 画面のちらつきがおきない。
  const planSpotIds = useSelector((state: AppState) => planSpotSelectors.selectIds(state));
  // DAYに紐づくplanSpot数を取得。class=jc-plan-spotsで余分なpaddingが表示されないようにする為
  const dayPlanSpotIds = useSelector((state: AppState) => planSpotInDaySelector(state), deepEqual);

  if (planSpotIds.length === 0 || dayPlanSpotIds.length === 0) {
    return <div data-testid="no_data" />;
  }

  return (
    <div className="jc-plan-spots">
      <div data-controller="plan-spots">
        {(planSpotIds || []).map((id: EntityId, index: number) => (
          // Componentを複数配置し、ループする場合一意となるkeyを設定する必要がある
          <div key={Math.random()} className="jc-plan-spot">
            {/* 移動情報 */}
            {index > 0 && (
              <div className="jc-plan-spot__transit-part">
                <Transit fromPlanSpotId={planSpotIds[index - 1]} toPlanSpotId={id} />
              </div>
            )}
            {/* スポット情報 */}
            <div className="jc-plan-spot__content-part">
              {/* START 時刻 各DAYの先頭のみに表示 */}
              {index === 0 && <StartTime />}
              <Spot planSpotId={id} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(PlanSpotApp);
