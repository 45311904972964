import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { ModalStartState } from '../types';

import { toggle } from './modalSlice';
import { updateStartTime } from './planDaySlice';

const initialState: ModalStartState = {
  id: 0,
  inputHour: 0,
  inputMinute: 0,
};

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const modalStartSlice = createSlice({
  name: 'modalStart',
  initialState,
  reducers: {
    reset: (state: ModalStartState) => {
      Object.assign(state, initialState);
    },
    setTime: (
      state: ModalStartState,
      action: PayloadAction<{ id: number; startTimeHour: number; startTimeMinute: number }>
    ) => {
      const { id, startTimeHour, startTimeMinute } = action.payload;
      state.id = id;
      state.inputHour = startTimeHour;
      state.inputMinute = startTimeMinute;
    },
    changeInputHour: (state: ModalStartState, action: PayloadAction<string>) => {
      const inputVal = Number(action.payload);
      if (inputVal < 0 || inputVal >= 24) return;
      state.inputHour = inputVal;
    },
    changeInputMinute: (state: ModalStartState, action: PayloadAction<string>) => {
      const inputVal = Number(action.payload);
      if (inputVal < 0 || inputVal > 59) return;
      state.inputMinute = inputVal;
    },
  },
  extraReducers: (builder) => {
    // Modalが閉じられたらplanSpotを初期化
    builder.addMatcher(toggle.match, (state: ModalStartState) => {
      modalStartSlice.caseReducers.reset(state);
    });
    builder.addMatcher(updateStartTime.match, (state: ModalStartState) => {
      modalStartSlice.caseReducers.reset(state);
    });
  },
});

export const { reset, setTime, changeInputHour, changeInputMinute } = modalStartSlice.actions;
export default modalStartSlice.reducer;
