import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppState, CustomSpotState, FavoriteSpot, PlanSpot } from '../types';
import appApi from '../utils/api';

const initialState: CustomSpotState = {
  isShow: false,
  isNameError: false,
  isStayError: false,
  name: '',
  stay: 30,
  lat: 0,
  lng: 0,
};

//-------------------------------------------------------------
// 非同期処理
//-------------------------------------------------------------
// オリジナルスポット登録処理
export const customSpotCreateToApi = createAsyncThunk<
  { planSpots: PlanSpot[]; favoriteSpots: FavoriteSpot[] },
  CustomSpotState
>('customSpot/create', async (arg: CustomSpotState, thunkAPI) => {
  const { name, lat, lng, stay } = arg;
  const response = await appApi.post(`${(thunkAPI.getState() as AppState).config.rootPath}/custom_spots`, {
    japan_concierge_custom_spot_form: {
      name,
      lat,
      lng,
      stay,
    },
  });
  return response.data;
});

// オリジナルスポット名取得処理
export const getCustomSpotNameToApi = createAsyncThunk<string, void>('customSpot/new', async (arg: void, thunkAPI) => {
  const response = await appApi.get(`${(thunkAPI.getState() as AppState).config.rootPath}/custom_spots/new`);
  return response.data;
});

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const customSpotSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    reset: (state: CustomSpotState) => {
      Object.assign(state, initialState);
    },
    setIsShow: (state: CustomSpotState, action: PayloadAction<boolean>) => {
      state.isShow = action.payload;
    },
    setName: (state: CustomSpotState, action: PayloadAction<string>) => {
      state.name = action.payload;
      state.isNameError = state.name.trim() === '';
    },
    setStay: (state: CustomSpotState, action: PayloadAction<number>) => {
      state.stay = action.payload;
      state.isStayError = state.stay <= 0;
    },
    setIsNameError: (state: CustomSpotState, action: PayloadAction<boolean>) => {
      state.isNameError = action.payload;
    },
    setIsStayError: (state: CustomSpotState, action: PayloadAction<boolean>) => {
      state.isStayError = action.payload;
    },
    setLatLng: (state: CustomSpotState, action: PayloadAction<{ lat: number; lng: number }>) => {
      state.lat = action.payload.lat;
      state.lng = action.payload.lng;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(customSpotCreateToApi.fulfilled, (state: CustomSpotState) => {
      customSpotSlice.caseReducers.reset(state);
    });
    builder.addCase(getCustomSpotNameToApi.fulfilled, (state: CustomSpotState, action) => {
      state.name = action.payload;
    });
  },
});

export const { reset, setIsShow, setName, setStay, setLatLng, setIsNameError, setIsStayError } =
  customSpotSlice.actions;
export default customSpotSlice.reducer;
