import React from 'react';

import IconDoubleDown from '../icons/iconDoubleDownComponent';

const ScrollButton = (): JSX.Element => {
  return (
    <button
      className="jc-map-slider__toggle-btn"
      type="button"
      aria-label="ScrollButton"
      onClick={() => {
        document.getElementById('planRoute')?.scrollIntoView({ behavior: "smooth" });
      }}
    >
      <IconDoubleDown additionalClassNames={['jc-map-slider__toggle-btn-icon']} />
    </button>
  );
};

export default ScrollButton;
