import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { AppState, ModalStayState, PlanSpot } from '../types';

import { toggle } from './modalSlice';
import { updateStay } from './planSpotSlice';
import appApi from '../utils/api';

const initialState: ModalStayState = {
  planSpot: undefined,
  isChangeRecommended: false,
};

//-------------------------------------------------------------
// 非同期処理
//-------------------------------------------------------------
// 滞在時間更新処理
export const updateStayToApi = createAsyncThunk<void, PlanSpot>(
  'modalStay/updateStay',
  async (arg: PlanSpot, thunkAPI) => {
    const { planId, id, stay, stayMemo } = arg;
    const { isChangeRecommended } = (thunkAPI.getState() as AppState).modalStay;
    await appApi.patch(`${(thunkAPI.getState() as AppState).config.rootPath}/plans/${planId}/plan_spots/${id}`, {
      custom_stay_value: isChangeRecommended ? null : stay,
      custom_stay_memo: stayMemo,
    });
  }
);

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const modalStaySlice = createSlice({
  name: 'modalStay',
  initialState,
  reducers: {
    setPlanSpot: (state: ModalStayState, action: PayloadAction<PlanSpot | undefined>) => {
      state.planSpot = action.payload;
    },
    setStayTime: (state: ModalStayState, action: PayloadAction<string>) => {
      if (state.planSpot) {
        state.planSpot.stay = Number(action.payload);
      }
      state.isChangeRecommended = false;
    },
    changeToRecommendedValue: (state: ModalStayState) => {
      if (state.planSpot) {
        state.planSpot.stay = state.planSpot.spot?.stay || 0;
        state.isChangeRecommended = true;
      }
    },
    changeMemo: (state: ModalStayState, action: PayloadAction<string>) => {
      if (state.planSpot) {
        state.planSpot.stayMemo = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    // Modalが閉じられたらplanSpotを初期化
    builder.addMatcher(toggle.match, (state: ModalStayState) => {
      state.planSpot = undefined;
    });
    builder.addMatcher(updateStay.match, (state: ModalStayState) => {
      state.planSpot = undefined;
    });
  },
});

export const { setPlanSpot, setStayTime, changeToRecommendedValue, changeMemo } = modalStaySlice.actions;
export default modalStaySlice.reducer;
