import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { SnsState } from '../types';
import { toggle as modalToggle } from './modalSlice';

const initialState: SnsState = {
  isShown: false,
  parentUrl: '',
  isDataRemote: false,
};

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const snsSlice = createSlice({
  name: 'sns',
  initialState,
  reducers: {
    reset: (state: SnsState) => {
      Object.assign(state, initialState);
    },
    toggle: (state: SnsState) => {
      state.isShown = !state.isShown;
    },
    setParentUrl: (state: SnsState, action: PayloadAction<string>) => {
      if (action.payload) {
        state.parentUrl = action.payload;
      }
    },
    setDataRemote: (state: SnsState, action: PayloadAction<boolean>) => {
      state.isDataRemote = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Modalが閉じられたらModalFavoriteSpotStateを初期化
    builder.addMatcher(modalToggle.match, (state: SnsState) => {
      state.isShown = false;
    });
  },
});

export const { reset, toggle, setParentUrl, setDataRemote } = snsSlice.actions;

export default snsSlice.reducer;
