import React, { useRef } from 'react';

type MapPointProps = {
  position: number;
};

const PlanDaySpotMarker = (props: MapPointProps) => {
  const { position } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    // FEチームへ
    // styleは確認用に設定しているためのものですので、適宜適切な値 or classNameの指定をお願いします。
    // 対応完了後はこのコメントの削除もお願いします
    <div ref={containerRef} className="jc-export-course-map-spot__position">
      <div style={{ fontSize: 20, textAlign: 'center' }}>{position}</div>
    </div>
  );
};

export default React.memo(PlanDaySpotMarker);
