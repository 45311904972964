import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { activePlanDaySelector } from '../../slices/planDaySlice';
import { toggle, setContentId } from '../../slices/modalSlice';
import { setTime } from '../../slices/modalStartSlice';
import type { AppState, PlanDay } from '../../types';

import I18n from '../../i18n/i18n';
import { useAppDispatch } from '../../active_plan/hook';
import { formatTime } from '../../utils/datetime';

const startTime: FC = () => {
  const planDay: PlanDay | undefined = useSelector((state: AppState) => activePlanDaySelector(state));
  const dispatch = useAppDispatch();

  if (!planDay) {
    return <div data-testid="startTime_nodata" />;
  }

  // 0も設定できるようにしています
  const startTime = new Date();
  if (planDay?.startTimeHour !== undefined) {
    startTime.setHours(planDay.startTimeHour || 9, planDay.startTimeMinute || 0);
  }

  return (
    <div className="jc-plan-spot__timetable">
      <div className="jc-plan-spot__timetable-line" />
      <div data-testid="type_arrival" className={'jc-plan-spot__timetable-time'}>
        START {formatTime(startTime)}
      </div>
      <button
        className="jc-plan-spot__timetable-start-btn"
        type="button"
        aria-label="StartTimeEdit"
        onClick={() => {
          dispatch(setContentId('start'));
          dispatch(toggle());
          dispatch(setTime(planDay));
        }}
      >
        {I18n.t('japan_concierge.active_plans.show.change_start_time')}
      </button>
    </div>
  );
};

export default startTime;
