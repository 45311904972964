import { createAsyncThunk, createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { Export, ExportAppState, ExportPlanDay, ExportPlanDaySpot } from '../types/export_type';
import appApi from '../utils/api';

const initialState: Export = {
  id: '',
  exportPlan: undefined,
  isShowMap: new Array(0),
};

//-------------------------------------------------------------
// 非同期処理
//-------------------------------------------------------------
// active_planの情報を取得する処理
export const getExportInfoToApi = createAsyncThunk<{ export: Export }, void>(
  'export/show',
  async (arg: void, thunkAPI) => {
    const { id } = (thunkAPI.getState() as ExportAppState).export;
    const response = await appApi.get(`${(thunkAPI.getState() as ExportAppState).config.rootPath}/exports/${id}`);
    return response.data;
  }
);

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const exportSlice = createSlice({
  name: 'export',
  initialState,
  reducers: {
    reset: (state: Export) => {
      Object.assign(state, initialState);
    },
    setId: (state: Export, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setExport: (state: Export, action: PayloadAction<{ export: Export }>) => {
      state.exportPlan = action.payload.export.exportPlan;
      // Day数分初期化しておく
      state.isShowMap = new Array(action.payload.export.exportPlan?.exportPlanDays?.length || 0).fill(false);
    },
    setIsShowMap: (state: Export, action: PayloadAction<number>) => {
      state.isShowMap[action.payload] = !state.isShowMap[action.payload];
    },
  },
  extraReducers: (builder) => {
    // 画面表示時/リロード時にサーバから取得時に呼ばれる。
    builder.addCase(getExportInfoToApi.fulfilled, (state, action) => {
      exportSlice.caseReducers.setExport(state, action);
    });
  },
});

type ReturnSelectorType<S> = (state: ExportAppState) => S;
// 指定したPlanDayのPlanSpotの中から指定されたpositionより前のPlanSpotの滞在時間と移動時間の合計値を算出する
export const totalStayTransitTimeSelector = (day: number, position: number): ReturnSelectorType<number> =>
  createSelector([(state: ExportAppState) => state.export], (_export: Export) => {
    let totalStayTime = 0;
    const exportPlanDay = _export.exportPlan?.exportPlanDays?.filter((planDay: ExportPlanDay) => planDay.day === day);
    if (exportPlanDay && exportPlanDay.length > 0) {
      // 指定されたpositionより前の滞在時間を抽出するため、自分を含まない
      const planDaySpotsNoSelf = exportPlanDay[0]?.exportPlanDaySpots?.filter(
        (planDaySpot: ExportPlanDaySpot) => planDaySpot.position < position
      );
      planDaySpotsNoSelf?.forEach((planDaySpot: ExportPlanDaySpot) => {
        totalStayTime += planDaySpot.stay;
      });

      // 指定されたpositionより前の移動時間を抽出するため、自分を含む
      const exportPlanDaySpots = exportPlanDay[0]?.exportPlanDaySpots?.filter(
        (planDaySpot: ExportPlanDaySpot) => planDaySpot.position <= position && planDaySpot.position > 0
      );

      exportPlanDaySpots?.forEach((planDaySpot: ExportPlanDaySpot) => {
        totalStayTime += planDaySpot.transitTime;
      });
    }
    return totalStayTime;
  });

export const { reset, setId, setIsShowMap } = exportSlice.actions;
export default exportSlice.reducer;
