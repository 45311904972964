import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { MapMenuCategoryState, Category, AppState, Season } from '../types';
import appApi from '../utils/api';

const initialState: MapMenuCategoryState = {
  categories: [],
  selectedIndex: 0, // 0:未選択状態 1:お気にり 2:スタート地点 3〜:カテゴリ
};

//-------------------------------------------------------------
// 非同期処理
//-------------------------------------------------------------

// カテゴリー情報を取得する処理
export const getMapMenuListToApi = createAsyncThunk<
  { categories: Category[]; isStartSpot: boolean; seasons: Season[] },
  void
>('mapMenuCategory/index', async (arg: void, thunkAPI) => {
  const response = await appApi.get(`${(thunkAPI.getState() as AppState).config.rootPath}/map_menus`);
  return response.data;
});

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const mapMenuCategorySlice = createSlice({
  name: 'mapMenuCategory',
  initialState,
  reducers: {
    reset: (state: MapMenuCategoryState) => {
      Object.assign(state, initialState);
    },
    setIndex: (state: MapMenuCategoryState, action: PayloadAction<number>) => {
      // 既に選択状態の場合は解除する必要がある(現行仕様)
      state.selectedIndex = state.selectedIndex === action.payload ? 0 : action.payload;
    },
    setCategories: (state: MapMenuCategoryState, action: PayloadAction<{ categories: Category[] }>) => {
      state.categories = action.payload.categories;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMapMenuListToApi.fulfilled, (state, action) => {
      mapMenuCategorySlice.caseReducers.setCategories(state, action);
    });
  },
});

export const { reset, setIndex, setCategories } = mapMenuCategorySlice.actions;

export default mapMenuCategorySlice.reducer;
