import React, { FC } from 'react';
import { ExportPlanDaySpot } from '../../types/export_type';

import IconCar from '../icons/iconCarComponent';
import IconTrain from '../icons/iconTrainComponent';
import IconWalking from '../icons/iconWalkingComponent';
import IconBicycle from "../icons/iconBicycleComponent";
import I18n from '../../i18n/i18n';

interface Props {
  exportPlanDaySpot: ExportPlanDaySpot;
}

const Transit: FC<Props> = (props: Props) => {
  const { exportPlanDaySpot } = props;

  if (!exportPlanDaySpot) {
    return <div data-testid="transit_no_data" />;
  }

  const formatHour = (hour: number): string => {
    if (!hour) return '';
    return `${hour}${I18n.t('japan_concierge.active_plans.show.hour_short')}`;
  };

  const formatMinute = (hour: number, minute: number): string => {
    if (hour > 0 && minute === 0) return '';
    if (hour === 0 && minute === 0) return `0${I18n.t('japan_concierge.active_plans.show.minute_short')}`;
    return `${hour > 0 ? ` ${minute}` : minute}${I18n.t('japan_concierge.active_plans.show.minute_short')}`;
  };

  const format = (time: number): string => {
    if (!time) return 'ー';

    const hour = Math.floor(time / 60);
    const minute = time % 60;

    return `${formatHour(hour)}${formatMinute(hour, minute)}`;
  };

  const formatTransitTime = format(exportPlanDaySpot.transitTime);

  return (
    <div className="jc-export-course-item-interval">
      <div className="jc-export-course-item-interval__inner">
        {/* 移動手段 */}
        <div className="jc-export-course-item-interval__icon">
          {exportPlanDaySpot.mode === 'train' && <IconTrain />}
          {exportPlanDaySpot.mode === 'car' && <IconCar />}
          {exportPlanDaySpot.mode === 'walk' && <IconWalking />}
          {exportPlanDaySpot.mode === 'bicycle' && <IconBicycle />}
        </div>

        {/* 移動時間 */}
        <a href={exportPlanDaySpot.directionUrl} target="_blank" rel="noreferrer" className="jc-export-plan-spot__direction-link">
          <div className="jc-export-course-item-interval__time">{formatTransitTime}</div>
        </a>
      </div>

      {/* メモ */}
      {exportPlanDaySpot.transitMemo && (
        <div className="jc-export-course-item-interval__memo jc-u-mt-2">{exportPlanDaySpot.transitMemo}</div>
      )}
    </div>
  );
};

export default Transit;
