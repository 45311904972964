import React from 'react';
import { useSelector } from 'react-redux';

import ScrollButton from './scrollButtonComponent';
import SpotSlider from './spotSliderComponent';
import { AppState } from '../../types';

const SpotSlideTile = (): JSX.Element => {
  const mapMenuSelectedIndex = useSelector((state: AppState) => state.mapMenuCategory.selectedIndex);
  const selectedSeasonIndex = useSelector((state: AppState) => state.mapMenuSeason.selectedIndex);
  const selectedMonth = useSelector((state: AppState) => state.mapMenuMonth.selectedMonth);

  // カテゴリー検索 お気に入り登録 スタート地点検索、イベント検索にて検索未実施の場合のみ何も出力しない
  if (mapMenuSelectedIndex === 0 && selectedSeasonIndex === 0 && selectedMonth === 0) {
    return (
      <div>
        <ScrollButton />
      </div>
    );
  }

  return (
    <div className="jc-map-slider">
      <ScrollButton />
      <SpotSlider />
    </div>
  );
};

export default SpotSlideTile;
