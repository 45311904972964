import React, { FC, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { IntlProvider, FormattedMessage } from 'react-intl';

import { FavoriteSpot } from '../../types';
import { deleteFavoriteSpotToApi, deleteFavoriteSpot, setIndex } from '../../slices/planMenuSlice';
import { getSpotToApi } from '../../slices/modalSpotSlice';
import { toggle } from '../../slices/modalSlice';
import { useAppDispatch } from '../../active_plan/hook';
import { createPlanSpotToApi } from '../../slices/planSpotSlice';
import IconPlus from '../icons/iconPlusComponent';
import I18n from '../../i18n/i18n';
import IconCircleDelete from '../icons/iconCircleDeleteComponent';

type Props = {
  favoriteSpot: FavoriteSpot;
};

const FavoriteSpotFC: FC<Props> = (props: Props) => {
  const { favoriteSpot } = props;
  const dispatch = useAppDispatch();
  const [isAddToMyPlan, setisAddToMyPlan] = useState<boolean>(false);

  const closeClick = () => {
    dispatch(deleteFavoriteSpot(favoriteSpot.id));
    dispatch(deleteFavoriteSpotToApi(favoriteSpot.spotId));
    const event = new CustomEvent('favorite-spot-updated');
    document.dispatchEvent(event);
  };

  const handleClick = () => {
    dispatch(toggle());
    dispatch(getSpotToApi(favoriteSpot.spotId));
  };

  const createLinkElement = () => {
    return (
      <a
        className="stock-fav-spot__add-plan-popup-link"
        onClick={() => {
          dispatch(setIndex(0));
        }}
      >
        {I18n.t('japan_concierge.active_plans.show.my_plan')}
      </a>
    );
  };

  const stringToHTML = () => {
    return (
      <IntlProvider locale="en">
        <FormattedMessage
          id="successfullyAddedPlan"
          defaultMessage={I18n.t('japan_concierge.active_plans.show.successfully_added_plan')}
          values={{ link: createLinkElement() }}
        />
      </IntlProvider>
    );
  };

  return (
    <div className="stock-fav-spot" key={favoriteSpot.id} data-testid="favoriteSpotFC">
      <div className="stock-fav-spot__inner">
        <div className="stock-fav-spot__image-container">
          <img className="stock-fav-spot__image" alt={favoriteSpot.name} src={favoriteSpot.image} />
        </div>
        <div className="stock-fav-spot__content">
          <div role="link" className="stock-fav-spot__title-container" onClick={handleClick}>
            <h5 className="stock-fav-spot__title">{favoriteSpot.name}</h5>
          </div>

          <div className="stock-fav-spot__description-container">
            <p className="stock-fav-spot__description">{favoriteSpot.description}</p>
          </div>

          {/* プランに追加 */}
          <div className="stock-fav-spot__add-plan-container">
            <div className="stock-fav-spot__add-plan">
              {isAddToMyPlan && <span className="stock-fav-spot__add-plan-popup">{stringToHTML()}</span>}
              <button
                className="jc-add-plan stock-fav-spot__add-plan-button"
                type="button"
                aria-label="addPlanButton"
                onClick={() => {
                  setisAddToMyPlan(true);
                  dispatch(createPlanSpotToApi({ spotId: favoriteSpot.spotId, position: 'auto' }));
                }}
              >
                <IconPlus additionalClassNames={['jc-add-plan__plus-icon']} />
                {I18n.t('japan_concierge.spot_modals.show.add_to_my_plan')}
              </button>
            </div>
          </div>
          <div className="stock-fav-spot__close-container">
            <button
              className="stock-fav-spot__close"
              type="button"
              onClick={closeClick}
              data-testid="favoriteSpotDelete"
              aria-hidden="true"
            >
              <IconCircleDelete additionalClassNames={['stock-fav-spot__close-icon']} />
              <span className="stock-fav-spot__close-text">
                {I18n.t('japan_concierge.active_plans.show.remove_from_favorites_list')}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoriteSpotFC;
