import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState, Plan } from '../../types';
import { deletePlanToApi, updateActivePlanToApi } from '../../slices/planSlice';
import { deletePlan, setIndex } from '../../slices/planMenuSlice';
import { reset as planSpotReset } from '../../slices/planSpotSlice';
import { reset as transitReset } from '../../slices/transitSlice';
import { setDay } from '../../slices/activeDaySlice';
import { AppDispatch } from '../../active_plan/store';
import IconCircleDelete from '../icons/iconCircleDeleteComponent';

type Props = {
  plan: Plan;
};

const PlanFC: FC<Props> = (props: Props) => {
  const { plan } = props;
  const activePlanId = useSelector((state: AppState) => state.plan.id);
  const dispatch: AppDispatch = useDispatch();

  const closeClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    // activePlanの場合、planSpot, transitを削除する
    if (activePlanId === plan.id) {
      dispatch(planSpotReset());
      dispatch(transitReset());
    }
    dispatch(setDay(1));
    dispatch(deletePlan(plan.id));
    dispatch(deletePlanToApi(plan.id));
    // プラン選択処理（handleClick）が起動しないようにバブリングを抑止
    e.stopPropagation();
  };

  const handleClick = () => {
    if (activePlanId !== plan.id) {
      dispatch(setIndex(0));
      dispatch(setDay(1));
      dispatch(planSpotReset());
      dispatch(transitReset());
      dispatch(updateActivePlanToApi(plan.id));
    } else {
      dispatch(setIndex(0));
    }
  };

  return (
    <div
      className={`stock-plan ${activePlanId === plan.id ? 'stock-plan--state_active' : ''}`}
      key={plan.id}
      onClick={handleClick}
      role="link"
      data-testid="activePlan"
    >
      <img className="stock-plan__image" alt={plan.name} src={plan.image} />
      <button className="stock-plan__close" type="button" onClick={closeClick} aria-label="PlanListPlanDelete">
        <IconCircleDelete additionalClassNames={['stock-plan__close-icon']} />
      </button>
      <h5 className="stock-plan__name" data-testid={`planName-${plan.id}`}>
        {plan.name}
      </h5>
    </div>
  );
};

export default PlanFC;
