import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { type EntityId } from '@reduxjs/toolkit';

import { deepEqual } from 'fast-equals';
import { activePlanDaySelector } from '../../slices/planDaySlice';
import {
  deletePlanSpotToApi,
  deletePlanSpot,
  planSpotSelectors,
  getPosition,
  planSpotInMaxPositionSelector,
  planSpotInMinPositionSelector,
  planSpotPositionSelector,
} from '../../slices/planSpotSlice';
import { deleteTransit, getTransitTime, transitPositionSelector } from '../../slices/transitSlice';
import { getSpotToApi, setIsDisplayRemoveButton } from '../../slices/modalSpotSlice';
import { toggle, setContentId } from '../../slices/modalSlice';
import { setPlanSpot } from '../../slices/modalStaySlice';

import { AppState, SpotLink } from '../../types';

import IconClock from '../icons/iconClockComponent';
import IconCircleDelete from '../icons/iconTimesComponent';
import IconSort2 from '../icons/iconSort2Component';
import I18n from '../../i18n/i18n';
import { useAppDispatch } from '../../active_plan/hook';
import { formatTime } from '../../utils/datetime';
import OutsideLink from './outsideLinkComponent';

interface Props {
  planSpotId: EntityId;
}

const Spot: FC<Props> = (props: Props) => {
  const { planSpotId } = props;
  const planDay = useSelector((state: AppState) => activePlanDaySelector(state));
  const planSpot = useSelector((state: AppState) => planSpotSelectors.selectById(state, planSpotId));
  const planSpots = useSelector(
    (state: AppState) => planSpotPositionSelector(planSpot?.position || 0)(state),
    deepEqual
  );
  const transits = useSelector((state: AppState) => transitPositionSelector(planSpot?.position || 0)(state), deepEqual);
  // DAYに紐づくplanSoptの最小、最大positionを算出
  const maxPosition = useSelector((state: AppState) => planSpotInMaxPositionSelector(state), deepEqual);
  const minPosition = useSelector((state: AppState) => planSpotInMinPositionSelector(state), deepEqual);
  const dispatch = useAppDispatch();

  if (!planSpot || !planSpot.spot || planDay?.day !== planSpot.day) {
    return <div data-testid="no_data" />;
  }

  // 削除処理
  // tsでは関数名deleteが利用できない
  const onClickDelete = () => {
    if (planSpot) {
      dispatch(deletePlanSpotToApi(planSpot.id));
    }
    dispatch(deleteTransit(planSpotId));
    dispatch(deletePlanSpot({ id: planSpotId, position: planSpot.position }));
  };

  const formatHour = (hour: number): string => {
    if (!hour) return '';
    return `${hour}${I18n.t('japan_concierge.active_plans.show.hour_short')}`;
  };

  const formatMinute = (hour: number, minute: number): string => {
    if (hour > 0 && minute === 0) return '';
    if (hour === 0 && minute === 0) return `0${I18n.t('japan_concierge.active_plans.show.minute_short')}`;
    return `${hour > 0 ? ` ${minute}` : minute}${I18n.t('japan_concierge.active_plans.show.minute_short')}`;
  };

  const format = (time: number): string => {
    if (!time) return `0${I18n.t('japan_concierge.active_plans.show.minute_short')}`;

    const hour = Math.floor(time / 60);
    const minute = time % 60;

    return `${formatHour(hour)}${formatMinute(hour, minute)}`;
  };

  // プランの起点となる出発時刻
  const planStartTime = new Date();
  // planDay.startTimeHour || 9 で判定するとplanDay.startTimeHourが0の場合もnull or undifined判定され９が設定される為以下のようにして
  // 0も設定できるようにしています
  if (planDay?.startTimeHour !== undefined) {
    planStartTime.setHours(planDay.startTimeHour, planDay.startTimeMinute || 0);
  }
  const startTime = new Date();
  if (planDay?.startTimeHour !== undefined) {
    startTime.setHours(planDay.startTimeHour || 9, planDay.startTimeMinute || 0);
  }

  // 表示するプランスポットのpositionより以前の全てのスポットの滞在時間の合計値
  const stayTotalBeforeSpot = planSpots.reduce((sum, p) => {
    if (p?.position !== planSpot?.position) {
      return sum + (p?.stay || 0);
    }
    return sum;
  }, 0);
  // 表示するプランスポットのpositionより以前の全ての移動時間の合計値
  const transitTotal = transits.reduce((sum, t) => {
    const targetPlanSpot = planSpots.find((p) => p?.id === t?.toPlanSpotId);
    const time = getTransitTime(targetPlanSpot?.transitTime || 0, targetPlanSpot?.mode || 'auto', t);
    return sum + time;
  }, 0);

  // 到着時刻
  const arrivalTime = new Date(
    planStartTime.setMinutes(planStartTime.getMinutes() + transitTotal + stayTotalBeforeSpot)
  );
  // 出発時刻
  const departureTime = new Date(planStartTime.setMinutes(planStartTime.getMinutes() + (planSpot?.stay || 0)));

  const isArrivalTimeWarning = arrivalTime.getDate() !== new Date(startTime).getDate();
  const isDepartureTimeWarning = departureTime.getDate() !== new Date(startTime).getDate();

  return (
    <div className="jc-plan-spot__content-container">
      <span className="jc-plan-spot__index-wrap">
        <span className="jc-plan-spot__index" data-testid="plan_spot_position">
          {getPosition(planSpot.position || 0, maxPosition, minPosition)}
        </span>
      </span>
      <button className="jc-plan-spot__delete" type="button" aria-label="Close" onClick={() => onClickDelete()}>
        <IconCircleDelete additionalClassNames={['jc-plan-spot__delete-icon']} />
      </button>
      <span className="jc-plan-spot__handle sort-handle">
        <IconSort2 additionalClassNames={['jc-plan-spot__handle-icon']} />
      </span>

      <div className="jc-plan-spot__content">
        {/* スポット画像 */}
        <div className="jc-plan-spot__img-wrap">
          <img className="jc-plan-spot__img" alt={planSpot.name} src={planSpot.image} />
        </div>

        {/* スポット詳細 */}
        <div className="jc-plan-spot__detail-wrap">
          {/* 到着時刻 - 出発時刻 */}
          <div className="jc-plan-spot__arrival_and_departure-time-area" data-testid="arrival_and_departure">
            <span
              className={isArrivalTimeWarning ? ' jc-plan-spot__timetable-time--state_warning' : ''}
              data-testid="type_arrival"
            >
              {formatTime(arrivalTime)}
            </span>
            <span>-</span>
            <span
              className={isDepartureTimeWarning ? ' jc-plan-spot__timetable-time--state_warning' : ''}
              data-testid="type_departure"
            >
              {formatTime(departureTime)}
            </span>
          </div>

          {/* {スポット名} */}
          <div
            className="jc-plan-spot__name"
            role="link"
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              e.stopPropagation();
              if (planSpot?.spot) {
                dispatch(setContentId('spot'));
                dispatch(toggle());
                dispatch(setIsDisplayRemoveButton(true));
                dispatch(getSpotToApi(planSpot.spot.id));
              }
            }}
          >
            {planSpot.name}
          </div>

          {/* 滞在時間 */}
          <div className="jc-plan-spot__stay-time-set">
            <span className="jc-plan-spot__stay-time">
              <span className="jc-plan-spot__stay-text">滞在時間：</span>
              <IconClock additionalClassNames={['jc-plan-spot__stay-time-icon']} />
              <span className="jc-plan-spot__stay-time-text">{`${format(planSpot.stay)}`}</span>
            </span>
            <button
              type="button"
              aria-label="StayEdit"
              className="jc-plan-spot__stay-time-edit-btn"
              onClick={() => {
                dispatch(setContentId('stay'));
                dispatch(toggle());
                dispatch(setPlanSpot(planSpot));
              }}
            >
              <span className='jc-u-d-none jc-u-d-md-block'>{I18n.t('japan_concierge.active_plans.show.edit_visit_duration')}</span>
              <span className='jc-u-d-block jc-u-d-md-none'>{I18n.t('japan_concierge.active_plans.show.edit')}</span>
            </button>
          </div>

          {/* リンク */}
          <div className="jc-plan-spot-other-links">
            {planSpot.spot.links && planSpot.spot.links.length > 0 && (
              <div className="jc-plan-spot-other-links__inner">
                {planSpot.spot.links.map((link: SpotLink) => (
                  <OutsideLink link={link} key={link.id} />
                ))}
              </div>
            )}
          </div>

          {/* 説明 */}
          {planSpot.spot.description && <div className="jc-plan-spot__description">{planSpot.spot.description}</div>}
        </div>
      </div>
    </div>
  );
};

export default Spot;
