import React from 'react';
import { useSelector } from 'react-redux';

import { getSpotToApi } from '../../slices/modalSpotSlice';
import { toggle } from '../../slices/modalSlice';
import { AppState, MapSpot, SpotLink } from '../../types';
import I18n from '../../i18n/i18n';
import { createPlanSpotToApi } from '../../slices/planSpotSlice';
import { focusSpotOnMap } from '../../slices/mapSlice';
import { setSpotId } from '../../slices/spotSlideTileSlice';
import { useAppDispatch } from '../../active_plan/hook';
import IconPlus from '../icons/iconPlusComponent';
import IconLink from '../icons/iconLinkComponent';

type SpotSlideTileCardProps = {
  mapSpot: MapSpot;
};

const SpotSlideTileCard = (props: SpotSlideTileCardProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { mapSpot } = props;
  const mapMenuSelectedIndex = useSelector((state: AppState) => state.mapMenuCategory.selectedIndex);
  const selectedSpotId = useSelector((state: AppState) => state.spotSlideTile.selectedSpotId);

  return (
    <div
      id={String(mapSpot.id)}
      data-testid={`mapSpotCard${mapSpot.id}`}
      className={`jc-map-slider__slide ${selectedSpotId === mapSpot.id ? 'jc-map-slider__slide--active' : ''}`}
      key={mapSpot.id}
      onClick={() => {
        dispatch(setSpotId(selectedSpotId === mapSpot.id ? 0 : mapSpot.id));
        dispatch(focusSpotOnMap(new google.maps.LatLng({ lat: mapSpot.spot.lat, lng: mapSpot.spot.lng })));
      }}
    >
      <div className="jc-map-slider__slide-card">
        <div className="jc-map-slider__slide-card-inner">
          <div
            className="jc-map-slider__slide-card-image-container"
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{ __html: mapSpot.slideImage }}
          />
          <div className="jc-map-slider__slide-card-summary">
            <div
              className="jc-map-slider__slide-card-title"
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e.stopPropagation();
                dispatch(toggle());
                dispatch(getSpotToApi(mapSpot.spot.id));
              }}
            >
              {mapSpot.spot.name}
            </div>
            <div className="jc-map-slider__slide-card-description">{mapSpot.spot.description}</div>
            <div className="jc-map-slider__slide-card-add-button-container">
              <button
                type="button"
                className="jc-add-plan jc-map-slider__slide-card-add-button"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  dispatch(
                    createPlanSpotToApi({
                      spotId: mapSpot.spot.id,
                      position: mapMenuSelectedIndex === 2 ? '1' : 'auto',
                    })
                  );
                }}
              >
                <IconPlus additionalClassNames={['jc-add-plan__plus-icon']} />
                {I18n.t('japan_concierge.map_partials.common.add_to_course')}
              </button>
            </div>
          </div>

          {mapSpot.spot.links.length !== 0 && (
            <div className="jc-map-slider__slide-card-link-container">
              {mapSpot.spot.links.map((link: SpotLink) => (
                <a
                  href={link.url}
                  className="jc-outside-link jc-map-slider__slide-card-link-item"
                  target="_blank"
                  rel="noreferrer"
                  key={link.id}
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                    e.stopPropagation();
                  }}
                >
                  <IconLink additionalClassNames={['jc-outside-link__icon']} />
                  {link.name}
                </a>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpotSlideTileCard;
