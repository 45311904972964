import React, { FC } from 'react';

import I18n from '../../i18n/i18n';
import { customSpotCreateToApi, setName, setStay } from '../../slices/customeSpotSlice';
import { useAppDispatch } from '../../active_plan/hook';
import { useSelector } from 'react-redux';
import { AppState } from '../../types';

// interface MapProps extends google.maps.MapOptions {
//   zoom: number;
//   center: { lat: number; lng: number };
// }

const Form: FC = () => {
  const dispatch = useAppDispatch();
  const customSpot = useSelector((state: AppState) => state.customSpot);

  return (
    <div className="jc-custom-spot-form__definition-wrap">
      <div className="jc-custom-spot-form__definition-head">
        <p className="jc-custom-spot-form__definition-head-ttl">
          {I18n.t('japan_concierge.custom_spots.new.definition_window_title')}
        </p>
      </div>
      <div className="jc-custom-spot-form__definition-body">
        <div className="jc-custom-spot-form__text-input-set jc-custom-spot-form__text-input-set--type_spot-name">
          <div className="jc-custom-spot-form__text-input-set-label">
            {I18n.t('japan_concierge.custom_spots.new.spot_name')}
            <span className="jc-custom-spot-form__text-input-set-note">
              * {I18n.t('japan_concierge.custom_spots.new.required')}
            </span>
          </div>
          <input
            type="text"
            name="name"
            id="custom_spot_name"
            data-testid="custom_spot_name"
            value={customSpot.name}
            onChange={(e) => {
              dispatch(setName(e.target.value));
            }}
          />
          {customSpot.isNameError && (
            <span className="error" data-testid="custom_spot_name_error">
              * {I18n.t('japan_concierge.custom_spots.new.spot_name_error')}
            </span>
          )}
        </div>
        <div className="jc-custom-spot-form__text-input-set jc-custom-spot-form__text-input-set--type_stay-time">
          <div className="jc-custom-spot-form__text-input-set-label">
            {I18n.t('japan_concierge.custom_spots.new.stay_time')}
            <span className="jc-custom-spot-form__text-input-set-note">
              * {I18n.t('japan_concierge.custom_spots.new.required')}
            </span>
          </div>
          <input
            type="number"
            name="stay"
            id="custom_spot_stay"
            data-testid="custom_spot_stay"
            className="numeric integer optional"
            value={customSpot.stay}
            onChange={(e) => {
              dispatch(setStay(Number(e.target.value)));
            }}
          />
          {customSpot.isStayError && (
            <span className="error" data-testid="custom_spot_stay_error">
              * {I18n.t('japan_concierge.custom_spots.new.stay_time_error')}
            </span>
          )}
        </div>
        <div className="jc-custom-spot-form__latlng-txts">
          <div className="jc-custom-spot-form__latlng-txt">
            <span className="jc-u-font-weight-bold">{I18n.t('japan_concierge.custom_spots.new.lat')}</span>:{' '}
            <span data-testid="custom_spot_lat">{customSpot.lat}</span>
          </div>
          <div className="jc-custom-spot-form__latlng-txt">
            <span className="jc-u-font-weight-bold">{I18n.t('japan_concierge.custom_spots.new.lng')}</span>:{' '}
            <span data-testid="custom_spot_lng">{customSpot.lng}</span>
          </div>
        </div>
        <div className="jc-u-text-center">
          <button
            className="jc-custom-spot-form__done-btn"
            data-testid="custom_spot_create"
            disabled={customSpot.name.trim() === '' || customSpot.isNameError || customSpot.isStayError}
            onClick={() => {
              dispatch(customSpotCreateToApi(customSpot));
            }}
          >
            {I18n.t('japan_concierge.custom_spots.new.create')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Form;
