import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getExportInfoToApi } from '../slices/exportSlice';
import { ExportAppState, ExportPlanDay } from '../types/export_type';

import ExportApp from '../components/export';
import { MapConfigProps } from '../types';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FlameIcon from '../images/export-intro-lead-icon-1.png';
import { useAppDispatch } from "./hook";

interface Props {
  mapConfig: MapConfigProps;
}

const App: FC<Props> = (props: Props) => {
  const { mapConfig } = props;
  const dispatch = useAppDispatch();
  const plan = useSelector((state: ExportAppState) => state.export.exportPlan);

  useEffect(() => {
    // 初回呼び出し時に呼ばれる
    dispatch(getExportInfoToApi());
  }, []);

  if (!plan) {
    return <div data-testid="no_data" />;
  }

  return (
    <div>
      <div className="jc-export-intro">
        {/* プラン名 */}
        <h1 className="jc-export-intro__ttl">{plan.name}</h1>

        {/* プランメモ */}
        {plan.memo && (
          <div className="jc-export-intro__lead jc-u-mt-5">
            <img className="jc-export-intro__lead-icon" src={FlameIcon} alt="" />
            {plan.memo}
          </div>
        )}
      </div>

      {/* PlanDay */}
      {(plan.exportPlanDays || []).map((exportPlanDay: ExportPlanDay) => (
        <ExportApp exportPlanDay={exportPlanDay} key={exportPlanDay.id} mapConfig={mapConfig} />
      ))}
    </div>
  );
};

export default App;
