import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../types';

const Popup: FC = () => {
  const content = useSelector((state: AppState) => state.popup.contentText);
  const isShown = useSelector((state: AppState) => state.popup.isShown);
  const clientHeight = useSelector((state: AppState) => state.modal.clientHeight);
  const iframeRect = useSelector((state: AppState) => state.modal.iframeRect);
  const iframe = useSelector((state: AppState) => state.config.iframe);
  const targetRef = useRef(null);
  const [styleTop, setStyleTop] = useState(0);

  useEffect(() => {
    if (isShown) {
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const rect = targetRef?.current?.getBoundingClientRect();
        // 計算式
        // IFrameのgetBoundingClientRect().top + ブラウザの高さ / 2 - モーダルの高さ / 2 - 12
        // 親フレームで取得したIFrameのgetBoundingClientRect().topでIFrameのtopの位置が取得出来る。スクロールするとIFrameの最上位部分が
        // 見えなくなるとマイナス値となる(15pxほどずれがある)
        const displayTop = -(iframeRect?.top || 0) + (clientHeight || 2) / 2 - (rect?.height || 2) / 2 - 12;
        if (displayTop + (rect?.height || 0) > (iframeRect?.height || 0)) {
          setStyleTop(-(iframeRect?.top || 10));
        } else {
          setStyleTop(displayTop > 10 ? displayTop : 10);
        }
      }, 300);
    }
  }, [isShown, iframeRect?.top]);

  return (
    <div
      className={`jc-popup ${isShown ? '--state_show' : ''}`}
      style={iframe ? { top: `${styleTop}px`, maxHeight: `${(clientHeight || 400) * 0.8}px` } : {}}
      data-testid="popup"
      ref={targetRef}
    >
      <div className="jc-popup__content">{content}</div>
    </div>
  );
};

export default Popup;
