import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { MapState, MapSpot } from '../types';
import { setDay } from './activeDaySlice';
import { getActivePlanToApi } from './planSlice';

// ここでinfowWindowを初期化したいのですが、new google.maps.InfoWindow()を実行するとgoogleが読み込まれるタイミングによりエラーとなるため
// ここではundefinedを設定する
const initialState: MapState = {
  infoWindow: undefined,
  isInitializeMapCenter: true,
  isPlanSpot: false,
  isRunFitBounds: false,
  map: undefined,
  mapSpot: undefined,
  selectedPlanSpotId: 0,
  selectedPosition: 0,
};

//-------------------------------------------------------------
// Reducer, Actionの設定
//-------------------------------------------------------------
const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    reset: (state: MapState) => {
      Object.assign(state, initialState);
    },
    setMap: (state: MapState, action: PayloadAction<google.maps.Map | undefined>) => {
      state.map = action.payload;
    },
    openInfoWindow: (
      state: MapState,
      action: PayloadAction<{ mapSpot: MapSpot | undefined; isPlanSpot: boolean; position: number }>
    ) => {
      // infowindowはシステム上1つしか表示しない仕様のため、infowindow内で各ボタン押下時の処理をevent.addEventListenerで登録しているため、
      // 解除する必要がある。(event.removeEventListener) マーカーをクリックすされる度に前回表示していた内容を破棄するために以下の処理が必要
      if (state.infoWindow) {
        state.infoWindow?.close();
        state.infoWindow = undefined;
      }
      state.infoWindow = new google.maps.InfoWindow();
      state.mapSpot = action.payload.mapSpot;
      state.isPlanSpot = action.payload.isPlanSpot;
      state.selectedPosition = action.payload.position;
    },
    closeInfoWindow: (state: MapState) => {
      state.infoWindow?.close();
      state.infoWindow = undefined;
      state.mapSpot = undefined;
    },
    focusSpotOnMap: (state: MapState, action: PayloadAction<google.maps.LatLng>) => {
      if (state.map) {
        state.map.panTo(action.payload);
      }
    },
    setIsRunFitBounds: (state: MapState, action: PayloadAction<boolean>) => {
      state.isRunFitBounds = action.payload;
    },
    setIsInitializeMapCenter: (state: MapState, action: PayloadAction<boolean>) => {
      state.isInitializeMapCenter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // 画面表示時/リロード時にサーバから取得時に呼ばれる。
    builder.addCase(getActivePlanToApi.fulfilled, (state) => {
      mapSlice.caseReducers.setIsRunFitBounds(state, { payload: true, type: mapSlice.actions.setIsRunFitBounds.type });
    });
    // planDay選択時にinfowindowを閉じる
    builder.addMatcher(setDay.match, (state: MapState) => {
      mapSlice.caseReducers.closeInfoWindow(state);
    });
  },
});

export const {
  reset,
  setMap,
  openInfoWindow,
  closeInfoWindow,
  focusSpotOnMap,
  setIsRunFitBounds,
  setIsInitializeMapCenter,
} = mapSlice.actions;

export default mapSlice.reducer;
