import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../types';
import {
  returnRegistedAsPlanSpotSelector,
  setStart,
  planSpotInMinPositionSelector,
  planSpotInMaxPositionSelector,
  setStartFromInfoWindowToApi,
  setGoalFromInfoWindowToApi,
} from '../../slices/planSpotSlice';
import { useAppDispatch } from '../../active_plan/hook';
import { deleteTransit } from '../../slices/transitSlice';
import I18n from '../../i18n/i18n';

import { deepEqual } from 'fast-equals';

const ModalContentSpotButton: FC = () => {
  const { spot } = useSelector((state: AppState) => state.modalSpot);
  const dispatch = useAppDispatch();
  const planSpot = useSelector((state: AppState) => returnRegistedAsPlanSpotSelector(spot?.id || 0)(state));
  const minPosition = useSelector((state: AppState) => planSpotInMinPositionSelector(state), deepEqual);
  const maxPosition = useSelector((state: AppState) => planSpotInMaxPositionSelector(state), deepEqual);

  const startButtonClick = () => {
    if (planSpot) {
      dispatch(setStart({ id: planSpot.id, position: minPosition }));
      dispatch(deleteTransit(planSpot.id));
      dispatch(setStartFromInfoWindowToApi(planSpot));
    }
  };

  const goalButtonClick = () => {
    if (planSpot) {
      dispatch(deleteTransit(planSpot.id));
      dispatch(setGoalFromInfoWindowToApi(planSpot));
    }
  };

  if (!spot) {
    return <div data-testid="no_data_spot" />;
  }

  return (
    <>
      {planSpot && (
        <div className="jc-modal-content-spot-info-view__sort-switch-button-container">
          <button
            onClick={startButtonClick}
            className="jc-btn-style-reset jc-modal-content-spot-info-view__sort-switch-button"
            disabled={minPosition === planSpot.position}
          >
            <span>↑</span>
            <span>{I18n.t('japan_concierge.spot_modals.show.move_to_top')}</span>
          </button>
          <button
            onClick={goalButtonClick}
            disabled={maxPosition === planSpot.position}
            className="jc-btn-style-reset jc-modal-content-spot-info-view__sort-switch-button"
          >
            <span>↓</span>
            <span>{I18n.t('japan_concierge.spot_modals.show.move_to_last')}</span>
          </button>
        </div>
      )}
    </>
  );
};

export default ModalContentSpotButton;
