import React, { FC, ComponentType } from 'react';

type IconProps = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

interface Props {
  TransitIconComponent: ComponentType<IconProps>;
  formatTransitTime: string;
}

const TransitInfo: FC<Props> = (props) => {
  const { TransitIconComponent, formatTransitTime } = props;

  return (
    <div className="jc-plan-spot__transit-info" key="car">
      <span className="jc-plan-spot__transit-badge-icon-circle">
        <TransitIconComponent additionalClassNames={['jc-plan-spot__transit-badge-icon']} />
      </span>
      <span className="jc-plan-spot__transit-info-text">{formatTransitTime}</span>
    </div>
  );
};

export default TransitInfo;
