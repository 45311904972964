import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import I18n from '../../i18n/i18n';
import { AppState } from '../../types';
import IconQuestionCircle from '../icons/iconQuestionCircleComponent';

const HelpButton: FC = () => {
  const { helpUrl, helpUrlAnchorLink } = useSelector((state: AppState) => state.config);
  const jcHelpUrlWithLocale = (): string => {
    if (['ja', 'zh-TW', 'zh-CN', 'ko'].includes(I18n.locale)) {
      return I18n.locale.toLowerCase();
    }
    return 'en';
  };

  return (
    <a
      className="jc-top-menu__button"
      target="_blank"
      href={`${helpUrl}?locale=${jcHelpUrlWithLocale()}${helpUrlAnchorLink}`}
      rel="noreferrer"
    >
      <IconQuestionCircle additionalClassNames={['jc-top-menu__icon']}/>
      <span className="jc-top-menu__text">{I18n.t('japan_concierge.active_plans.top_menu.help_button')}</span>
    </a>
  );
};

export default HelpButton;
