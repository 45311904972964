import React, { ForwardRefRenderFunction, forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../types';

import { setIndex } from '../../slices/mapMenuCategorySlice';
import { getFavoriteMapSpotToApi, reset } from '../../slices/mapSpotSlice';
import { useAppDispatch } from '../../active_plan/hook';
import I18n from '../../i18n/i18n';

const FavoriteButton: ForwardRefRenderFunction<HTMLButtonElement> = (_, ref) => {
  const dispatch = useAppDispatch();
  const selectedIndex = useSelector((state: AppState) => state.mapMenuCategory.selectedIndex);
  const categories = useSelector((state: AppState) => state.mapMenuCategory.categories);

  if (categories.length === 0) {
    return <div data-testid="no_data" />;
  }

  return (
    <button
      ref={ref}
      type="button"
      className={`${selectedIndex === 1 ? 'active' : ''}`}
      onClick={() => {
        dispatch(setIndex(1));
        dispatch(reset());
        if (selectedIndex !== 1) {
          dispatch(getFavoriteMapSpotToApi());
        }
      }}
    >
      {I18n.t('japan_concierge.active_plans.map_menu.my_favorite')}
    </button>
  );
};

export default forwardRef( FavoriteButton);
